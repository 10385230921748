import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useActionChoicesContext } from "../../../context/GeneralAgroProvider";
import { ACTION } from "../../../constants/actions";
import Title from "../../Typography/Title";
import { FormRadioGroupInput, FormSelectInput } from "../../../form";
import { availableActionsOptions } from "../utils/actionModalUtils";
import "../action-modal.css";
import { ACTION_VIEW_MODE } from "../../../constants/actionViewModes";

const ActionBaseInfo = ({ viewMode = "view", selectedField }) => {
  const { t } = useTranslation();
  const { actionChoices, getActionById } = useActionChoicesContext();
  const { values, setValues, handleChange } = useFormikContext();

  const isViewMode =
    viewMode === ACTION_VIEW_MODE.view || viewMode === ACTION_VIEW_MODE.edit;

  const shouldShowOtherActions = useMemo(
    () =>
      getActionById ? getActionById(values.taskId) === ACTION.other : false,
    [getActionById, values.taskId]
  );

  const changeAction = useCallback(
    (taskId) => {
      const action = getActionById(taskId);
      switch (action) {
        case ACTION.watering:
          setValues((prev) => ({
            ...prev,
            waterAction: {
              operationTypeId: selectedField.wateringMechanismRead?.id,
            },
            fertilizeAction: undefined,
            drugAction: undefined,
            otherAction: undefined,
            diagnoseAction: undefined,
          }));
          break;
        case ACTION.drug:
          setValues((prev) => ({
            ...prev,
            drugAction: { bio: false },
            waterAction: undefined,
            fertilizeAction: undefined,
            otherAction: undefined,
            diagnoseAction: undefined,
          }));
          break;
        case ACTION.fertilize:
          setValues((prev) => ({
            ...prev,
            fertilizeAction: { bio: false },
            waterAction: undefined,
            drugAction: undefined,
            otherAction: undefined,
            diagnoseAction: undefined,
          }));
          break;
        case ACTION.diagnose:
          setValues((prev) => ({
            ...prev,
            waterAction: undefined,
            drugAction: undefined,
            otherAction: undefined,
            fertilizeAction: undefined,
          }));
          break;
        case ACTION.other:
          setValues((prev) => ({
            ...prev,
            waterAction: undefined,
            drugAction: undefined,
            diagnoseAction: undefined,
            fertilizeAction: undefined,
          }));
          break;
        default:
          break;
      }
    },
    [getActionById, selectedField, setValues]
  );

  const handleTaskChange = useCallback(
    (e) => {
      // It has to be in that order in order for both updates to take place
      changeAction(e.target.value);
      handleChange(e);
    },
    [changeAction, handleChange]
  );

  return (
    <div className="action-top-box-section">
      <Title title={t("ActionModal.ActionType")} />
      <FormRadioGroupInput
        name="actionTypeId"
        isViewMode={isViewMode}
        options={Object.values(actionChoices.actionTypes)?.map(
          ({ id, name }) => ({
            value: id,
            label: t("ActionType.Action", { count: id }),
          })
        )}
      />
      <Title title={t("ActionModal.ActinKind")} />
      <FormSelectInput
        name="taskId"
        placeholder={t("ActionModal.ActinKind")}
        shouldHaveErrorBehaviorAfterSubmit
        isViewMode={isViewMode}
        onChange={handleTaskChange}
        options={availableActionsOptions(
          values?.actionTypeId,
          actionChoices.actions
        )?.map(({ taskId, taskName }) => ({
          value: taskId,
          label: taskName,
        }))}
      />
      {shouldShowOtherActions && (
        <FormSelectInput
          name="otherAction.operationTypeId"
          isViewMode={isViewMode}
          shouldHaveErrorBehaviorAfterSubmit
          options={actionChoices.actions.other?.operations?.map(
            ({ operationTypeId, operationTypeName }) => ({
              value: operationTypeId,
              label: operationTypeName,
            })
          )}
        />
      )}
    </div>
  );
};

export default ActionBaseInfo;
