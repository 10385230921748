import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api } from "../api";
import useFirebaseConfig from "../hooks/useFirebaseConfig";
import useBackgroundMessages from "../hooks/useBackgroundMessages";
import { useHandlePushMessages } from "../hooks/useHandlePushMessages";
import { useDebouncedEffect } from "../hooks/useDebounce";
import { FETCH_STATE } from "../constants/fetchState";
import { deepTransformKeys } from "../utils/transformObjectKeys";
import { snakeToCamel } from "../utils/camelSnakeTransformation";

const FETCH_DEBOUNCE_DELAY = 1000 * 5;
const PAGE_SIZE = 20;

const InboxContext = createContext({
  inbox: [],
  isOpen: false,
  openInbox: () => {},
  closeInbox: () => {},
  fetchInbox: (shouldShowLoading, page) => {},
  readOne: (id) => {},
  readAll: () => {},
  seeAll: () => {},
  deleteOne: (id) => {},
  fetchState: FETCH_STATE.IDLE,
});

const transformMessages = (results) => {
  return results.map((item) => {
    const notificationInfo = item?.notification;
    const notificationExtra = notificationInfo?.extra;
    return {
      id: item.id,
      createdAt: item.createdAt,
      type: notificationInfo?.entity,
      status: notificationInfo?.entityType,
      user: {
        id: notificationExtra?.actor?.userId,
        firstName: notificationExtra?.actor?.firstName,
        lastName: notificationExtra?.actor?.lastName,
        role: notificationExtra?.actor?.role,
      },
      field: notificationExtra?.field,
      request: notificationExtra?.collaborations?.[0],
      actions: notificationExtra?.activities?.map((activity) => ({
        id: activity.id,
        type: activity.type,
        operationId: activity.operation?.id,
        taskId: activity.task?.id,
        status: notificationInfo?.entityType,
      })),
      readAt: item.readAt,
    };
  });
};

const transformInbox = (fetchedInbox, currentInbox = {}) => {
  const currentResults = currentInbox?.results || [];
  const newResults = transformMessages(fetchedInbox?.results || []);
  const currentPage = fetchedInbox?.currentPage || 1;
  const totalPages = fetchedInbox?.totalPages || 1;
  const nextPage = currentPage < totalPages ? currentPage + 1 : null;

  return {
    paginationState: {
      count: fetchedInbox?.count,
      currentPage,
      nextPage,
      totalPages,
    },
    totalUnread: fetchedInbox?.unreadMessagesCount,
    totalUnseen: fetchedInbox?.unseenMessagesCount || 0,
    results: [...currentResults, ...newResults],
  };
};

const transformWebSocketMessage = (payload) => {
  const newPayload = {};
  if (payload?.messageId) {
    newPayload.id = payload.messageId;
    newPayload.readAt = null;
  }

  if (payload?.data) {
    const { extra, ...data } = payload.data;
    newPayload.notification = data;
    if (extra) {
      const newExtra = JSON.parse(extra);
      const newExtraCamel = deepTransformKeys(newExtra, snakeToCamel);
      newPayload.notification.extra = newExtraCamel;
    }
  }

  return newPayload;
};

const InboxProvider = ({ children }) => {
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [inbox, setInbox] = useState({
    paginationState: {},
  });
  const [buffer, setBuffer] = useState([]);
  const [isInboxOpen, setIsInboxOpen] = useState(false);

  const { handleIncomingMessageToast } = useHandlePushMessages();
  const handleMessage = useCallback(
    (payload) => {
      const newPayload = transformWebSocketMessage(payload);

      // TODO: - Check the type of the message and update the corresponding buffer
      setBuffer((prev) => [...prev, newPayload]);

      // DONE: - Show a toast message if on the same page
      handleIncomingMessageToast(newPayload);
    },
    [handleIncomingMessageToast]
  );

  useFirebaseConfig({ handleMessage });

  useBackgroundMessages(handleMessage);

  // TODO: - Test and use useVisibilityChange hook to update the buffers from localStorage when the tab is focused

  const fetchInbox = useCallback(async (shouldShowLoading, page = 1) => {
    if (shouldShowLoading) {
      setFetchState(FETCH_STATE.LOADING);
    }
    try {
      const res = await api.inbox.fetchAll(page);
      setInbox((prev) => {
        if (page === 1) {
          return transformInbox(res.data);
        }
        return transformInbox(res.data, prev);
      });
      setFetchState(FETCH_STATE.SUCCESS);
    } catch (error) {
      console.error(error);
      setFetchState(FETCH_STATE.ERROR);
    }
  }, []);

  const fetchInboxState = useCallback(async () => {
    try {
      const res = await api.inbox.fetchAll(1);
      setInbox((prev) => {
        const transformedInbox = transformInbox(res.data, prev);
        const newState = {
          paginationState: {
            count: transformedInbox.paginationState,
            currentPage: transformedInbox.paginationState.currentPage,
            nextPage: transformedInbox.paginationState.nextPage,
            totalPages: transformedInbox.paginationState.totalPages,
          },
          totalUnread: transformedInbox.totalUnread,
          totalUnseen: transformedInbox.totalUnseen,
        };
        return {
          ...prev,
          ...newState,
        };
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const readOne = useCallback(async (id) => {
    try {
      await api.inbox.readOne(id);
      setInbox((prev) => ({
        ...prev,
        results: prev?.results?.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              readAt: new Date().toISOString(),
            };
          }
          return item;
        }),
      }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const readAll = useCallback(async () => {
    try {
      await api.inbox.readAll();
      setInbox((prev) => ({
        ...prev,
        results: prev?.results?.map((item) => {
          if (item.readAt) {
            return item;
          }
          return {
            ...item,
            readAt: new Date().toISOString(),
          };
        }),
      }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const seeAll = useCallback(async () => {
    try {
      await api.inbox.seeAll();
      setInbox((prev) => ({ ...prev, totalUnseen: 0 }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const deleteOne = useCallback(async (id) => {
    try {
      await api.inbox.deleteOne(id);
      setInbox((prev) => ({
        ...prev,
        results: prev?.results?.filter((item) => item.id !== id),
      }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const openInbox = useCallback(() => {
    setIsInboxOpen(true);
    fetchInbox(true);
  }, [fetchInbox]);

  const closeInbox = useCallback(() => {
    setIsInboxOpen(false);
  }, []);

  useEffect(() => {
    if (typeof fetchInboxState === "function") {
      fetchInboxState();
    }
  }, [fetchInboxState]);

  useDebouncedEffect(
    () => {
      if (buffer.length) {
        const newMessages = [...buffer];
        setBuffer([]);

        if (isInboxOpen) {
          const newMessagesCount = newMessages.length;
          const newTotalPages = Math.ceil(
            (inbox.paginationState.count + newMessagesCount) / PAGE_SIZE
          );
          const newCurrentPage =
            Math.ceil(inbox.results.length + newMessagesCount) / PAGE_SIZE;
          const newNextPage =
            newCurrentPage < newTotalPages ? newCurrentPage + 1 : null;
          const newMessagesTransformed = transformMessages(newMessages);

          setInbox((prev) => ({
            ...prev,
            results: [...newMessagesTransformed, ...prev.results],
            paginationState: {
              count: prev.paginationState.count + newMessagesCount,
              currentPage: newCurrentPage,
              nextPage: newNextPage,
              totalPages: newTotalPages,
            },
            totalUnread: prev.totalUnread + newMessagesCount,
          }));
        } else {
          fetchInbox();
        }
      }
    },
    [buffer],
    FETCH_DEBOUNCE_DELAY
  );

  const value = useMemo(
    () => ({
      inbox,
      isOpen: isInboxOpen,
      openInbox,
      closeInbox,
      fetchInbox,
      readOne,
      readAll,
      seeAll,
      deleteOne,
      fetchState,
    }),
    [
      inbox,
      isInboxOpen,
      openInbox,
      closeInbox,
      fetchState,
      fetchInbox,
      readOne,
      readAll,
      seeAll,
      deleteOne,
    ]
  );

  return (
    <InboxContext.Provider value={value}>{children}</InboxContext.Provider>
  );
};

const useInboxContext = () => {
  const context = useContext(InboxContext);

  if (!context) {
    throw new Error("useInboxContext must be used within a InboxProvider");
  }
  return useContext(InboxContext);
};

export { InboxProvider, useInboxContext };
