import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "utils/paths";
import { api } from "api";
import { useTranslation } from "react-i18next";

const findAllCropsOfFields = (fields, isEn) => {
  const crops = fields.reduce((acc, field) => {
    const cropId = field.varietyRead.cropId;
    if (acc.map((crop) => crop.value).includes(cropId)) {
      return [...acc];
    } else {
      const cropNameGr = field.varietyRead.cropNameGr;
      const cropNameEn = field.varietyRead.cropNameEn;
      const crop = {
        value: cropId,
        label: isEn ? cropNameEn : cropNameGr,
      };
      return [...acc, crop];
    }
  }, []);
  return crops;
};

const findAllAreasOfFields = (fields, isEn) => {
  const areas = fields.reduce((acc, field) => {
    const regionId = field.municipality.regionId;
    const prefectureId = field.municipality.prefectureId;
    const prefectureNameGr = field.municipality.prefectureNameGr;
    const prefectureNameEn = field.municipality.prefectureNameEn;

    const existingArea = acc.find((area) => area.value === regionId);
    if (existingArea) {
      const existingPrefecture = existingArea.children.find(
        (prefecture) => prefecture.value === prefectureId
      );
      if (existingPrefecture) {
        return acc;
      } else {
        const prefecture = {
          value: prefectureId,
          label: isEn ? prefectureNameEn : prefectureNameGr,
          parentIdPath: [regionId],
        };
        existingArea.children.push(prefecture);
        return acc;
      }
    } else {
      const regionNameGr = field.municipality.regionNameGr;
      const regionNameEn = field.municipality.regionNameEn;
      const area = {
        value: regionId,
        label: isEn ? regionNameEn : regionNameGr,
        children: [
          {
            value: prefectureId,
            label: isEn ? prefectureNameEn : prefectureNameGr,
          },
        ],
      };
      return [...acc, area];
    }
  }, []);
  return areas;
};

const FieldsContext = createContext({
  fields: [],
  fetchState: {
    isLoading: false,
    error: undefined,
  },
  myLocations: [],
  myCrops: [],
});

const FieldsContextActions = createContext({
  fetchUserFields: ({ onSuccess, onError }) => {},
  createNewField: ({ postData, onSuccess, onError }) => {},
  updateField: ({ id, patchData, onSuccess, onError }) => {},
  deleteField: ({ id, onSuccess, onError }) => {},
  cancelCollaboration: ({ id, onSuccess, onError }) => {},
});

const FieldsProvider = ({ children }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const [fields, setFields] = useState([]);
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    error: undefined,
  });

  const fetchUserFields = useCallback(
    (handlers = { onSuccess: null, onError: null }) => {
      const { onSuccess, onError } = handlers;

      setFetchState({ isLoading: true, error: undefined });
      api.fields
        .fetchMy()
        .then((res) => {
          const fields = res.data.fields;
          setFields(fields);

          setFetchState({ isLoading: false, error: undefined });
          onSuccess?.(fields);
        })
        .catch((error) => {
          setFetchState({ isLoading: false, error });
          onError?.(error);
        });
    },
    []
  );

  const myCrops = useMemo(() => {
    const crops = findAllCropsOfFields(fields, isEn);
    return crops;
  }, [fields, isEn]);

  const myLocations = useMemo(() => {
    const areas = findAllAreasOfFields(fields, isEn);
    return areas;
  }, [fields, isEn]);

  useEffect(() => {
    if (typeof fetchUserFields === "function") {
      fetchUserFields();
    }
  }, [fetchUserFields]);

  const createNewField = useCallback(
    ({ postData, onSuccess, onError }) => {
      api.fields
        .create(postData)
        .then(({ data: field }) => {
          navigate?.(paths.field(field.id));
          onSuccess?.(field);
          fetchUserFields?.();
        })
        .catch((err) => {
          onError?.(err);
        });
    },
    [fetchUserFields, navigate]
  );

  const updateField = useCallback(({ id, patchData, onSuccess, onError }) => {
    api.fields
      .update(id, patchData)
      .then((res) => {
        onSuccess?.(res.data);
        setFields((prev) =>
          prev.map((field) => {
            if (field.id === id) {
              return res.data;
            }
            return field;
          })
        );
      })
      .catch((error) => {
        onError?.(error);
      });
  }, []);

  const deleteField = useCallback(
    ({ id, onSuccess, onError }) => {
      api.fields
        .delete(id)
        .then((res) => {
          onSuccess?.(res.data);
          fetchUserFields?.();
        })
        .catch((err) => onError?.(err));
    },
    [fetchUserFields]
  );

  const cancelCollaboration = useCallback(
    ({ id, onSuccess, onError }) => {
      api.fields
        .removeMe(id)
        .then((res) => {
          onSuccess?.(res.data);
          fetchUserFields?.();
        })
        .catch((err) => onError?.(err));
    },
    [fetchUserFields]
  );

  const value = useMemo(
    () => ({
      fields,
      fetchState,
      myCrops,
      myLocations,
    }),
    [fields, fetchState, myCrops, myLocations]
  );

  const actions = useMemo(
    () => ({
      fetchUserFields,
      createNewField,
      updateField,
      deleteField,
      cancelCollaboration,
    }),
    [
      fetchUserFields,
      createNewField,
      updateField,
      deleteField,
      cancelCollaboration,
    ]
  );

  return (
    <FieldsContextActions.Provider value={actions}>
      <FieldsContext.Provider value={value}>{children}</FieldsContext.Provider>
    </FieldsContextActions.Provider>
  );
};

const useFieldsContext = () => {
  const context = useContext(FieldsContext);

  if (!context) {
    throw new Error("useFieldsContext must be used within a FieldsProvider");
  }
  return useContext(FieldsContext);
};

const useFieldsContextActions = () => {
  const context = useContext(FieldsContextActions);

  if (!context) {
    throw new Error(
      "useFieldsContextActions must be used within a FieldsProvider"
    );
  }
  return useContext(FieldsContextActions);
};

export { useFieldsContext, useFieldsContextActions, FieldsProvider };
