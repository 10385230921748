import "./weather-day.css";
import NoContentFound from "../NoContentFound/NoContentFound";
import Title from "../Typography/Title";
import SubTitle from "../Typography/SubTitle";
import { useEffect, useState } from "react";
import { getIcon } from "../../utils/weatherIcons";
import NormalText from "../Typography/NormalText";
import moment from "moment";

// ° = Option + Shift + 8

const TEXT_COLOR = "#424242";

function checkDateStatus(inputDate) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const input = new Date(inputDate);
  input.setHours(0, 0, 0, 0);

  if (input < today) {
    return "past";
  } else if (input > today) {
    return "future";
  } else {
    return "today";
  }
}

const WeatherDay = ({ day, style = {}, onClick }) => {
  const [iconCode, setIconCode] = useState("");
  const [dateToDisplay, setDateToDisplay] = useState("");
  const [dateStatus, setDateStatus] = useState("past");

  const handleClick = () => {
    onClick?.(day.timeAsDate);
  };

  useEffect(() => {
    const iconParts = day?.mainIcon.split("/");
    const iconName = iconParts[iconParts.length - 1];
    const iconCode = iconName.split(".")[0];
    setIconCode(iconCode);
    const date = moment(day?.timeAsDate);
    const formattedDate = date.format("DD MMM");
    setDateToDisplay(formattedDate);
    const pastTodayOrFuture = checkDateStatus(day?.timeAsDate);
    setDateStatus(pastTodayOrFuture);
  }, [day]);

  return (
    <div
      className={`weather-day-wrapper ${dateStatus === "future" ? "future-day" : dateStatus === "today" ? "today" : ""}`}
      style={{ cursor: typeof onClick === "function" && "pointer", ...style }}
      onClick={handleClick}
    >
      {day ? (
        <div className="weather-day-title">
          <NormalText text={dateToDisplay} />
          <div className="weather-day-flex">
            <img
              src={getIcon(iconCode)}
              style={{ width: "45px", height: "45px" }}
              alt="weather-icon"
            />
            <div className="weather-day-temperature">
              <Title
                title={`${day.maxTemp}°`}
                style={{ color: TEXT_COLOR }}
                className="max-temp"
              />
              <SubTitle
                text={`${day.minTemp}°`}
                style={{ color: TEXT_COLOR, fontWeight: 400 }}
                className="min-temp"
              />
            </div>
          </div>
        </div>
      ) : (
        <NoContentFound text="Χωρίς δεδομένα" />
      )}
    </div>
  );
};

export default WeatherDay;
