import { accountApi } from "./accountApi";
import { actionsApi } from "./actionsApi";
import { authApi } from "./authApi";
import { biocycleApi } from "./biocycleApi";
import { collabApi } from "./collabApi";
import { cropsApi } from "./cropsApi";
import { emissionsApi } from "./emissionsApi";
import { enemiesApi } from "./enemiesApi";
import { equipmentsApi } from "./equipmentsApi";
import { fieldsApi } from "./fieldsApi";
import { generalAgroApi } from "./generalAgroApi";
import { inboxApi } from "./inboxApi";
import { kaekApi } from "./kaekApi";
import { settingsApi } from "./settingsApi";
import { stressesApi } from "./stressesApi";
import { usersApi } from "./usersApi";
import { weatherApi } from "./weatherApi";

const endpoints = {
  account: accountApi,
  actions: actionsApi,
  auth: authApi,
  biocycle: biocycleApi,
  collab: collabApi,
  crops: cropsApi,
  emissions: emissionsApi,
  enemies: enemiesApi,
  equipments: equipmentsApi,
  fields: fieldsApi,
  inbox: inboxApi,
  generalAgro: generalAgroApi,
  kaek: kaekApi,
  settings: settingsApi,
  stresses: stressesApi,
  users: usersApi,
  weather: weatherApi,
};

export default endpoints;
