import moment from "moment";

export const formatInboxDatetime = (t, date) => {
  const momentDate = moment(date);
  const now = moment();

  const diffInSeconds = now.diff(momentDate, "seconds");
  if (diffInSeconds < 60) {
    return t("Inbox.DatetimeFormat.Now");
  }

  const diffInMinutes = now.diff(momentDate, "minutes");
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${t("Inbox.DatetimeFormat.Minutes")}`;
  }

  const diffInHours = now.diff(momentDate, "hours");
  if (diffInHours < 24) {
    return `${diffInHours} ${t("Inbox.DatetimeFormat.Hours")}`;
  }
  const diffInDays = now.diff(momentDate, "days");
  if (diffInDays < 7) {
    return `${diffInDays} ${t("Inbox.DatetimeFormat.Days")}`;
  }
  const diffInWeeks = now.diff(momentDate, "weeks");
  if (diffInWeeks < 56)
    return `${diffInWeeks} ${t("Inbox.DatetimeFormat.Weeks")}`;

  const diffInYears = now.diff(momentDate, "years");
  return `${diffInYears} ${t("Inbox.DatetimeFormat.Years")}`;
};
