import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormSelectInput, FormTextInput } from "../../../../form";
import NormalText from "../../../Typography/NormalText";
import { actionInputName } from "../../utils/inputName";

const QuantityInput = ({
  isProposedValue,
  isViewMode,
  actionBaseName,
  quantityOptions = [],
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const wrapperClassName =
    "action-details-quantity-input" +
    (isViewMode ? " quantity-input-baseline" : "");

  const baseName = isProposedValue ? "proposedAction" : actionBaseName;

  return (
    <div className={wrapperClassName}>
      {isViewMode ? (
        <NormalText
          text={values?.[baseName]?.quantity}
          style={{ paddingLeft: "8px" }}
        />
      ) : (
        <FormTextInput
          name={actionInputName(actionBaseName, "quantity", isProposedValue)}
          shouldHaveErrorBehaviorAfterSubmit
          style={{
            width: "120px",
          }}
          isFullWidth={false}
          isViewMode={isViewMode}
        />
      )}
      <FormSelectInput
        name={actionInputName(actionBaseName, "quantityUnits", isProposedValue)}
        placeholder={t("ActionModal.Units")}
        shouldHaveErrorBehaviorAfterSubmit
        options={quantityOptions}
        isViewMode={isViewMode}
        isFullWidth={false}
        style={{ minWidth: "140px" }}
      />
    </div>
  );
};

export default QuantityInput;
