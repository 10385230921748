import { Popup } from "react-leaflet";
import Marker from "./Marker";
import "./styles.css";

const AnalysisMarker = ({
  position,
  onPositionChange,
  onDelete,
  isViewOnly,
}) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete?.();
  };

  const isRemovable = typeof onDelete === "function" && !isViewOnly;

  return (
    <Marker
      position={position}
      setPosition={onPositionChange}
      isDraggable={!isViewOnly}
    >
      {isRemovable && (
        <Popup
          offset={{ x: 0, y: -20 }}
          closeOnEscapeKey
          keepInView
          closeButton={false}
          className="leaflet-marker-popup"
        >
          <div
            style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
            onClick={handleDelete}
          >
            x
          </div>
        </Popup>
      )}
    </Marker>
  );
};

export default AnalysisMarker;
