import { useEffect, useRef } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

const MapImageOverlay = ({ png }) => {
  const map = useMap();
  const overlayRef = useRef(null);

  useEffect(() => {
    if (overlayRef.current) {
      map.removeLayer(overlayRef.current);
    }
    if (png.pngImage) {
      const ndviLayer = L.imageOverlay(png.pngImage, [
        [png.boundBox.maxLat, png.boundBox.minLon],
        [png.boundBox.minLat, png.boundBox.maxLon],
      ]);

      ndviLayer.addTo(map);
      overlayRef.current = ndviLayer;
    }

    return () => {
      if (overlayRef.current) {
        map.removeLayer(overlayRef.current);
      }
    };
  }, [png, map]);

  return null;
};

export default MapImageOverlay;
