import LoadingImage from "../../assets/loading/main.gif";

const CamvioLoader = ({ style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        ...style,
      }}
    >
      <img alt="loader" src={LoadingImage} width="300px" />
    </div>
  );
};

export default CamvioLoader;
