import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { api } from "api";
import { FETCH_STATE } from "constants/fetchState";
import { toaster } from "utils/toaster";
import { useTranslation } from "react-i18next";

const COMMENT_SHOW_STEP = 3;

const parseComments = (commentsString) => JSON.parse(commentsString || "[]");
const stringifyComments = (newComment, comments = []) => {
  const newComments = [...comments, newComment];
  return JSON.stringify(newComments);
};

export const useActionComments = () => {
  const { t } = useTranslation();
  const { fieldId } = useParams();
  const [searchParams] = useSearchParams();
  const commentRef = useRef();

  const [fetchState, setFetchState] = useState(false);
  const [lastDisplayedIndex, setLastDisplayedIndex] = useState();
  const [comments, setComments] = useState([]);
  const indexStartingNewestComments = useRef();

  const actionId = useMemo(() => {
    return searchParams.get("actionId");
  }, [searchParams]);

  const displayedComments = useMemo(() => {
    if (!lastDisplayedIndex) return comments;
    return comments.slice(lastDisplayedIndex);
  }, [comments, lastDisplayedIndex]);

  const commentsRemaining = useMemo(() => {
    return comments.length - displayedComments.length;
  }, [comments?.length, displayedComments?.length]);

  const couldHideOlder =
    indexStartingNewestComments.current > commentsRemaining;

  useEffect(() => {
    if (typeof lastDisplayedIndex !== "undefined") return;
    if (comments.length > COMMENT_SHOW_STEP) {
      indexStartingNewestComments.current = comments.length - COMMENT_SHOW_STEP;
      setLastDisplayedIndex(comments.length - COMMENT_SHOW_STEP);
    }
  }, [comments?.length, lastDisplayedIndex]);

  const showOlder = (e) => {
    e.preventDefault();
    if (lastDisplayedIndex - COMMENT_SHOW_STEP) {
      setLastDisplayedIndex((prev) => prev - COMMENT_SHOW_STEP);
    } else {
      setLastDisplayedIndex(0);
    }
  };

  const hideOlder = (e) => {
    e.preventDefault();
    if (comments.length > indexStartingNewestComments.current) {
      setLastDisplayedIndex(indexStartingNewestComments.current);
    }
  };

  const fetchComments = useCallback(() => {
    if (!actionId || !fieldId || !t) return;

    setFetchState(FETCH_STATE.LOADING);

    api.actions.comments
      .fetch({ fieldId, actionId })
      .then(({ data }) => {
        setComments(data);
        setFetchState(FETCH_STATE.SUCCESS);
        setLastDisplayedIndex();
        commentRef.current.value = "";
      })
      .catch(() => {
        toaster.error({
          message: t("ActionModal.Comments.Error.Fetch"),
          position: "bottom-center",
          shouldReload: true,
        });
        setFetchState(FETCH_STATE.ERROR);
      });
  }, [actionId, fieldId, t]);

  const addComment = () => {
    const newComment = commentRef.current.value;
    if (!newComment) return;

    setFetchState(FETCH_STATE.LOADING);
    api.actions.comments
      .add({ fieldId, actionId, text: newComment })
      .then(() => {
        fetchComments();
      })
      .catch(() => {
        toaster.error({
          message: t("ActionModal.Comments.Error.Submit"),

          position: "bottom-center",
        });
        setFetchState(FETCH_STATE.ERROR);
      });
  };

  const deleteComment = (id) => {
    if (!actionId || !fieldId) return;
    if (!id) return;

    setFetchState(FETCH_STATE.LOADING);
    api.actions.comments
      .delete({ fieldId, actionId, commentId: id })
      .then(() => {
        fetchComments();
      })
      .catch(() => {
        toaster.error({
          message: t("ActionModal.Comments.Error.Fetch"),
          position: "bottom-center",
        });
        setFetchState(FETCH_STATE.ERROR);
      });
  };

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  return {
    addComment,
    deleteComment,
    showOlder,
    hideOlder,
    fetchState,
    commentRef,
    displayedComments,
    comments,
    commentsRemaining,
    couldHideOlder,
  };
};
