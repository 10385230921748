import { useCallback, useMemo } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useUserContext } from "context/UserProvider";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import { useActionModalGetContext } from "context/ActionModalProvider";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { schemas } from "schemas";
import { useActionCalls, useTransformAction } from "./useActionsUtils";

export const useActionFormikSetup = ({ selectedField, onClose }) => {
  const { t } = useTranslation();

  const { userData } = useUserContext();
  const { action } = useActionModalGetContext();
  const { actionChoices } = useActionChoicesContext();
  // const { stressTypes } = useStressTypesContext();
  const { transformAction } = useTransformAction();
  const { postAction, patchAction, isLoading } = useActionCalls(
    selectedField.id
  );

  const isNew = action.type === ACTION_VIEW_MODE.new;

  const initialFormValues = useMemo(() => {
    return {
      fieldId: selectedField.id,
      ...(isNew
        ? {
            createdByUserId: userData.id,
            status: action.status,
            initialStatus: action.initialStatus,
            taskId: "",
          }
        : {}),
      ...(action.action
        ? { ...transformAction(action.action) }
        : {
            actionTypeId: 1,
            taskId: "",
            dateRange: {
              lower: "",
              upper: "",
            },
            // stressType: stressTypes?.[0]?.name[isEn ? "nameEn" : "nameGr"],
          }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField.id, action, transformAction]);

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: schemas.actions(t, actionChoices),
    validateOnChange: true,
    onSubmit: ({
      shouldCheckEnemyId,
      stressType,
      status,
      initialStatus,
      ...values
    }) => {
      let finalValues = { ...values };

      if (!finalValues.dateRange.lower || !finalValues.dateRange.upper) {
        finalValues.dateRange = {
          lower: moment(finalValues.datetimePerformed).format("YYYY-MM-DD"),
          upper: moment(finalValues.datetimePerformedEnd).format("YYYY-MM-DD"),
        };
      }

      let timeTaken = values.timeTaken || 0;
      if (finalValues?.equipment?.length > 0) {
        timeTaken = finalValues.equipment.reduce(
          (acc, curr) => acc + curr.duration,
          0
        );
      }
      finalValues.timeTaken = timeTaken;

      if (isNew) {
        postAction({
          status,
          values: finalValues,
          onSuccess: onClose,
          onError: onClose,
        });
      } else {
        patchAction({
          status,
          initialStatus,
          values: finalValues,
          onSuccess: onClose,
          onError: onClose,
        });
      }
    },
  });

  return { formik, isLoading };
};

export const useAnalysisFormikSetup = ({ selectedField, onClose, type }) => {
  const { t } = useTranslation();
  const { actionChoices } = useActionChoicesContext();
  const { userData } = useUserContext();
  const { postAction, isLoading } = useActionCalls(selectedField?.id);

  const diagnoseTaskId = actionChoices.actions.diagnose.taskId;

  const initialTypeId =
    type === "leaf"
      ? actionChoices.actions.diagnose?.operations[2]?.operationTypeId
      : actionChoices.actions.diagnose?.operations[1]?.operationTypeId;

  const initialValues = {
    actionTypeId: 1,
    fieldId: selectedField?.id,
    taskId: diagnoseTaskId,
    createdByUserId: userData.id,
    datetimePerformed: moment().toISOString(),
    datetimePerformedEnd: moment().toISOString(),
    dateRange: { lower: "", upper: "" },
    timeTaken: 10,
    diagnoseAction: {
      analysis: {},
      sampleCoordinates: [],
      operationTypeId: initialTypeId,
    },
  };

  const handleSubmit = useCallback(
    (values) => {
      const finalCoordinates = values?.diagnoseAction?.sampleCoordinates?.map(
        (coord) => ({ latitude: coord[0], longitude: coord[1] })
      );

      const { stressType, shouldCheckEnemyId, ...finalValues } = {
        ...values,
        diagnoseAction: {
          ...values.diagnoseAction,
          sampleCoordinates: finalCoordinates,
        },
      };

      if (!finalValues.dateRange.lower || !finalValues.dateRange.upper) {
        finalValues.dateRange = {
          lower: moment(finalValues.datetimePerformed).format("YYYY-MM-DD"),
          upper: moment(finalValues.datetimePerformedEnd).format("YYYY-MM-DD"),
        };
      }

      postAction({
        status: ACTION_STATUS.performed,
        values: finalValues,
        onSuccess: onClose,
      });
    },
    [onClose, postAction]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schemas.analysis(t),
    onSubmit: handleSubmit,
  });

  return { formik, isLoading };
};
