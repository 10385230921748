import { Box, useMediaQuery, useTheme } from "@mui/material";
import GlobalSidebarsContainer from "../sidebars/global-container/GlobalSidebarsContainer";

const PageWrapper = ({ children }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        position: "relative",
        marginLeft: isMd ? theme.mobile.margin.default : theme.width.navbar,
      }}
    >
      <GlobalSidebarsContainer />
      {children}
    </Box>
  );
};

export default PageWrapper;
