import { USER_ROLES } from "../../constants/userRoles";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getFarmers = (params = { regionId: undefined }) => {
  const { regionId } = params;
  const config = { ...(regionId ? { params } : {}) };

  return axiosInstance.get(`${URLS.users}/farmers/`, config);
};

const getUser = (id, role = USER_ROLES.FARMER) => {
  if (role === USER_ROLES.AGRONOMIST) {
    return axiosInstance.get(`${URLS.users}/agronomists/${id}/`);
  } else if (role === USER_ROLES.FARMER) {
    return axiosInstance.get(`${URLS.users}/farmers/${id}/`);
  }
};

/**
 * @param {Object} params - The request parameters.
 * @param {string} params.query - The search query param to filter.
 * @param {string[]} params.regions - A list of regions ids to filter.
 */
const getAvailableFarmers = (params) => {
  const finalParams = new URLSearchParams();

  const { query, regions } = params || {};
  if (query) {
    finalParams.append("query", query);
  }
  if (regions && regions.length > 0) {
    const finalRegions = regions.map((region) => region.value).join(",");
    finalParams.append("regions", finalRegions);
  }
  return axiosInstance.get(`${URLS.search}/farmer-fields/`, {
    params: finalParams,
  });
};

/**
 * @param {Object} params - The request parameters.
 * @param {string} params.query - The search query param to filter.
 * @param {string[]} params.regions - A list of regions ids to filter.
 */
const getAvailableAgronomists = (params) => {
  const { query, regions } = params || {};
  const finalParams = new URLSearchParams();
  if (query) {
    finalParams.append("icontains", [query]);
  }
  if (regions && regions.length > 0) {
    finalParams.append(
      "region",
      regions.map((region) => JSON.stringify({ id: region.value }))
    );
  }

  // return axiosInstance.get(`${URLS.search}/agronomist-field/query/execute/`, {
  // params: finalParams,
  // });
  return axiosInstance.get(`${URLS.users}/agronomists/`);
};

export const usersApi = {
  fetchOne: getUser,
  fetchFarmers: getFarmers,
  availableFarmers: getAvailableFarmers,
  availableAgronomists: getAvailableAgronomists,
};
