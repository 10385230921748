import { setOfArrayOfObjects, sortAlphabetically } from "./utils";

const findNode = (nodes, id) => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].value === id) {
      return nodes[i];
    }
    if (nodes[i].children) {
      const found = findNode(nodes[i].children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const getMinimalItem = (item) => {
  if (!item) return null;
  const { children, ...restItemInfo } = item;
  return restItemInfo;
};
const getAll = ({ data = [], isMinimal, level = 0 }) => {
  let result;
  if (level > 0) {
    return data?.reduce(
      (acc, item) =>
        acc.concat(
          getAll({ data: item.children, isMinimal, level: level - 1 })
        ),
      []
    );
  }
  if (isMinimal) {
    result = data?.map((item) => getMinimalItem(item));
  } else {
    result = data;
  }
  return sortAlphabetically(result, "label");
};

const getParents = ({ data, item }) => {
  const findParent = (searchNodes, parentIdPath) => {
    if (parentIdPath.length === 0) {
      return [];
    }
    const [parentId, ...restParentIdPath] = parentIdPath;
    const parent = searchNodes.find((n) => n.value === parentId);
    return [parent, ...findParent(parent.children, restParentIdPath)];
  };
  return findParent(data, item.parentIdPath);
};

const getItemById = ({ data, id, isMinimal, level }) => {
  let result;
  if (typeof level === "undefined") {
    result = findNode(data, id);
  } else {
    const allData = getAll({ data, isMinimal, level });
    result = allData.find((item) => item.value === id);
  }

  if (isMinimal) {
    return getMinimalItem(result);
  }
  return result;
};

const getChildren = ({ data, id, item, items, ids, isMinimal, level }) => {
  let itemToSearch = item;
  if (typeof id !== "undefined") {
    itemToSearch = getItemById({ data, id, level });
  }
  if (isMinimal) {
    return itemToSearch.children.map((item) => getMinimalItem(item));
  } else {
    return itemToSearch.children;
  }
};

const getChildrenOfMultiple = ({ data, ids, items = [], isMinimal, level }) => {
  let itemsToSearch = [...items];
  if (Array.isArray(ids)) {
    itemsToSearch = ids.map((id) => getItemById({ data, id, level }));
  }
  const children = [];
  itemsToSearch.forEach((item) => {
    children.push(...getChildren({ data, item, isMinimal, level }));
  });
  return children;
};

const deepToggleChildren = (item) => {
  const nodesToToggle = [];
  const toggleNodes = (nodes) => {
    nodes.forEach((node) => {
      if (node.children) {
        toggleNodes(node.children);
      }
      nodesToToggle.push(node);
    });
  };
  toggleNodes(item.children);
  return nodesToToggle;
};

const toggleParents = ({ shouldUnCheck, checkedNodesValues, data, item }) => {
  const parents = getParents({ data, item });
  parents.reverse();

  const nodesToToggle = [];
  if (shouldUnCheck) {
    const uncheckParentIfChildrenUnchecked = (parents, childValue) => {
      const [parent, ...grandParents] = parents;
      const allChildrenUnchecked = parent.children
        .filter((child) => child.value !== childValue)
        .every((child) => !checkedNodesValues.includes(child.value));
      if (allChildrenUnchecked) {
        nodesToToggle.push({ value: parent.value, label: parent.label });
        if (grandParents.length > 0) {
          uncheckParentIfChildrenUnchecked(grandParents, parent.value);
        }
      }
    };
    uncheckParentIfChildrenUnchecked(parents, item.value);
  } else {
    const checkParents = (parents) => {
      const [parent, ...grandParents] = parents;
      nodesToToggle.push({ value: parent.value, label: parent.label });
      if (grandParents.length > 0) {
        checkParents(grandParents);
      }
    };
    checkParents(parents);
  }
  return nodesToToggle;
};

const toggleItem = ({ checkedNodes, data, item, id }) => {
  const itemToToggle = id == null ? item : getItemById({ data, id });
  if (!itemToToggle) {
    return checkedNodes;
  }

  const { value, label, parentIdPath, children } = itemToToggle;
  const checkedNodesValues = checkedNodes.map((node) => node.value);
  const shouldUnCheck = checkedNodesValues.includes(value);

  let nodesToToggle = [];
  // Toggle all nested children
  if (children) {
    const toggledChildren = deepToggleChildren(itemToToggle);
    nodesToToggle = nodesToToggle.concat(toggledChildren);
  }

  // Toggle parents
  if (Array.isArray(parentIdPath) && parentIdPath.length > 0) {
    const toggledParents = toggleParents({
      shouldUnCheck,
      checkedNodesValues,
      data,
      item: itemToToggle,
    });
    nodesToToggle = nodesToToggle.concat(toggledParents);
  }

  // Toggle node
  nodesToToggle.push({ value, label });

  // Update checked nodes
  let newCheckedNodes = [...checkedNodes];

  if (shouldUnCheck) {
    nodesToToggle.forEach((nodeToToggle) => {
      newCheckedNodes = newCheckedNodes.filter(
        (checked) => checked.value !== nodeToToggle.value
      );
    });
  } else {
    newCheckedNodes = setOfArrayOfObjects(
      [...newCheckedNodes, ...nodesToToggle],
      "value"
    );
  }

  return newCheckedNodes;
};

const nestedUtils = {
  getAll,
  getParents,
  getItemById,
  getChildren,
  getChildrenOfMultiple,
  toggleItem,
};

export default nestedUtils;
