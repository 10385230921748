import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ACTION_STATUS } from "constants/actionStatus";
import { useUserContext } from "context/UserProvider";
import { ReactComponent as UserAvatarIcon } from "assets/icons/user-avatar.svg";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";
import NormalText from "../../../../Typography/NormalText";
import LoadingButton from "../../../../LoadingButton/LoadingButton";

const statusTranslated = (status, t) => {
  switch (status) {
    case ACTION_STATUS.failed:
      return t("ActionModal.Badge.Failed");
    case ACTION_STATUS.suggested:
      return t("ActionModal.Badge.Suggested");
    case ACTION_STATUS.performed:
      return t("ActionModal.Badge.Performed");
    default:
      return "";
  }
};

const ViewActionModalHeader = ({
  action,
  onDelete,
  onToggleComplete,
  onEdit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { status } = action;
  const { userData } = useUserContext();
  const [finalStatus, setFinalStatus] = useState(status);

  useEffect(() => {
    if (status === ACTION_STATUS.suggested) {
      const compareDate = moment(action.action.dateRange.upper).endOf("day");
      const nowMoment = moment();
      if (compareDate.isBefore(nowMoment)) {
        setFinalStatus(ACTION_STATUS.failed);
      }
    }
  }, [action.action?.dateRange?.upper, status]);

  let badgeClassname = "action-status-badge";
  if (finalStatus === ACTION_STATUS.failed) {
    badgeClassname += " error";
  } else if (finalStatus === ACTION_STATUS.performed) {
    badgeClassname += " success";
  } else {
    badgeClassname += " pending";
  }

  const isOwner = userData?.id == action?.action?.createdByUserId ?? false;

  return (
    <>
      <div className="action-modal-header-row space-between">
        <div className="action-modal-header-title">
          {t("ActionModal.Mode.ViewAction")}
        </div>
        <div className="action-modal-header-btn-group">
          {isOwner && (
            <>
              <LoadingButton
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={onDelete}
                className="action-action-button"
                variant="text"
              >
                {t("Global.Delete")}
              </LoadingButton>
              <ButtonCustom onClick={onEdit} className="action-action-button">
                {t("Global.Edit")}
              </ButtonCustom>
            </>
          )}
          <ButtonCustom
            onClick={onToggleComplete}
            buttonType="filled"
            className="action-action-button"
          >
            {t(
              status !== ACTION_STATUS.performed
                ? "ActionModal.Complete"
                : "ActionModal.UndoComplete"
            )}
          </ButtonCustom>
        </div>
      </div>
      <div className="action-modal-header-row">
        <div className={badgeClassname}>{statusTranslated(finalStatus, t)}</div>
        {action.action.createdAt && (
          <div className="action-header-assignment">
            <NormalText
              className="action-header-assignment-type"
              text={t("ActionModal.Assignment.Assigned")}
            />
            <UserAvatarIcon
              alt="user-avatar-icon"
              fill="#0F6CBD"
              width="20px"
              height="20px"
            />
            <NormalText
              text={moment(action.action.createdAt)?.format("DD MMM YYYY")}
            />
          </div>
        )}
        {action.action.datetimePerformedEnd && (
          <div className="action-header-assignment">
            <NormalText
              className="action-header-assignment-type"
              text={t("ActionModal.Assignment.Completion")}
            />
            <UserAvatarIcon
              alt="user-avatar-icon"
              fill="#FF8C00"
              width="20px"
              height="20px"
            />
            <NormalText
              text={moment(action.datetimePerformedEnd)?.format("DD MMM YYYY")}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ViewActionModalHeader;
