import { notifications } from "../../../constants/notifications";
import ActionNotificationContent from "./notification-content/ActionNotificationContent";
import RequestNotificationContent from "./notification-content/RequestNotificationContent";

const Notification = ({ notification, tab }) => {
  const { type: notificationType } = notification || {};

  const shouldShow = (type) =>
    notificationType === type && (tab === "all" || notificationType === tab);

  return (
    <>
      {shouldShow(notifications.types.activity) && (
        <ActionNotificationContent notification={notification} />
      )}
      {shouldShow(notifications.types.collaboration) && (
        <RequestNotificationContent notification={notification} />
      )}
    </>
  );
};

export default Notification;
