import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  styled,
} from "@mui/material";
import NormalText from "../Typography/NormalText";

const LabelStyled = styled(FormControlLabel)(() => ({
  ".MuiFormControlLabel-label": {
    fontSize: "14px",
    fontWeight: 400,
  },
}));

const RadioGroupStyled = styled(MuiRadioGroup)({
  ".MuiButtonBase-root.MuiRadio-root": {
    paddingTop: "2px",
    paddingBottom: "2px",
  },
});

const RadioGroup = ({
  classNames = {}, // { root: '', label: ''}
  direction = "row",
  id,
  labelPlacement = "end", // 'start' | 'end' | 'top' | 'bottom'
  name,
  onChange,
  options = [],
  value,
  isViewMode,
  styles = {}, // { root: '', label: ''}
}) => {
  const foundValue = options.find((option) => option.value === value)?.label;
  return (
    <FormControl>
      {isViewMode ? (
        <NormalText
          style={{ lineHeight: "24px", paddingLeft: "12px" }}
          text={foundValue || "-"}
        />
      ) : (
        <RadioGroupStyled
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          row={direction === "row"}
          className={classNames.root}
          sx={styles.root}
        >
          {options.map((option) => (
            <LabelStyled
              key={option.value}
              value={option.value}
              label={option.label}
              control={<Radio size="small" />}
              labelPlacement={labelPlacement}
              className={classNames.label}
              sx={styles.label}
            />
          ))}
        </RadioGroupStyled>
      )}
    </FormControl>
  );
};

export default RadioGroup;
