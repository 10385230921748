import moment from "moment";
import { useEffect, useState } from "react";

const useSelectedWeek = (date) => {
  const [state, setState] = useState({
    selectedWeek: undefined,
    date,
  });

  useEffect(() => {
    const currentDate = moment(date).startOf("day").add(12, "hours");
    if (
      state.selectedWeek &&
      currentDate.isValid() &&
      currentDate.isBetween(state.selectedWeek.start, state.selectedWeek.end)
    ) {
      return;
    }

    const weekStart = moment(date).startOf("isoWeek");
    const weekEnd = moment(date).endOf("isoWeek");

    let days = {};
    let day = weekStart.clone();
    while (day.isSameOrBefore(weekEnd)) {
      days[day.format("YYYY-MM-DD")] = undefined;
      day.add(1, "day");
    }

    setState({
      date,
      selectedWeek: {
        start: weekStart,
        end: weekEnd,
        days,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return { ...state };
};

export default useSelectedWeek;
