import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getCarbonFootprint = (payload) => {
  const params = {
    field_id: payload.fieldId,
    date_range: payload.dateRange,
  };
  return axiosInstance.get(`${URLS.statistics}/carbon-footprint/`, {
    params,
  });
};

const getWaterConsumption = (payload) => {
  const params = {
    field_id: payload.fieldId,
    date_range: payload.dateRange,
  };
  return axiosInstance.get(`${URLS.statistics}/irrigation-usage/`, {
    params,
  });
};

export const emissionsApi = {
  fetchCarbonFootprint: getCarbonFootprint,
  fetchWaterConsumption: getWaterConsumption,
};
