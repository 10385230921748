import { useCallback, useEffect, useRef, useState } from "react";
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { api } from "../api";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const useFirebaseConfig = ({ handleMessage }) => {
  const isSetupUpRef = useRef(false);
  const setupNotifications = useCallback(async () => {
    if (!handleMessage) return;

    try {
      // Request permission for notifications
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        // Get the FCM token
        const token = await getToken(messaging);
        await api.account.registerFCMToken(token);
        isSetupUpRef.current = true;
      } else {
        console.error("Notification permission denied.");
      }

      onMessage(messaging, handleMessage);
    } catch (error) {
      console.error("Error setting up notifications:", error);
    }
  }, [handleMessage]);

  useEffect(() => {
    if (isSetupUpRef.current) return;
    if (typeof setupNotifications === "function") {
      setupNotifications();
    }
  }, [setupNotifications]);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  return { messaging, onMessageListener };
};

export default useFirebaseConfig;
