import React, { useEffect, useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import markerSelected from "assets/icons/map/markerSelected.svg";
import markerNotSelected from "assets/icons/map/markerNotSelected.svg";
import { useAddFieldsContext } from "context/AddFieldProvider";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import MarkerPopup from "./MarkerPopup";

const markerClusters = L.markerClusterGroup();
const selctedIcon = new L.Icon({
  iconUrl: markerSelected,
  iconSize: [25, 41],
});

const notSelectedIcon = new L.Icon({
  iconUrl: markerNotSelected,
  iconSize: [25, 41],
});

const ClusterMarkers = ({ fields }) => {
  const { addFieldOn } = useAddFieldsContext();

  const [showModal, setShowModal] = useState(false);
  const [clickedField, setClickedField] = useState({});

  const map = useMap();

  //show popup on click
  const markerClick = (field) => {
    setClickedField(field);
    setShowModal(true);
    map.panTo(
      new L.LatLng(
        field.centerPoint.coordinates[1],
        field.centerPoint.coordinates[0]
      )
    );
  };
  useEffect(() => {
    markerClusters.clearLayers();
    fields?.forEach((item) => {
      const center = [
        item.centerPoint.coordinates[1],
        item.centerPoint.coordinates[0],
      ];
      return (
        L.marker(center, {
          icon: clickedField.id === item.id ? selctedIcon : notSelectedIcon,
        })
          .addTo(markerClusters)
          ///adding the popup
          .on("click", () => markerClick(item))
      );
    });

    map.addLayer(markerClusters);
  }, [map, fields, clickedField]);

  useEffect(() => {
    if (addFieldOn) {
      map.removeLayer(markerClusters);
    }
  }, [addFieldOn]);

  return (
    <>
      {showModal && !addFieldOn && (
        <MarkerPopup field={clickedField}></MarkerPopup>
      )}
    </>
  );
};

export default ClusterMarkers;
