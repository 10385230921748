import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "constants/actionStatus";
import { useActionChoicesContext } from "context/GeneralAgroProvider";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FormSelectInput } from "form";
import Title from "../../../Typography/Title";
import DateSpan from "../date-span/DateSpan";
import DetailSection from "../DetailSection";
import AnalysisResults from "../analysis-results/AnalysisResults";
import { useAnalysisFields } from "./useAnalysisFields";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const DiagnosePerformed = ({ viewMode, field }) => {
  const { t } = useTranslation();
  const { actionChoices } = useActionChoicesContext();

  const status = ACTION_STATUS.performed;
  const operations = actionChoices.actions.diagnose?.operations;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { analysisFields } = useAnalysisFields(viewMode);

  return (
    <div className="action-modal-card action-details-box">
      <div className="details-half-width">
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection title={t("ActionModal.DiagnoseType")} isRequired>
          <FormSelectInput
            name="diagnoseAction.operationTypeId"
            shouldHaveErrorBehaviorAfterSubmit
            options={operations?.map(
              ({ operationTypeName, operationTypeId }) => ({
                value: operationTypeId,
                label: operationTypeName,
              })
            )}
            isViewMode={isViewMode}
          />
        </DetailSection>
      </div>
      {analysisFields ? (
        <DetailSection title={t("ActionModal.AnalysisResult.Title")}>
          <AnalysisResults
            field={field}
            analysisFields={analysisFields}
            isViewMode={isViewMode}
          />
        </DetailSection>
      ) : (
        <div className="details-half-width">
          <ActionModalDescriptionSection
            actionBaseName="diagnoseAction"
            isViewMode={isViewMode}
          />
        </div>
      )}
    </div>
  );
};

export default DiagnosePerformed;
