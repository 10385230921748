import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSideBySideImageOverlays } from "../../../hooks/useMapImageOverlay";
import SmallText from "../../Typography/SmallText";
import CloudCoverage from "../../cloud-coverage/CloudCoverage";
import DateField from "../../dates/DateField";
import {
  useFetchIndiceImage,
  useFetchInicesMonthly,
} from "../../../hooks/useFetchAllIndicesMeta";

const IndicesComparatorNew = ({ initialDate, indiceType }) => {
  const { i18n, t } = useTranslation();

  const [date1, setDate1] = useState(initialDate);
  const [date2, setDate2] = useState(initialDate);
  const [month1, setMonth1] = useState(initialDate);
  const [month2, setMonth2] = useState(initialDate);

  const {
    fetchState: fetchState1,
    monthlyIndices: monthlyIndices1,
    availableDates: availableDates1,
  } = useFetchInicesMonthly(month1);

  const {
    fetchState: fetchState2,
    monthlyIndices: monthlyIndices2,
    availableDates: availableDates2,
  } = useFetchInicesMonthly(month2);

  const { png: png1, cloudCover: cloudCover1 } = useFetchIndiceImage(
    indiceType,
    monthlyIndices1,
    date1
  );

  const { png: png2, cloudCover: cloudCover2 } = useFetchIndiceImage(
    indiceType,
    monthlyIndices2,
    date2
  );

  useSideBySideImageOverlays(png1, png2);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px 16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "8px",
        }}
      >
        <DateField
          style={{
            backgroundColor: "#FFFFFFAA",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            width: "200px",
          }}
          isLoading={fetchState1 === "loading"}
          defaultValue={moment(initialDate)}
          onChange={(date) => setDate1(date.format("YYYY-MM-DD"))}
          onMonthChange={(date) => setMonth1(date.format("YYYY-MM-DD"))}
          availableDates={availableDates1}
          locale={i18n.language}
        />
        {!png1?.pngImage && (
          <SmallText
            style={{
              color: "white",
              textShadow: "2px 2px 2px black",
              fontStyle: "italic",
              fontSize: "10px",
            }}
            text={t("SingleFieldPage.GroundConditions.NoIndiceImageFound")}
          />
        )}
        {typeof cloudCover1 !== "undefined" && (
          <CloudCoverage cloudCoverage={cloudCover1} shouldDisplayIcon />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "end",
        }}
      >
        <DateField
          style={{
            backgroundColor: "#FFFFFFAA",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            width: "200px",
          }}
          isLoading={fetchState2 === "loading"}
          defaultValue={moment(initialDate)}
          onChange={(date) => setDate2(date.format("YYYY-MM-DD"))}
          onMonthChange={(date) => setMonth2(date.format("YYYY-MM-DD"))}
          availableDates={availableDates2}
          locale={i18n.language}
        />
        {!png2?.pngImage && (
          <SmallText
            style={{
              color: "white",
              textShadow: "2px 2px 2px black",
              fontStyle: "italic",
              fontSize: "10px",
            }}
            text={t("SingleFieldPage.GroundConditions.NoIndiceImageFound")}
          />
        )}
        {typeof cloudCover2 !== "undefined" && (
          <CloudCoverage cloudCoverage={cloudCover2} shouldDisplayIcon />
        )}
      </div>
    </div>
  );
};

export default IndicesComparatorNew;
