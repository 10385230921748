import moment from "moment";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getFiveDaysForecast = ({ fieldId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/hourly-forecast/?field_id=${fieldId}&agency=8`
  );
};

const getHourlyHistory = ({ fieldId, timeFrom, timeTo }) => {
  const timeFromIsValid = moment(timeFrom).isValid();

  if (!fieldId || !timeFromIsValid) {
    return;
  }

  let timeFromFinal = moment(timeFrom).format();

  let timeToFinal =
    Boolean(timeTo) && moment(timeTo).isValid()
      ? moment(timeTo).format()
      : moment().add(-1, "hour").startOf("hour").format();

  const params = {
    field_id: fieldId,
    time_from: timeFromFinal,
    time_to: timeToFinal,
    agency: 8,
  };
  return axiosInstance.get(`${URLS.externalRequests}/hourly-history/`, {
    params,
  });
};

const getHourlyWeatherOfDay = ({ fieldId, date }) => {
  const params = {
    field_id: fieldId,
    agency: 8,
    time_from: moment(date).startOf("day").format(),
    time_to: moment(date).startOf("day").add(1, "day").format(),
  };
  return axiosInstance.get(
    `${URLS.externalRequests}/hourly-forecast/?field_id=${fieldId}&agency=8`,
    {
      params,
    }
  );
};

const getWeatherHistory = ({ fieldId, timeFrom, timeTo, agency = 8 }) => {
  const timeFromIsValid = moment(timeFrom).isValid();

  if (!fieldId || !timeFromIsValid) {
    return;
  }

  let timeFromFinal = moment(timeFrom).format();

  let timeToFinal =
    Boolean(timeTo) && moment(timeTo).isValid()
      ? moment(timeTo).format()
      : moment().add(-1, "day").startOf("day").format();

  const params = {
    field_id: fieldId,
    agency,
    time_from: timeFromFinal,
    time_to: timeToFinal,
  };
  return axiosInstance.get(`${URLS.externalRequests}/daily-historical/`, {
    params,
  });
};

const getWeatherForecast = ({ fieldId, timeFrom, timeTo, agency = 8 }) => {
  if (
    !fieldId ||
    !timeFrom ||
    !moment(timeFrom).isValid() ||
    !timeTo ||
    !moment(timeTo).isValid()
  ) {
    return Promise.reject(new Error("Invalid Weather parameters"));
  }

  let timeFromFinal = moment(timeFrom).format();

  let timeToFinal = moment(timeTo).format();

  const params = {
    field_id: fieldId,
    agency,
    time_from: timeFromFinal,
    time_to: timeToFinal,
  };

  return axiosInstance.get(`${URLS.externalRequests}/daily-forecast/`, {
    params,
  });
};

const getOpenMeteoWeatherForecast = (fieldId, forecastStart, forecastEnd) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/openmeteo-forecast-weather/?field_id=${fieldId}&start_date=${forecastStart}&end_date=${forecastEnd}`
  );
};

const getOpenMeteoWeatherHistory = (fieldId, startDate, endDate) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/openmeteo-historical-weather/?field_id=${fieldId}&start_date=${startDate}&end_date=${endDate}`
  );
};

export const weatherApi = {
  forecast: {
    fiveDays: getFiveDaysForecast,
    hourly: getHourlyWeatherOfDay,
    daily: getWeatherForecast,
    external: getOpenMeteoWeatherForecast,
  },
  history: {
    hourly: getHourlyHistory,
    daily: getWeatherHistory,
    external: getOpenMeteoWeatherHistory,
  },
};
