import { useLocation } from "react-router-dom";
import { paths } from "../utils/paths";
import { notifications } from "../constants/notifications";
import { useTranslation } from "react-i18next";
import { toaster } from "../utils/toaster";

export const useHandlePushMessages = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const handleActivityMessage = (payload) => {
    const fieldId = payload.notification.extra?.field?.id;
    const fieldPath = paths.field(fieldId);
    const isSameFieldPage = location.pathname.includes(fieldPath);
    if (isSameFieldPage) {
      toaster.info({
        id: "new-action-message-on-this-field",
        title: t("Inbox.Toasts.Activities.Title"),
        message: t("Inbox.Toasts.Activities.Message"),
        shouldAutoClose: false,
        shouldReload: true,
      });
    }
  };

  const handleRequestMessage = () => {
    const isUsersPage =
      location.pathname.includes(paths.farmers) ||
      location.pathname.includes(paths.agronomists);
    if (isUsersPage) {
      toaster.info({
        id: "new-request-message",
        title: t("Inbox.Toasts.Collaborations.Title"),
        message: t("Inbox.Toasts.Collaborations.Message"),
        shouldAutoClose: false,
        shouldReload: true,
      });
    }
  };

  const handleIncomingMessageToast = (payload = {}) => {
    const { notification } = payload;
    const { entity: type } = notification || {};

    if (type === notifications.types.activity) {
      handleActivityMessage(payload);
    } else if (type === notifications.types.collaboration) {
      handleRequestMessage();
    }
  };

  return {
    handleIncomingMessageToast,
  };
};
