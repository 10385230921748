import { useTranslation } from "react-i18next";
import { Badge } from "@mui/material";
import { formatInboxDatetime } from "../../../../utils/formatInboxDatetime";
import AvatarCustom from "../../../AvatarCustom/AvatarCustom";
import SmallText from "../../../Typography/SmallText";
import NotificationDropdown from "./NotificationDropdown";
import { useInboxContext } from "../../../../context/InboxProvider";

const NotificationContentContainer = ({ notification, children, onClick }) => {
  const { t } = useTranslation();
  const { readOne } = useInboxContext();
  const { user, createdAt, id, readAt } = notification || {};
  const name = user ? user.firstName + " " + user.lastName : undefined;
  const sentBefore = formatInboxDatetime(t, createdAt);

  const handleClick = () => {
    readOne(id);
    onClick?.();
  };

  return (
    <div className="inbox-sidebar-item notification" onClick={handleClick}>
      {user && (
        <div className="notification-left-col">
          {readAt ? (
            <AvatarCustom name={name} width="32px" height="32px" />
          ) : (
            <Badge variant="dot" color="primary">
              <AvatarCustom name={name} width="32px" height="32px" />
            </Badge>
          )}
          <SmallText className="notification-time">{sentBefore}</SmallText>
        </div>
      )}
      <div className="notification-content action-notification-content">
        {children}
      </div>
      <NotificationDropdown id={id} />
    </div>
  );
};

export default NotificationContentContainer;
