import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getMyInbox = (page) => {
  const params = new URLSearchParams();
  params.append("page", page);
  return axiosInstance.get(`${URLS.inbox}/`, { params });
};

const getNotification = (id) => {
  return axiosInstance.get(`${URLS.inbox}/${id}/`);
};

const readNotification = (id) => {
  return axiosInstance.post(`${URLS.inbox}/${id}/mark-as-read/`);
};

/**
 * @param {string[]} ids
 */
const bulkReadNotifications = (ids) => {
  const notifications = {
    notifications: ids,
  };
  return axiosInstance.post(`${URLS.inbox}/mark-as-read`, notifications);
};

const readAllNotifications = () => {
  return axiosInstance.post(`${URLS.inbox}/mark-all-as-read/`);
};

const seeAllNotifications = () => {
  return axiosInstance.post(`${URLS.inbox}/mark-all-as-seen/`);
};

const deleteNotification = (id) => {
  return axiosInstance.delete(`${URLS.inbox}/${id}/`);
};

export const inboxApi = {
  bulkRead: bulkReadNotifications,
  fetchAll: getMyInbox,
  fetchOne: getNotification,
  readAll: readAllNotifications,
  readOne: readNotification,
  seeAll: seeAllNotifications,
  deleteOne: deleteNotification,
};
