import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDropdownMenu } from "../../../../hooks/useDropdownMenu";
import { useInboxContext } from "../../../../context/InboxProvider";
import MenuDropdown from "../../../menu-dropdown/MenuDropdown";

const NotificationDropdown = ({ id }) => {
  const { t } = useTranslation();
  const { isOpen, anchorEl, open, close } = useDropdownMenu();

  const { readOne, deleteOne } = useInboxContext();

  const handleRead = () => {
    readOne(id);
  };

  const handleDelete = () => {
    deleteOne(id);
  };

  const items = [
    {
      label: t("Inbox.Dropdown.Read", { count: 1 }),
      iconClass: "icon-read-one",
      onClick: handleRead,
    },
    {
      label: t("Inbox.Dropdown.Delete"),
      iconClass: "icon-delete-one",
      onClick: handleDelete,
    },
  ];

  return (
    <MenuDropdown
      items={items}
      isOpen={isOpen}
      anchorEl={anchorEl}
      onClose={close}
      onOpen={open}
      placement="bottom-end"
    >
      <IconButton size="small">
        <MoreHorizIcon />
      </IconButton>
    </MenuDropdown>
  );
};

export default NotificationDropdown;
