import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getEnemyInformation = ({ stressId, biocyclePhaseId, enemyId }) => {
  const params = {
    ...(biocyclePhaseId ? { bio_id: biocyclePhaseId } : {}),
    ...(enemyId ? { enemy_id: enemyId } : {}),
  };
  const config = { ...{ params } };

  return axiosInstance.get(
    `${URLS.externalRequests}/agro/stresses/${stressId}/get-syptoms/`,
    config
  );
};

const getEnemiesInformation = (enemies) => {
  const promises = enemies
    .map((enemy) => ({ ...enemy, enemyId: enemy.uuid }))
    .map((enemy) => getEnemyInformation(enemy));

  return Promise.all(promises);
};

const getEnemyOperations = (enemyId, biocyclePhaseId) =>
  axiosInstance.get(
    `${URLS.externalRequests}/agro/enemies/get-operations/?uuid=${enemyId}&bio_uuid=${biocyclePhaseId}`,
    {}
  );

const getEnemiesOperations = (enemies) => {
  const promises = enemies.map((enemy) =>
    getEnemyOperations(enemy.uuid, enemy.biocyclePhaseId)
  );

  return Promise.all(promises);
};

export const enemiesApi = {
  fetchOne: getEnemyInformation,
  fetchMultiple: getEnemiesInformation,
  fetchOperations: getEnemyOperations,
  fetchOperationsOfMultiple: getEnemiesOperations,
};
