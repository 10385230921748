import { Button, useTheme } from "@mui/material";
import React from "react";

const ButtonCustom = ({
  children,
  height,
  fontSize,
  buttonType,
  onClick,
  type,
  style = {},
  className,
  variant = "outlined",
  ...props
}) => {
  const theme = useTheme();
  const filledStyles = {
    backgroundColor: theme.color.primary90,
    color: theme.color.white100,
    borderColor: theme.color.primary90,
    "&:active": {
      backgroundColor: theme.color.primary100,
      borderColor: theme.color.primary100,
    },
    "&:hover": {
      borderColor: theme.color.primary100,
      color: theme.color.white100,
      backgroundColor: theme.color.primary100,
    },
    "&.Mui-disabled": {
      background: "#f3f3f3",
      color: theme.color.black90,
    },
  };
  const outlinedStyles = {
    backgroundColor: theme.color.white100,
    color: theme.color.black90,
    borderColor: theme.color.black30,
    "&:active": {
      backgroundColor: theme.color.primary30,
      borderColor: theme.color.primary100,
    },
    "&:hover": {
      borderColor: theme.color.primary60,
      color: theme.color.primary90,
      backgroundColor: theme.color.white100,
    },
    "&.Mui-disabled": {
      background: theme.color.black30,
      color: theme.color.black90,
    },
  };

  const deleteStyles = {
    backgroundColor: theme.color.error60,
    color: theme.color.white100,
    borderColor: theme.color.black30,
    "&:hover": {
      borderColor: theme.color.black30,
      color: theme.color.white100,
      backgroundColor: theme.color.error90,
    },
    "&:active": {
      backgroundColor: theme.color.error90,
      borderColor: theme.color.black100,
    },
  };
  const selectStyles = () => {
    if (buttonType === "filled") {
      return filledStyles;
    } else if (buttonType === "outlined") {
      return outlinedStyles;
    } else if (buttonType === "delete") {
      return deleteStyles;
    } else {
      return {};
    }
  };
  return (
    <Button
      {...props}
      disabled={props.disabled}
      type={type}
      onClick={onClick}
      sx={{
        height,
        borderRadius: "50px",
        textTransform: "none",
        fontSize,
        whiteSpace: "nowrap",
        ...selectStyles(),
        ...style,
      }}
      variant={variant}
      className={className}
    >
      {children}
    </Button>
  );
};

export default ButtonCustom;
