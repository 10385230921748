import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import "./toast-message.css";
import NormalText from "../Typography/NormalText";

const ToastMessage = ({ title, message, shouldReload }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div className="toast-message">
      {title && <NormalText style={{ fontWeight: "600" }}>{title}</NormalText>}
      {message && <NormalText> {message} </NormalText>}
      {shouldReload && (
        <button
          onClick={() => window.location.reload()}
          className="btn-unstyled toast-message-link"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          <NormalText className="toast-message-link">
            {t("Global.ReloadPage")}
          </NormalText>
        </button>
      )}
    </div>
  );
};

export default ToastMessage;
