import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useStressTypesContext } from "../../../context/GeneralAgroProvider";
import { sortObjectsByProperty } from "../../../utils/sortUtils";
import { FormRadioGroupInput, FormSelectInput } from "../../../form";
import Title from "../../Typography/Title";
import {
  checkIfEnemyIsInFilteredEnemies,
  checkIfStressIsInFilteredStresses,
} from "../utils/actionModalUtils";
import "../action-modal.css";
import useFetchEnemiesOfStress from "./useFetchEnemiesOfStress";
import useFilteredStresses from "./useFilteredStresses";
import NormalText from "../../Typography/NormalText";
import { ACTION_VIEW_MODE } from "../../../constants/actionViewModes";

const stressTypeIsAbiotic = (isEn, stressType) =>
  stressType === (isEn ? "Abiotic stress" : "Αβιοτική καταπόνηση");

const ActionCause = ({ viewMode, isRequired = false }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { handleChange, values, setFieldValue, setValues, validateForm } =
    useFormikContext();

  const { stressTypes } = useStressTypesContext();
  const { filteredStresses } = useFilteredStresses(values?.stressType);
  const { filteredEnemies, isLoading: isEnemiesLoading } =
    useFetchEnemiesOfStress(values?.caused?.stressId);

  useEffect(() => {
    checkIfStressIsInFilteredStresses(
      isViewMode,
      values,
      setValues,
      filteredStresses
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStresses]);

  const handleStressChange = (e) => {
    handleChange(e);
    const selectedStressUuid = e.target.value;

    let selectedStressName =
      filteredStresses?.find((stress) => e?.target?.value === stress.uuid)?.name
        ?.nameGr || "Missing info";

    if (!selectedStressUuid) {
      return;
    }

    setFieldValue("caused.stressName", selectedStressName).then(() =>
      validateForm()
    );
  };

  const handleEnemyChange = (e) => {
    handleChange(e);
    const enemyId = e.target.value;
    setFieldValue(
      "caused.enemyName",
      filteredEnemies.filter((enemy) => enemy.uuid === enemyId)[0]?.name
        ?.nameGr || "Missing info"
    ).then(() => validateForm());
  };

  useEffect(() => {
    checkIfEnemyIsInFilteredEnemies(
      isViewMode,
      values,
      setValues,
      filteredEnemies
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEnemies]);

  const isViewMode =
    viewMode === ACTION_VIEW_MODE.view || viewMode === ACTION_VIEW_MODE.edit;

  const enemyTitle = stressTypeIsAbiotic(isEn, values?.stressType)
    ? "ActionModal.Defficiency"
    : "ActionModal.Enemy";

  const shouldShowEnemy =
    !stressTypeIsAbiotic(isEn, values?.stressType) ||
    filteredEnemies.length > 0;

  const hasCause = values?.caused?.stressId || values?.caused?.enemyId;

  return (
    <div className="action-top-box-section">
      <div className="action-cause-title-section">
        <Title title={t("ActionModal.Cause")} />
        <NormalText
          text={!isRequired && "(" + t("ActionModal.Optional") + ")"}
        />
      </div>
      <FormRadioGroupInput
        name="stressType"
        isViewMode={isViewMode}
        options={stressTypes?.map(({ name }) => ({
          value: isEn ? name.nameEn : name.nameGr,
          label: (isEn ? name.nameEn : name.nameGr)?.split(" ")?.[0],
        }))}
      />
      <div className="action-cause-title-section">
        <Title title={t("ActionModal.Stress")} />
        <NormalText
          text={!isRequired && "(" + t("ActionModal.Optional") + ")"}
        />
      </div>
      {isViewMode && !hasCause ? (
        <NormalText style={{ paddingLeft: "12px" }}>-</NormalText>
      ) : (
        <>
          <FormSelectInput
            name="caused.stressId"
            isViewMode={isViewMode}
            placeholder={t("ActionModal.Stress")}
            onChange={handleStressChange}
            shouldHaveErrorBehaviorAfterSubmit
            options={filteredStresses
              ?.map(({ uuid, name }) => ({
                value: uuid,
                label: isEn ? name?.nameEn : name?.nameGr,
              }))
              .sort((a, b) => sortObjectsByProperty(a, b, "label"))}
          />
          {shouldShowEnemy && (
            <FormSelectInput
              name="caused.enemyId"
              isViewMode={isViewMode}
              placeholder={t(enemyTitle)}
              onChange={handleEnemyChange}
              isDisabled={!values?.caused?.stressId || isEnemiesLoading}
              isLoading={isEnemiesLoading}
              shouldHaveErrorBehaviorAfterSubmit
              options={filteredEnemies
                ?.map(({ uuid, name }) => ({
                  value: uuid,
                  label: isEn ? name?.nameEn : name?.nameGr,
                }))
                .sort((a, b) => sortObjectsByProperty(a, b, "label"))}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ActionCause;
