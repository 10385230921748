import { USER_ROLES } from "../constants/userRoles";

export const userRoleRead = (t, role) => {
  let userRoleRead = "";
  if (role === USER_ROLES.FARMER) {
    userRoleRead = t("Global.Farmer");
  } else if (role === USER_ROLES.AGRONOMIST) {
    userRoleRead = t("Global.Agronomist");
  }

  return userRoleRead;
};
