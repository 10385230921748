import { useEffect, useState } from "react";
import { api } from "../../../api";

const useFetchEnemiesOfStress = (stressId) => {
  const [filteredEnemies, setFilteredEnemies] = useState([]);
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    error: null,
  });

  useEffect(() => {
    if (stressId) {
      setFetchState(() => ({ isLoading: true, error: null }));
      api.stresses
        .fetchEnemies(stressId)
        .then(({ data }) => {
          const enemies = [
            ...data.pathogens,
            ...data.insects,
            ...data.weeds,
            ...data.mineralImbalances,
          ];

          setFilteredEnemies(enemies);
          setFetchState(() => ({ isLoading: false, error: null }));
        })
        .catch((err) => {
          setFetchState(() => ({ isLoading: false, error: err }));
        });
    } else {
      setFilteredEnemies([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stressId]);

  return { filteredEnemies, ...fetchState };
};

export default useFetchEnemiesOfStress;
