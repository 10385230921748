import { useFormikContext } from "formik";
import "../action-modal-header.css";
import NewAnalysisHeader from "./modes/NewAnalysisHeader";
import { ACTION_VIEW_MODE } from "../../../../constants/actionViewModes";

const AnalysisHeader = ({ isLoading }) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const viewMode = ACTION_VIEW_MODE.new;

  return (
    <div className="action-modal-header-wrapper">
      {viewMode === ACTION_VIEW_MODE.new && (
        <NewAnalysisHeader onSubmit={handleSubmit} isLoading={isLoading} />
      )}
    </div>
  );
};

export default AnalysisHeader;
