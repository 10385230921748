import { createContext, useContext, useMemo, useState } from "react";
import useFetchAllIndicesMeta from "../hooks/useFetchAllIndicesMeta";

const IndicesContext = createContext({
  indices: undefined,
  cachedMonthlyIndices: undefined,
  setCachedMonthlyIndices: (i) => {},
  isLoading: false,
  error: undefined,
});

const IndicesProvider = ({ children, fieldId }) => {
  const { indices, isLoading, error } = useFetchAllIndicesMeta(fieldId);

  const [cachedMonthlyIndices, setCachedMonthlyIndices] = useState();

  const value = useMemo(
    () => ({
      indices,
      isLoading,
      error,
      cachedMonthlyIndices,
      setCachedMonthlyIndices,
    }),
    [indices, isLoading, error, cachedMonthlyIndices, setCachedMonthlyIndices]
  );

  return (
    <IndicesContext.Provider value={value}>{children}</IndicesContext.Provider>
  );
};

const useIndicesContext = () => {
  const context = useContext(IndicesContext);

  if (!context) {
    throw new Error("useIndicesContext must be used within a IndicesProvider");
  }
  return useContext(IndicesContext);
};

export { IndicesProvider, useIndicesContext };
