import Loader from "./Loader";

const LoaderOverlay = ({ zIndex = 1, size = 50, style }) => (
  <Loader
    size={size}
    style={{
      zIndex,
      position: "absolute",
      backgroundColor: "#000",
      opacity: 0.5,
      ...style,
    }}
  />
);

export default LoaderOverlay;
