export const camelToSnake = (word) => {
  // for composition objects with one letter keys
  if (word.length === 1) {
    return word;
  }
  return word.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const snakeToCamel = (word) =>
  word.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
