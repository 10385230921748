import { useTheme } from "@mui/material";

const MapToolPosition = ({ style, children }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: "absolute",
        zIndex: theme.zIndex.max,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default MapToolPosition;
