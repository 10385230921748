import { Tooltip, useTheme } from "@mui/material";
import infoIcon from "../../assets/icons/info.svg";
import INDICES from "../../constants/indices";
import { useTranslation } from "react-i18next";
import SmallText from "../Typography/SmallText";

const INDICES_GRADIENT = {
  [INDICES.ndvi]: {
    color:
      "linear-gradient(90deg, #ff0000 -2.38%, #edf8b1 51.69%, #008000 102.49%)",
    labelMin: "-1",
    labelMax: "1",
    info: "Agro.Indices.Description.Ndvi",
  },
  [INDICES.ndwi]: {
    color:
      "linear-gradient(90deg, #edf8b1 -2.38%, #0ba61a 51.69%, #0000ff 102.49%)",
    labelMin: "-1",
    labelMax: "1",
    info: "Agro.Indices.Description.Ndwi",
  },
  [INDICES.wdrvi]: {
    color:
      "linear-gradient(90deg, #ff0000 -2.38%, #edf8b1 51.69%, #008000 102.49%)",
    labelMin: "-1",
    labelMax: "1",
    info: "Agro.Indices.Description.Wdrvi",
  },
  [INDICES.evi]: {
    color: "linear-gradient(90deg, #8c510a, #01665e)",
    labelMin: "-1",
    labelMax: "1",
    info: "Agro.Indices.Description.Evi",
  },
  [INDICES.ccci]: {
    color:
      "linear-gradient(90deg, #ff0000 -2.38%, #edf8b1 51.69%, #008000 102.49%)",
    labelMin: "-1",
    labelMax: "1",
    info: "Agro.Indices.Description.Ccci",
  },
  [INDICES.lai]: {
    color: "linear-gradient(90deg, #ffffff -10%, #008000)",
    labelMin: "0",
    labelMax: "3+",
    info: "Agro.Indices.Description.Lai",
  },
  [INDICES.ndmi]: {
    color: "linear-gradient(90deg, #edf8ba, #008800, #0000ff)",
    labelMin: "-1",
    labelMax: "1",
    info: "Agro.Indices.Description.Ndmi",
  },
};

const IndexesBar = ({ indiceType }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const indice = INDICES_GRADIENT[indiceType];

  return (
    <div
      style={{
        display: "flex",
        width: "400px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "8px",
        padding: "8px",
        background: "rgba(35, 61, 77, 0.4)",
        borderRadius: "8px",
        backdropFilter: "blur(2px)",
      }}
    >
      <Tooltip title={t(indice?.info)} placement="bottom">
        <img src={infoIcon} alt="info" style={{ cursor: "pointer" }} />
      </Tooltip>
      <div style={{ flexDirection: "column", flexGrow: 1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SmallText
            style={{ color: theme.color.white100 }}
            text={indice?.labelMin}
          />
          <SmallText
            style={{ color: theme.color.white100 }}
            text={indice?.labelMax}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "10px",
            background: indice?.color,
            borderRadius: "4px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <SmallText
          style={{
            color: theme.color.white100,
            textShadow: "2px 2px 2px black",
          }}
          text={t("Agro.Indices.CloudCoveragePrct", { cloud: undefined })}
        />
        <div
          style={{
            width: "100%",
            height: "10px",
            background: "#d9d9d9",
            boxShadow: "inset 0px 2px 3px rgba(0, 0, 0, 0.25)",
            borderRadius: "4px",
          }}
        />
      </div>
    </div>
  );
};

export default IndexesBar;
