import Loader from "../Loader/Loader";
import MapPolygon from "../Maps/MapPolygon";
import StaticMap from "../Maps/StaticMap";
import "./map-image.css";

const MapImage = ({ style = {}, className, polygon }) => (
  <div style={style} className={`map-image ${className || ""}`}>
    {polygon ? (
      <StaticMap LatLngCoords={polygon}>
        <MapPolygon LatLngCoords={polygon} />
      </StaticMap>
    ) : (
      <Loader />
    )}
  </div>
);

export default MapImage;
