import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikProvider } from "formik";
import { useSelectedFieldContext } from "../../context/SelectedFieldProvider";
import { varietyFullName } from "../../utils/extractInfoFromResponses";
import Modal from "../Modals/Modal";
import AnalysisHeader from "./header/analysis-header/AnalysisHeader";
import ActionCause from "./cause/ActionCause";
import AnalysisBaseInfo from "./base-info/AnalysisBaseInfo";
import { useAnalysisFormikSetup } from "./utils/useFormikSetup";
import AnalysisDetails from "./details/analysis/AnalysisDetails";
import { ACTION_VIEW_MODE } from "../../constants/actionViewModes";

const actionModalBreakpoint = "@media (max-width:1000px)";

const AnalysisModal = ({ isOpen, onClose, type }) => {
  const isSmallScreen = useMediaQuery(actionModalBreakpoint);
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const { selectedField } = useSelectedFieldContext();

  const { formik, isLoading } = useAnalysisFormikSetup({
    selectedField,
    onClose,
    type,
  });

  const title =
    selectedField?.name +
    " / " +
    varietyFullName(isEn, selectedField, { splitter: " / " });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className={`action-modal action-modal-modal ${
        isSmallScreen ? "small-screen" : ""
      }`}
      onClose={handleClose}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className="action-modal-form">
          <AnalysisHeader isLoading={isLoading} />
          <div className="action-modal-form-inner">
            <div className="action-modal-card">
              <div className="action-modal-top-box">
                <AnalysisBaseInfo
                  // viewMode={action.type}
                  viewMode={ACTION_VIEW_MODE.new}
                />
              </div>
              <div className="action-modal-top-box">
                <ActionCause
                  // viewMode={action.type}
                  viewMode={ACTION_VIEW_MODE.new}
                />
              </div>
            </div>

            <div className="action-details-wrapper">
              <AnalysisDetails
                // viewMode={action.type}
                viewMode={ACTION_VIEW_MODE.new}
              />
            </div>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default AnalysisModal;
