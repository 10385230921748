import { Field, useField, useFormikContext } from "formik";
import TextFieldCustom from "../components/TextFieldCustom/TextFieldCustom";

const FormTextInput = ({
  name,
  onChange,
  shouldHaveErrorBehaviorAfterSubmit,
  helperText,
  ...props
}) => {
  const [, meta] = useField(name);
  const metaError = name ? meta.error : null;
  const { submitCount } = useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && metaError
    : metaError;

  const finalHelperText = (shouldDisplayError && metaError) || helperText;

  return (
    <Field
      name={name}
      as={TextFieldCustom}
      helperText={finalHelperText}
      isError={shouldDisplayError}
      type="number"
      {...(typeof onChange === "function" ? { onChange } : {})}
      {...props}
    />
  );
};

export default FormTextInput;
