import React, { useEffect, useRef } from "react";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Skeleton } from "@mui/material";

const StaticMap = ({ children, center, LatLngCoords, hasSkeleton }) => {
  const satelliteMap = "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && LatLngCoords) {
      const bounds = new L.latLngBounds(LatLngCoords);
      mapRef.current.fitBounds(bounds, { padding: [5, 5] });
    }
  }, [LatLngCoords]);

  return !hasSkeleton ? (
    <Map
      ref={mapRef}
      center={center}
      style={{
        height: "100%",
        width: "100%",
      }}
      scrollWheelZoom={false}
      zoomControl={false}
      zoomAnimation={false}
      attributionControl={false}
      fadeAnimation={false}
      touchZoom={false}
      doubleClickZoom={false}
      dragging={false}
    >
      <TileLayer
        url={satelliteMap}
        maxZoom={20}
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
        keepBuffer={10}
      />
      {children}
    </Map>
  ) : (
    <Skeleton
      sx={{ padding: "0px", margin: "0px", transform: "none" }}
      width="100%"
      height="100%"
    />
  );
};

export default StaticMap;
