import { createContext, useContext, useMemo, useState } from "react";

const AddFieldsContext = createContext({
  polygon: null,
  setPolygon: () => {},
  fieldFocused: null,
  setFieldFocused: () => {},
  deletedPolygon: null,
  setDeletedPolygon: () => {},
  editedPolygon: null,
  setEditedPolygon: () => {},
  deleteAllPolygons: false,
  setDeleteAllPolygons: () => {},
  addFieldOn: false,
  setAddFieldOn: () => {},
});

const AddFieldsProvider = ({ children }) => {
  const [polygon, setPolygon] = useState();
  const [kaekPolygon, setKaekPolygon] = useState();
  const [isDrawOnMapOn, setIsDrawOnMapOn] = useState(false);
  const [fieldFocused, setFieldFocused] = useState();
  const [deletedPolygon, setDeletedPolygon] = useState();
  const [editedPolygon, setEditedPolygon] = useState();
  const [deleteAllPolygons, setDeleteAllPolygons] = useState(false);
  const [addFieldOn, setAddFieldOn] = useState(false);

  let addFieldsValues = useMemo(
    () => ({
      addFieldOn,
      setAddFieldOn,
      polygon,
      setPolygon,
      kaekPolygon,
      setKaekPolygon,
      isDrawOnMapOn,
      setIsDrawOnMapOn,
      fieldFocused,
      setFieldFocused,
      deletedPolygon,
      setDeletedPolygon,
      editedPolygon,
      setEditedPolygon,
      deleteAllPolygons,
      setDeleteAllPolygons,
    }),
    [
      addFieldOn,
      setAddFieldOn,
      polygon,
      setPolygon,
      kaekPolygon,
      setKaekPolygon,
      isDrawOnMapOn,
      setIsDrawOnMapOn,
      fieldFocused,
      setFieldFocused,
      deletedPolygon,
      setDeletedPolygon,
      editedPolygon,
      setEditedPolygon,
      deleteAllPolygons,
      setDeleteAllPolygons,
    ]
  );

  return (
    <AddFieldsContext.Provider value={addFieldsValues}>
      {children}
    </AddFieldsContext.Provider>
  );
};

const useAddFieldsContext = () => {
  const context = useContext(AddFieldsContext);

  if (!context) {
    throw new Error(
      "useAddFieldsContext must be used within a AddFieldsProvider"
    );
  }
  return context;
};

export { AddFieldsProvider, useAddFieldsContext };
