import  { useState } from "react";import { useTranslation } from "react-i18next";
import TextFieldCustom from "../../components/TextFieldCustom/TextFieldCustom";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function PasswordField({
  onChange,
  label,
  isError,
  helperText,
  className,
  id,
  name,
  ...props
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextFieldCustom
      {...props}
      id={id}
      name={name}
      placeholder={"**********"}
      label={label}
      onChange={onChange}
      isError={isError}
      helperText={helperText}
      sx={{
        width: "300px",
        marginTop: "25px",
      }}
      type={showPassword ? "text" : "password"}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
