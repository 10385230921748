import { ClickAwayListener, MenuList, Paper, Popper } from "@mui/material";
import MenuDropdownItem from "./MenuDropdownItem";

const MenuDropdown = ({
  children,
  anchorEl,
  id,
  isOpen,
  onClose,
  onOpen,
  items = [],
  placement,
}) => {
  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      onClose();
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (isOpen) {
      onClose();
      return;
    }
    onOpen(event);
  };

  const handleItemClick = (onClick) => {
    onClick?.();
    onClose();
  };
  return (
    <>
      <span onClick={handleClick}>{children}</span>
      <Popper
        anchorEl={anchorEl}
        open={isOpen}
        role={undefined}
        placement={placement}
        disablePortal
        style={{
          zIndex: 1,
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={onClose}>
            <MenuList
              id={id || "positioned-menu"}
              onKeyDown={handleListKeyDown}
            >
              {items.map((item, index) => (
                <MenuDropdownItem
                  key={index}
                  {...item}
                  onClick={() => handleItemClick(item.onClick)}
                />
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default MenuDropdown;
