import { useState } from "react";
import GetCoordinates from "../GetCoordinates";
import Marker from "./Marker";

const BeingPlacedAnalysisMarker = ({ onMarkerPlaced }) => {
  const [position, setPosition] = useState([0, 0]);

  return (
    <>
      <GetCoordinates onMouseMove={setPosition} onClick={onMarkerPlaced} />
      <Marker position={position}></Marker>
    </>
  );
};

export default BeingPlacedAnalysisMarker;
