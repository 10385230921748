import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormTextInput } from "form";
import { NormalText } from "components";
import { actionFormValue, actionInputName } from "../../utils/inputName";
import DetailSection from "../DetailSection";

const ActionModalDescriptionSection = ({
  actionBaseName,
  isProposed,
  isViewMode,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const fieldName = actionInputName(actionBaseName, "description", isProposed);
  const value = actionFormValue(
    values,
    actionBaseName,
    "description",
    isProposed
  );
  const isViewingEmpty = isViewMode && !value;
  return (
    <DetailSection title={t("ActionModal.Description.Title")}>
      {isViewingEmpty ? (
        <NormalText style={{ marginLeft: "20px", fontStyle: "italic" }}>
          {t("ActionModal.Description.No")}
        </NormalText>
      ) : (
        <FormTextInput
          name={fieldName}
          placeholder={t("ActionModal.Description.Placeholder")}
          shouldHaveErrorBehaviorAfterSubmit
          isMultiline
          isViewMode={isViewMode}
        />
      )}
    </DetailSection>
  );
};

export default ActionModalDescriptionSection;
