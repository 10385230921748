import ButtonCustom from "../ButtonCustom/ButtonCustom";
import Loader from "../Loader/Loader";

const LoadingButton = ({
  isLoading = false,
  isDisabled = false,
  onClick,
  type,
  buttonType = "outlined",
  style,
  className,
  variant,
  children,
}) => (
  <ButtonCustom
    onClick={onClick}
    disabled={isDisabled}
    type={type}
    buttonType={buttonType}
    className={className}
    variant={variant}
    style={style}
  >
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
      {isLoading && (
        <Loader
          style={{
            marginLeft: "12px",
            zIndex: "3000",
          }}
          {...(buttonType !== "outlined" ? { color: "#fff" } : {})}
        />
      )}
    </span>
  </ButtonCustom>
);

export default LoadingButton;
