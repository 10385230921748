import INDICES from "../../constants/indices";
import SelectorNew from "../Selector/SelectorNew";

const INDICES_OPTIONS = [
  { label: INDICES.ndvi.toUpperCase(), value: INDICES.ndvi },
  { label: INDICES.ndwi.toUpperCase(), value: INDICES.ndwi },
  { label: INDICES.wdrvi.toUpperCase(), value: INDICES.wdrvi },
  { label: INDICES.evi.toUpperCase(), value: INDICES.evi },
  { label: INDICES.ccci.toUpperCase(), value: INDICES.ccci },
  { label: INDICES.lai.toUpperCase(), value: INDICES.lai },
  { label: INDICES.ndmi.toUpperCase(), value: INDICES.ndmi },
];

const SelectIndiceType = ({
  isIndicesLoading,
  isDisabled,
  indicesError,
  onSelect,
  value,
}) => {
  return (
    <SelectorNew
      isLoading={isIndicesLoading}
      isDisabled={isDisabled || isIndicesLoading || indicesError}
      options={INDICES_OPTIONS}
      value={value}
      style={{
        minWidth: isIndicesLoading ? "150px" : 0,
        backgroundColor: "#FFFFFFAA",
        "&:hover": {
          backgroundColor:
            !isDisabled && !isIndicesLoading && !indicesError
              ? "#FFFFFF"
              : "#FFFFFFAA",
        },
      }}
      onChange={(e) => {
        onSelect?.(e.target.value);
      }}
    />
  );
};

export default SelectIndiceType;
