import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSelectedFieldContext } from "./SelectedFieldProvider";
import { api } from "../api";

const AgrochemichalsContext = createContext({
  activeSubstances: {},
  isLoading: false,
  error: null,
});

const AgrochemichalsProvider = ({ children }) => {
  const { selectedField: field } = useSelectedFieldContext();
  const [activeSubstances, setActiveSubstances] = useState();
  const [fetchState, setFetchState] = useState({
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    const cropId = field?.varietyRead?.cropId;
    if (!cropId) {
      setFetchState(() => ({
        isLoading: false,
        error: "No cropId",
      }));
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        const { data } = await api.crops.activeSubstances(cropId, { signal });
        const substances = {};
        data.forEach((substance) => {
          const { agrochemicalRead } = substance;
          if (!agrochemicalRead || !agrochemicalRead.pesticideId) {
            return;
          }
          const {
            // name: { nameEn },
            pesticideId,
          } = agrochemicalRead;

          substances[pesticideId] = Array.isArray(substances[pesticideId])
            ? [...substances[pesticideId], substance]
            : [substance];
        });
        setActiveSubstances(substances);
        setFetchState((prev) => ({ isLoading: false }));
      } catch (err) {
        setFetchState(() => ({
          isLoading: false,
          error: err,
        }));
      }
    })();

    return () => {
      controller.abort();
    };
  }, [field]);

  const values = useMemo(
    () => ({
      activeSubstances,
      ...fetchState,
    }),
    [activeSubstances, fetchState]
  );

  return (
    <AgrochemichalsContext.Provider value={values}>
      {children}
    </AgrochemichalsContext.Provider>
  );
};

const useAgrochemicalsContext = () => {
  const context = useContext(AgrochemichalsContext);

  if (!context) {
    throw new Error(
      "useAgrochemicalsContext must be used within a AgrochemichalsProvider"
    );
  }
  return context;
};

export { AgrochemichalsProvider, useAgrochemicalsContext };
