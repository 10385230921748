import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import compare from "../../assets/icons/fieldPage/compareSlider.svg";

const CustomControl = ({ isReady, position, onClick }) => {
  const map = useMap();

  useEffect(() => {
    const container = L.DomUtil.create("img", "leaflet-bar leaflet-control");

    container.src = compare;
    container.style.backgroundColor = `rgba(255, 255, 255, 0.667)`;
    container.style.borderRadius = "12px";
    container.style.width = "36px";
    container.style.height = "36px";
    container.style.cursor = isReady ? "pointer" : "default";
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.justifyContent = "center";
    container.style.transition = "background-color 0.3s ease";
    container.addEventListener("mouseover", () => {
      if (isReady) {
        container.style.backgroundColor = "rgba(255, 255, 255, 0.9)"; // Change to desired hover background color
      }
    });

    container.addEventListener("mouseout", () => {
      container.style.backgroundColor = "rgba(255, 255, 255, 0.667)";
    });

    if (isReady) {
      container.addEventListener("click", onClick);
    }

    const customControl = L.control({ position });
    customControl.onAdd = () => container;

    customControl.addTo(map);

    return () => {
      // Clean up the control when unmounting
      customControl.remove();
    };
  }, [map, position, isReady]);

  return null;
};

export default CustomControl;
