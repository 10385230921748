import React, { useEffect } from "react";
import { Polygon, useMap } from "react-leaflet";
import L from "leaflet";

const MapPolygon = ({ LatLngCoords, isEditable, onChange }) => {
  const map = useMap();

  ///fits the polygon layer inside the static map
  useEffect(() => {
    if (map && LatLngCoords) map.fitBounds(LatLngCoords);
  }, [map, LatLngCoords]);

  useEffect(() => {
    if (map && LatLngCoords) {
      if (isEditable) {
        map.eachLayer((layer) => {
          if (layer.pm) {
            if (layer instanceof L.Polygon) {
              layer.pm.enable();

              layer.on("pm:edit", (e) => {
                const polygon = e;
                onChange?.(polygon.layer);
              });
            }
          }
        });
      } else {
        map.eachLayer((layer) => {
          if (layer.pm) {
            if (layer instanceof L.Polygon) {
              layer.pm.disable();
            }
          }
        });
      }
    }
  }, [map, LatLngCoords, isEditable]);

  return LatLngCoords ? (
    <Polygon
      positions={LatLngCoords}
      pathOptions={{
        color: "#ffe599",
        fillColor: "#4a437d",
        fillOpacity: 0.1,
      }}
    />
  ) : (
    <></>
  );
};
export default MapPolygon;
