import { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";
import moment from "moment";

const CustomDatePicker = styled(DatePicker)({
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
  },
  "& .MuiInputAdornment-root": {
    border: "solid red",
  },
  "& .MuiInputAdornment-root .MuiIconButton-root.Mui-disabled": {
    color: "unset",
  },
});

const DateField = ({
  label,
  name,
  placeholder,
  isDisabled,
  value,
  defaultValue,
  onChange,
  onMonthChange,
  isError,
  isLoading,
  helperText,
  inputFormat = "DD/MM/YYYY",
  className,
  isViewMode,
  minDate,
  maxDate,
  style,
  locale = "en",
  availableDates,
  inputComponent,
}) => {
  const [dateValue, setDateValue] = useState(defaultValue || value || null);

  const handleDateChange = (date) => {
    setDateValue(date);
    onChange?.(date);
  };

  useEffect(() => {
    if (!defaultValue) {
      setDateValue(value || null);
    }
  }, [value, defaultValue]);

  const disableDate = (day) => {
    if (availableDates) {
      return !availableDates.includes(moment(day).format("YYYY-MM-DD"));
    } else {
      return false;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <CustomDatePicker
        minDate={minDate}
        maxDate={maxDate}
        disableMaskedInput
        value={dateValue}
        shouldDisableDate={disableDate}
        onChange={handleDateChange}
        onMonthChange={onMonthChange}
        disabled={isDisabled}
        loading={isLoading}
        inputFormat={inputFormat}
        renderInput={(params) => {
          return inputComponent ? (
            inputComponent?.(params)
          ) : (
            <TextFieldCustom
              {...params}
              inputProps={{
                ...params?.inputProps,
                placeholder,
                readOnly: true,
              }}
              name={name}
              isViewMode={isViewMode}
              label={label}
              isError={isError}
              helperText={helperText}
              isDisabled={isDisabled}
              className={className}
              style={style}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DateField;
