import { deepTransformKeys } from "./transformObjectKeys";
import { camelToSnake, snakeToCamel } from "./camelSnakeTransformation";
import axiosInstance from "../api/utils/axiosInstance";
import { paths } from "./paths";
import { storage } from "./localStorage";
import { api } from "../api";

let failedRequests = [];
let isTokenRefreshing = false;

export const setupAxiosInterceptors = () => {
  axiosInstance.interceptors.response.use(
    (response) => {
      const { data } = response;

      const shouldApplyCamelCase = Boolean(data);

      if (shouldApplyCamelCase) {
        return { ...response, data: deepTransformKeys(data, snakeToCamel) };
      }
      return response;
    },

    async (error) => {
      const status = error.response?.status;
      const originalRequestConfig = error.config;

      const isRefreshResponseError =
        originalRequestConfig.url.endsWith("/refresh/") && status === 401;

      const isLoginError =
        originalRequestConfig.url.endsWith("/token/") && status === 401;

      if (status !== 401 || isRefreshResponseError || isLoginError) {
        return Promise.reject(error);
      }

      if (isTokenRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequests.push({
            resolve,
            reject,
            config: originalRequestConfig,
          });
        });
      }

      isTokenRefreshing = true;

      try {
        const response = await api.auth.refreshToken();
        const { access, refresh, roles } = response.data;

        if (!access || !refresh || !roles) {
          throw new Error("Invalid token response");
        }

        storage.setCredsInLocalStorage(access, refresh, roles);

        failedRequests.forEach(({ resolve, config }) => {
          axiosInstance.request(config).then(resolve).catch(Promise.reject);
        });

        failedRequests = [];
        isTokenRefreshing = false;

        return axiosInstance(originalRequestConfig);
      } catch (refreshError) {
        failedRequests.forEach(({ reject }) => reject(refreshError));

        // Investigate handling logout error
        // try {
        // await api.logoutUser();
        // } catch (err) {
        // console.error("Error while trying to logout: ", err);
        // }

        storage.removeCredsFromLocalStorage();
        window.location.href = paths.login;
        failedRequests = [];
        isTokenRefreshing = false;

        return Promise.reject(refreshError);
      }
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => {
      const { token } = storage.getCredsFromLocalStorage();
      if (token) {
        const bearer = `Bearer ${token}`;
        config.headers.Authorization = bearer;
      }

      if (config && config.method.toLowerCase() !== "get" && config.data) {
        return {
          ...config,
          data: deepTransformKeys(config.data, camelToSnake),
        };
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
