import React, { useEffect, useRef } from "react";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const MapContainer = ({ children, center, zoom }) => {
  const hybridMap =
    "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}?lang=en";
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && mapRef.current.leafletElement) {
      const map = mapRef.current.leafletElement;
      map.invalidateSize();
    }
  }, []);
  var southWest = [-95, -185];
  var northEast = [95, 185];
  var bounds = [southWest, northEast];

  return (
    <Map
      ref={mapRef}
      center={center}
      zoom={zoom}
      minZoom={4}
      maxBounds={bounds}
      zoomControl={false}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <TileLayer
        url={hybridMap}
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
        keepBuffer={10}
        edgeBufferTiles={2}
      />
      {children}
    </Map>
  );
};

export default MapContainer;
