import { Button } from "@mui/material";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";

const ShowMore = ({ isShown, isFetching, onShowMore, showMoreText }) => {
  const { t } = useTranslation();
  return (
    isShown && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {isFetching ? (
          <Loader style={{ marginTop: "16px" }} />
        ) : (
          <Button
            buttonType="text"
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              marginTop: "12px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={onShowMore}
          >
            {showMoreText || t("ShowMore.Base")}
          </Button>
        )}
      </div>
    )
  );
};

export default ShowMore;
