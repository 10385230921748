import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import Loader from "../Loader/Loader";
import SubTitle from "../Typography/SubTitle";
import NormalText from "../Typography/NormalText";
import NoContentFound from "../NoContentFound/NoContentFound";
import Avatar from "./Avatar";
import "./UserCard.css";

const UserCard = ({ isLoading, user, title }) => {
  const { t } = useTranslation();
  const finalTitle = title || t("UserCard.Title");
  return (
    <Card style={{ minHeight: "250px", boxSizing: "border-box" }}>
      {isLoading ? (
        <Loader />
      ) : user ? (
        <>
          <Card.Section>
            <Card.SectionTitle title={finalTitle} />
            <Card.SectionContent>
              <div className="user-info-card-wrapper">
                <Avatar firstName={user.firstName} lastName={user.lastName} />
                <div className="user-info-card-name">
                  <SubTitle
                    text={`${user.firstName} ${user.lastName}`}
                    style={{ color: "#1B1D21" }}
                  />
                  <div className="user-info-card-contact">
                    <div className="user-info-card-contact-column">
                      <NormalText
                        text={t("UserCard.ContactPhone")}
                        style={{ color: "#4D4D4D" }}
                      />
                      <NormalText
                        text={user.phone}
                        style={{ color: "#1B1D21" }}
                      />
                    </div>
                    <div className="user-info-card-contact-column">
                      <NormalText
                        text={t("UserCard.Email")}
                        style={{ color: "#4D4D4D" }}
                      />
                      <NormalText
                        text={user.email}
                        style={{ color: "#1B1D21" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card.SectionContent>
          </Card.Section>
          <Card.Section>
            <Card.SectionTitle title={t("UserCard.ChargePlanInfo")} />
            <Card.SectionContent>
              {user.plan || t("UserCard.Free")}
            </Card.SectionContent>
          </Card.Section>
        </>
      ) : (
        <NoContentFound />
      )}
    </Card>
  );
};

export default UserCard;
