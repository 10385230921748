import icon01d from "../assets/icons/weather/01d.svg";
import icon01n from "../assets/icons/weather/01n.svg";
import icon02d from "../assets/icons/weather/02d.svg";
import icon02d1 from "../assets/icons/weather/02d-1.svg";
import icon02n from "../assets/icons/weather/02n.svg";
import icon03n from "../assets/icons/weather/03n.svg";
import icon04d from "../assets/icons/weather/04d.svg";
import icon04n from "../assets/icons/weather/04n.svg";
import icon09d from "../assets/icons/weather/09d.svg";
import icon09n from "../assets/icons/weather/09n.svg";
import icon10d from "../assets/icons/weather/10d.svg";
import icon10n from "../assets/icons/weather/10n.svg";
import icon11d from "../assets/icons/weather/11d.svg";
import icon11n from "../assets/icons/weather/11n.svg";
import icon13d from "../assets/icons/weather/13d.svg";
import icon13n from "../assets/icons/weather/13n.svg";
import icon50d from "../assets/icons/weather/50d.svg";
import icon50n from "../assets/icons/weather/50n.svg";
import unkownWeatherIcon from "../assets/images/no-weather-available.webp";

export const iconMap = {
  "01d": icon01d,
  "01n": icon01n,
  "02d": icon02d,
  "02d1": icon02d1,
  "02n": icon02n,
  "03n": icon03n,
  "04d": icon04d,
  "04n": icon04n,
  "09d": icon09d,
  "09n": icon09n,
  "10d": icon10d,
  "10n": icon10n,
  "11d": icon11d,
  "11n": icon11n,
  "13d": icon13d,
  "13n": icon13n,
  "50d": icon50d,
  "50n": icon50n,
};

export const getIcon = (iconCode) => {
  return iconMap[iconCode] || unkownWeatherIcon;
};

export const getIronCode = (hourlyMainIcon, sunset, sunrise, time) => {
  if (hourlyMainIcon) {
    const iconParts = hourlyMainIcon?.split("/");
    const iconName = iconParts?.[iconParts?.length - 1];
    let iconCode = iconName?.split(".")[0];

    if (
      time &&
      iconCode != "no-weather-available" &&
      isNight(sunset, sunrise, time)
    ) {
      iconCode = iconCode.replaceAll("d", "n");
    }

    return iconCode;
  }
};

const isNight = (sunset, sunrise, time) => {
  const inputTime = new Date(time * 1000);
  const sunsetTime = new Date(sunset * 1000);
  const sunriseTime = new Date(sunrise * 1000);
  return inputTime > sunsetTime || inputTime < sunriseTime;
};
