import { useFormikContext } from "formik";
import { ACTION_STATUS } from "../../../../constants/actionStatus";
import { useSelectedFieldContext } from "../../../../context/SelectedFieldProvider";
import DiagnoseSuggestion from "./DiagnoseSuggestion";
import DiagnosePerformed from "./DiagnosePerformed";

const DiagnoseDetails = ({ viewMode }) => {
  const { selectedField } = useSelectedFieldContext();
  const { values } = useFormikContext();

  const isSuggested = values?.status !== ACTION_STATUS.performed;

  return isSuggested ? (
    <DiagnoseSuggestion viewMode={viewMode} />
  ) : (
    <DiagnosePerformed field={selectedField} viewMode={viewMode} />
  );
};

export default DiagnoseDetails;
