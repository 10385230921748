import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import nestedUtils from "utils/nestedUtils";
import filterByTerm from "utils/filterByTerm";
import { useFieldsContext } from "./FieldsProvider";

const filterField = (field, filters) => {
  if (filters.crops?.length) {
    const cropId = field.varietyRead.cropId;
    if (!filters.crops.includes(cropId)) {
      return false;
    }
  }
  if (filters.regions?.length) {
    const regionId = field.municipality.regionId.toString();
    if (!filters.regions.includes(regionId)) {
      return false;
    }
  }
  if (filters.prefectures?.length) {
    const prefectureId = field.municipality.prefectureId.toString();
    if (!filters.prefectures.includes(prefectureId)) {
      return false;
    }
  }
  return filterByTerm.field(field, filters.searchTerm);
};

const transformLocationsLabels = (locations) => {
  const transformItem = (item) => {
    const { value, parentIdPath, label, children } = item || {};
    let newLabel = label;
    if (parentIdPath?.length === 2) {
      newLabel = label.split(",")[0];
    }
    const transformedItem = {
      ...item,
      value: value.toString(),
      label: newLabel,
    };
    if (children && children?.length > 0) {
      transformedItem.children = children.map(transformItem);
    }
    return transformedItem;
  };

  return locations.map(transformItem);
};

const FieldsFiltersContext = createContext({
  filters: {
    regions: [],
    prefectures: [],
    crops: [],
    searchTerm: "",
  },
  treeData: {
    locations: [],
    crops: [],
  },
  cropOptions: [],
  regionOptions: [],
  prefectureOptions: [],
  isReady: false,
  clear: () => {},
  /**
   *
   * @param {Object} data
   * @param {'regions' | 'prefectures' | 'crops' | 'searchTerm'} data.type
   * @param {string | Array<{value: string, label: string}>} data.newFilters
   * @param {Object} data.data
   */
  update: ({ type, newFilters, data }) => {},
  getFilteredFields: ({ fields, searchTerm }) => [],
});

const FieldsFiltersProvider = ({ children }) => {
  const navigate = useNavigate();
  const route = useLocation();
  const [isReady, setIsReady] = useState(false);

  const { myCrops, myLocations } = useFieldsContext();

  const treeData = useMemo(() => {
    if (myCrops.length && myLocations.length) {
      return {
        locations: transformLocationsLabels(myLocations),
        crops: myCrops,
      };
    }
  }, [myLocations, myCrops]);
  const isTreeDataReady = useMemo(() => !!treeData, [treeData]);

  const [filters, setFilters] = useState({
    regions: [],
    prefectures: [],
    crops: [],
    searchTerm: "",
  });

  const cropOptions = useMemo(() => myCrops, [myCrops]);

  const regionOptions = useMemo(() => {
    const allRegions = nestedUtils.getAll({
      data: treeData?.locations || [],
      level: 0,
    });
    return allRegions.map((region) => ({
      value: region.value,
      label: region.label,
    }));
  }, [treeData?.locations]);

  const prefectureOptions = useMemo(() => {
    let prefectures = [];
    if (filters.regions?.length) {
      prefectures = nestedUtils.getChildrenOfMultiple({
        data: treeData?.locations,
        ids: filters.regions,
        level: 0,
      });
    } else {
      prefectures = nestedUtils.getAll({
        data: treeData?.locations || [],
        level: 1,
      });
    }
    return prefectures.map((pref) => ({
      value: pref.value,
      label: pref.label,
    }));
  }, [treeData?.locations, filters?.regions]);

  const updateParams = useCallback(
    (data) => {
      const { regions, prefectures, crops, searchTerm } = data;
      const regionsString = regions?.join(",");
      const prefecturesString = prefectures?.join(",");
      const cropsString = crops?.join(",");
      const finalString = `${searchTerm ? `searchTerm=${searchTerm}&` : ""}${regionsString ? `regions=${regionsString}&` : ""}${prefecturesString ? `prefectures=${prefecturesString}&` : ""}${cropsString ? `crops=${cropsString}&` : ""}`;
      navigate({
        search: `${finalString ? `?${finalString.slice(0, -1)}` : ""}`,
      });
    },
    [navigate]
  );

  const update = useCallback(
    ({ type, newFilters, data }) => {
      setIsReady(false);
      if (data) {
        setFilters(data);
        updateParams(data);
      } else {
        if (type === "regions") {
          const prefectures = filters.prefectures.filter((prefId) =>
            prefectureOptions.map((pref) => pref.label).includes(prefId)
          );
          setFilters((prev) => ({
            ...prev,
            regions: newFilters,
            prefectures,
          }));
          updateParams({ ...filters, regions: newFilters, prefectures });
        } else {
          setFilters((prev) => {
            return {
              ...prev,
              [type]: newFilters,
            };
          });
          updateParams({ ...filters, [type]: newFilters });
        }
      }
      setIsReady(true);
    },
    [prefectureOptions, updateParams, filters]
  );

  const clear = useCallback(() => {
    update({
      data: { regions: [], prefectures: [], crops: [], searchTerm: "" },
    });
  }, [update]);

  const getFilteredFields = useCallback(
    ({ fields }) => {
      return fields.filter((field) => filterField(field, filters));
    },
    [filters]
  );

  const isInfoExtractedFromUrl = useRef();
  useEffect(() => {
    if (isInfoExtractedFromUrl.current) return;
    if (!isTreeDataReady) return;

    const params = new URLSearchParams(route.search);
    const regions = params.get("regions");
    const prefectures = params.get("prefectures");
    const crops = params.get("crops");
    const searchTerm = params.get("searchTerm");

    setFilters({
      regions: regions ? regions.split(",") : [],
      prefectures: prefectures ? prefectures.split(",") : [],
      crops: crops ? crops.split(",") : [],
      searchTerm: searchTerm || "",
    });

    // if (locations || crops) {
    //   const updatedFilters = { locations: [], crops: [] };
    //   if (locations) {
    //     const locationIds = locations.split(",");
    //     const locationNodes = locationIds.map((id) => {
    //       const locationNode = findNode(treeData.locations, id);
    //       return locationNode
    //         ? { value: locationNode.value, label: locationNode.label }
    //         : null;
    //     });
    //     updatedFilters.locations = locationNodes.filter(Boolean);
    //   }
    //   if (crops) {
    //     const cropIds = crops.split(",");
    //     const cropNodes = cropIds.map((id) => {
    //       const cropNode = findNode(treeData.crops, id);
    //       return cropNode
    //         ? { value: cropNode.value, label: cropNode.label }
    //         : null;
    //     });
    //     updatedFilters.crops = cropNodes.filter(Boolean);
    //   }
    //   setFilters(updatedFilters);
    // }

    setIsReady(true);
    isInfoExtractedFromUrl.current = true;
  }, [isTreeDataReady, route.search, treeData?.crops, treeData?.locations]);

  const value = useMemo(
    () => ({
      filters,
      treeData,
      isReady,
      cropOptions,
      regionOptions,
      prefectureOptions,
      clear,
      update,
      getFilteredFields,
    }),
    [
      filters,
      treeData,
      isReady,
      cropOptions,
      regionOptions,
      prefectureOptions,
      clear,
      update,
      getFilteredFields,
    ]
  );

  return (
    <FieldsFiltersContext.Provider value={value}>
      {children}
    </FieldsFiltersContext.Provider>
  );
};

const useFieldsFiltersContext = () => {
  const context = useContext(FieldsFiltersContext);

  if (!context) {
    throw new Error(
      "useFieldsFiltersContext must be used within a FieldsFiltersProvider"
    );
  }
  return useContext(FieldsFiltersContext);
};

export { useFieldsFiltersContext, FieldsFiltersProvider };
