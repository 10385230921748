// import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { toaster } from "../../../utils/toaster";
import { useActionChoicesContext } from "../../../context/GeneralAgroProvider";
import { ACTION } from "../../../constants/actions";
import { ACTION_STATUS } from "constants/actionStatus";

const useActionToasts = () => {
  const { t } = useTranslation();
  const { getActionById } = useActionChoicesContext();

  /**
   * Displays a toast message based on the provided parameters.
   * @param {Object} options - The options for the toast message.
   * @param {('error' | 'success' | 'info')} options.toastType - The type of the toast message (error, success, or info).
   * @param {('create' | 'update' | 'delete' | 'complete' | 'uncomplete')} options.actionTaken - The action taken (create, update, delete, or complete).
   * @param {Object} options.values - Values of the action
   * @param
   */
  const showActionToast = ({ toastType, actionTaken, values }) => {
    let title = "";
    let toastFn;
    switch (toastType) {
      case "error":
        toastFn = toaster.error;
        title = "ActionModal.Error.";
        break;
      case "success":
        toastFn = toaster.success;
        title = "ActionModal.Success.";
        break;
      default:
        toastFn = toaster.info;
        break;
    }

    switch (actionTaken) {
      case "create":
        title += "Creating";
        break;
      case "update":
        title += "Updating";
        break;
      case "delete":
        title += "Deleting";
        break;
      case "complete":
        title += "Completing";
        break;
      case "uncomplete":
        title += "MarkingIncomplete";
        break;
      default:
        break;
    }

    let actionType = values.status;
    if (values.initialStatus && values.initialStatus !== values.status) {
      if (values.initialStatus === ACTION_STATUS.performed) {
        actionType = "suggestCompleted";
      } else {
        actionType = "completedSuggested";
      }
    }

    let message = "";
    switch (actionType) {
      case "suggested":
        message += "ActionModal.Toast.Suggested.";
        break;
      case "performed":
        message += "ActionModal.Toast.Performed.";
        break;
      case "failed":
        message += "ActionModal.Toast.Failed.";
        break;
      case "completedSuggested":
        message += "ActionModal.Toast.CompletedSuggested.";
        break;
      case "suggestCompleted":
        message += "ActionModal.Toast.SuggestCompleted.";
        break;
      default:
        break;
    }

    const actionKind = getActionById(values.taskId);

    switch (actionKind) {
      case ACTION.fertilize:
        message += "FertilizeAction";
        break;
      case ACTION.watering:
        message += "WaterAction";
        break;
      case ACTION.drug:
        message += "DrugAction";
        break;
      case ACTION.diagnose:
        message += "DiagnoseAction";
        break;
      case ACTION.other:
        message += "OtherAction";
        break;
      default:
        break;
    }

    const actionId = values.id;
    title = t(title);
    message = t(message, { id: actionId ? `ID-${actionId}` : "" });

    const toastUniqueId = `action-toast-unique-id`;
    toastFn({
      message,
      title,
      position: "bottom-left",
      id: toastUniqueId,
      shouldAutoClose: 5000,
    });
  };

  return { showActionToast };
};

export default useActionToasts;
