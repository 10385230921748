import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "api";
import { useCropStressesContext } from "context/CropStressesProvider";
import { useActionsContext, DISPATCH_TYPES } from "context/ActionsProvider";
import { ACTION_STATUS } from "constants/actionStatus";
import { useActionModalGetContext } from "context/ActionModalProvider";
import useActionToasts from "./useActionToasts";

export const useTransformAction = () => {
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const { cropStresses } = useCropStressesContext();

  const transformAction = useCallback(
    (action) => {
      const { stressId } = action.caused || {};

      const stress = cropStresses.find((stress) => stress.uuid === stressId);
      const stressType = isEn
        ? stress?.stressTypeName.nameEn
        : stress?.stressTypeName.nameGr || "";

      return action
        ? {
            ...action,
            initialStatus: action.status,
            stressType,
          }
        : {};
    },
    [cropStresses, isEn]
  );

  return { transformAction };
};

const handleSuccess = (onSuccess, setIsLoading, setSelectedAction) => {
  setTimeout(() => {
    if (typeof onSuccess === "function") {
      onSuccess();
    }
    setIsLoading(false);
    if (typeof setSelectedAction === "function") {
      setSelectedAction();
    }
  }, 200);
};

const handleError = (onError, error, setIsLoading) => {
  setTimeout(() => {
    if (typeof onError === "function") {
      onError(error);
    }
    setIsLoading(false);
  }, 200);
};

export const useActionCalls = (fieldId) => {
  const [isLoading, setIsLoading] = useState(false);

  const { showActionToast } = useActionToasts();
  const { dispatchActions, setSelectedAction } = useActionsContext();
  const { action } = useActionModalGetContext();
  const actionId = action?.action?.id;

  const postAction = useCallback(
    ({ status, values, onSuccess, onError }) => {
      setIsLoading(true);
      const valuesForToast = { ...values, status };
      if (status === ACTION_STATUS.suggested) {
        api.actions
          .postSuggested(values)
          .then(({ data }) => {
            dispatchActions({
              type: DISPATCH_TYPES.set,
              action: { ...data, status: ACTION_STATUS.suggested },
            });
            showActionToast({
              toastType: "success",
              actionTaken: "create",
              values: valuesForToast,
            });
            handleSuccess(onSuccess, setIsLoading, setSelectedAction);
          })
          .catch((err) => {
            showActionToast({
              toastType: "error",
              actionTaken: "create",
              values: valuesForToast,
            });
            handleError(onError, err, setIsLoading);
          });
      } else if (status === ACTION_STATUS.performed) {
        api.actions
          .postPerformed(values)
          .then(({ data }) => {
            dispatchActions({
              type: DISPATCH_TYPES.set,
              action: { ...data, status: ACTION_STATUS.performed },
            });
            showActionToast({
              toastType: "success",
              actionTaken: "create",
              values: valuesForToast,
            });
            handleSuccess(onSuccess, setIsLoading, setSelectedAction);
          })
          .catch((err) => {
            showActionToast({
              toastType: "error",
              actionTaken: "create",
              values: valuesForToast,
            });
            handleError(onError, err, setIsLoading);
          });
      }
    },
    [dispatchActions, setSelectedAction, showActionToast]
  );

  const patchAction = useCallback(
    ({ status, initialStatus, values, onSuccess, onError }) => {
      setIsLoading(true);

      const valuesForToast = { ...values, status, initialStatus };
      if (initialStatus !== ACTION_STATUS.performed) {
        if (status === ACTION_STATUS.performed) {
          api.actions
            .transferToPerformed(fieldId, actionId)
            .then(() => {
              const valuesWithNewStatus = {
                ...values,
                status: ACTION_STATUS.performed,
              };
              api.actions
                .updatePerformed(fieldId, actionId, valuesWithNewStatus)
                .then(({ data: newPerformedAction }) => {
                  dispatchActions({
                    type: DISPATCH_TYPES.transfer,
                    action: values,
                    transferTo: {
                      type: ACTION_STATUS.performed,
                      action: newPerformedAction,
                    },
                  });
                  showActionToast({
                    toastType: "success",
                    actionTaken: "complete",
                    values: valuesForToast,
                  });
                  setSelectedAction((prev) => ({
                    ...prev,
                    ...newPerformedAction,
                    status: ACTION_STATUS.performed,
                  }));
                  handleSuccess(onSuccess, setIsLoading);
                })
                .catch((err) => {
                  showActionToast({
                    toastType: "error",
                    actionTaken: "complete",
                    values: valuesForToast,
                  });
                  handleError(onError, err, setIsLoading);
                });
            })
            .catch((err) => {
              showActionToast({
                toastType: "error",
                actionTaken: "complete",
                values: valuesForToast,
              });
              handleError(onError, err, setIsLoading);
            });
        } else {
          api.actions
            .updateSuggested(fieldId, actionId, values)
            .then(({ data }) => {
              dispatchActions({
                type: DISPATCH_TYPES.edit,
                action: { ...data, status: ACTION_STATUS.suggested },
              });
              setSelectedAction((prev) => ({ ...prev, ...data }));
              showActionToast({
                toastType: "success",
                actionTaken: "update",
                values: valuesForToast,
              });
              handleSuccess(onSuccess, setIsLoading);
            })
            .catch((err) => {
              showActionToast({
                toastType: "error",
                actionTaken: "update",
                values: valuesForToast,
              });
              handleError(onError, err, setIsLoading);
            });
        }
      } else {
        if (status === ACTION_STATUS.suggested) {
          api.actions
            .transferToSuggested(fieldId, actionId)
            .then(({ data }) => {
              const valuesWithNewStatus = {
                ...data,
                createdByUserFullName: values.createdByUserFullName,
                taskName: values.taskName,
                actionTypeName: values.actionTypeName,
                status: ACTION_STATUS.suggested,
                waterAction: Boolean(values.waterAction)
                  ? values.proposedAction
                  : null,
                drugAction: Boolean(values.drugAction)
                  ? values.proposedAction
                  : null,
                diagnoseAction: Boolean(values.diagnoseAction)
                  ? values.proposedAction
                  : null,
                fertilizeAction: Boolean(values.fertilizeAction)
                  ? values.proposedAction
                  : null,
                otherAction: Boolean(values.otherAction)
                  ? values.proposedAction
                  : null,
              };

              api.actions
                .updateSuggested(fieldId, actionId, valuesWithNewStatus)
                .then(({ data: newSuggestedAction }) => {
                  dispatchActions({
                    type: DISPATCH_TYPES.transfer,
                    action: newSuggestedAction,
                    transferTo: {
                      type: ACTION_STATUS.suggested,
                      action: newSuggestedAction,
                    },
                  });
                  showActionToast({
                    toastType: "success",
                    actionTaken: "uncomplete",
                    values: valuesForToast,
                  });
                  setSelectedAction(newSuggestedAction);
                  handleSuccess(onSuccess, setIsLoading);
                })
                .catch((err) => {
                  showActionToast({
                    toastType: "error",
                    actionTaken: "uncomplete",
                    values: valuesForToast,
                  });
                  handleError(onError, err, setIsLoading);
                });
            })
            .catch((err) => {
              showActionToast({
                toastType: "error",
                actionTaken: "uncomplete",
                values: valuesForToast,
              });
              handleError(onError, err, setIsLoading);
            });
        } else {
          api.actions
            .updatePerformed(fieldId, actionId, values)
            .then(({ data }) => {
              dispatchActions({
                type: DISPATCH_TYPES.edit,
                action: { ...data, status: ACTION_STATUS.performed },
              });
              setSelectedAction((prev) => ({ ...prev, ...data }));
              showActionToast({
                toastType: "success",
                actionTaken: "update",
                values: valuesForToast,
              });
              handleSuccess(onSuccess, setIsLoading);
            })
            .catch((err) => {
              showActionToast({
                toastType: "error",
                actionTaken: "update",
                values: valuesForToast,
              });
              handleError(onError, err, setIsLoading);
            });
        }
      }
    },
    [actionId, dispatchActions, fieldId, setSelectedAction, showActionToast]
  );

  const deleteAction = useCallback(
    ({ status, values, onSuccess, onError }) => {
      setIsLoading(true);
      const valuesForToast = { ...values, status };
      if (status === ACTION_STATUS.performed) {
        api.actions
          .deletePerformed(fieldId, actionId)
          .then((res) => {
            dispatchActions({
              type: DISPATCH_TYPES.delete,
              action: { ...values, status: ACTION_STATUS.performed },
            });
            showActionToast({
              toastType: "success",
              actionTaken: "delete",
              values: valuesForToast,
            });
            handleSuccess(onSuccess, setIsLoading, setSelectedAction);
          })
          .catch((err) => {
            showActionToast({
              toastType: "error",
              actionTaken: "delete",
              values: valuesForToast,
            });
            handleError(onError, err, setIsLoading);
          });
      } else {
        api.actions
          .deleteSuggested(fieldId, actionId)
          .then(() => {
            dispatchActions({
              type: DISPATCH_TYPES.delete,
              action: { ...values, status: ACTION_STATUS.suggested },
            });
            showActionToast({
              toastType: "success",
              actionTaken: "delete",
              values: valuesForToast,
            });
            handleSuccess(onSuccess, setIsLoading, setSelectedAction);
          })
          .catch((err) => {
            showActionToast({
              toastType: "error",
              actionTaken: "delete",
              values: valuesForToast,
            });
            handleError(onError, err, setIsLoading);
          });
      }
    },
    [actionId, dispatchActions, fieldId, setSelectedAction, showActionToast]
  );

  return { postAction, patchAction, deleteAction, isLoading };
};
