import { useMemo, useRef } from "react";
import L from "leaflet";
import { Marker as LeafletMarker } from "react-leaflet";

// import markerSelected from "../../../assets/icons/map/markerSelected.svg";
import markerIcon from "../../../assets/icons/map/markerNotSelected.svg";

const markerLeafletIcon = new L.Icon({
  iconUrl: markerIcon,
  // marker icon size is w: 24, h: 29
  iconAnchor: [12, 28],
});

const Marker = ({ isDraggable, position, setPosition, opacity, children }) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition?.(marker.getLatLng());
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <LeafletMarker
      ref={markerRef}
      icon={markerLeafletIcon}
      eventHandlers={eventHandlers}
      draggable={isDraggable}
      position={position}
      opacity={opacity}
      riseOnHover
    >
      {children}
    </LeafletMarker>
  );
};

export default Marker;
