const filterObjectByKeys = (obj, keys) => {
  const r = Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce((newObj, key) => ({ ...newObj, [`${key}`]: obj[key] }), {});
  return r;
};
export default filterObjectByKeys;

export const filterObject = (obj, filterFunc) => {
  if (typeof filterFunc !== "function") {
    throw new Error("filterFunc must be a function");
  }

  const filteredObj = {};
  for (const key in obj) {
    if (filterFunc(obj[key])) {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
};

export const setFromArrayOfObjects = (objects, key) => {
  if (!Array.isArray(objects)) {
    return [];
  }

  const uniqueCompositions = {};

  const resultArray = objects.filter((obj) => {
    if (!uniqueCompositions[obj[key]]) {
      uniqueCompositions[obj[key]] = true;
      return true;
    }
    return false;
  });

  return resultArray;
};
