import { useTranslation } from "react-i18next";
import { useDropdownMenu } from "../../../hooks/useDropdownMenu";
import { paths } from "../../../utils/paths";
import { useInboxContext } from "../../../context/InboxProvider";
import { useSidebarsContext } from "../../../context/SidebarsProvider";

export const useInboxDropdown = () => {
  const { t } = useTranslation();
  const { isOpen, anchorEl, open, close } = useDropdownMenu();

  const { readAll } = useInboxContext();
  const { toggleSidebar, selected } = useSidebarsContext();

  const handleReadAll = () => {
    readAll();
  };

  const items = [
    {
      label: t("Inbox.Dropdown.Read", { count: 5 }),
      onClick: handleReadAll,
      iconClass: "icon-read-all",
    },
    {
      label: t("Inbox.Dropdown.NotificationSettings"),
      href: paths.settings.notifications,
      iconClass: "icon-settings-tune",
      onClick: () => toggleSidebar(selected),
    },
  ];

  return { items, isOpen, anchorEl, open, close };
};
