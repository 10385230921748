import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { api } from "api";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FETCH_STATE } from "constants/fetchState";
import { useCropStressesContext } from "./CropStressesProvider";

const ActionModalGetContext = createContext({
  action: {
    type: ACTION_VIEW_MODE.new,
    action: undefined,
    actionId: "",
    status: "",
    initialStatus: "",
    shouldHideStatusToggle: false,
  },
  isOpen: false,
  fetchState: FETCH_STATE.IDLE,
  errorMessage: "",
});

const ActionModalSetContext = createContext({
  setAction: () => {},
  setIsOpen: () => {},
  openActionModal: ({
    type,
    action,
    actionId,
    status,
    initialStatus,
    shouldHideStatusToggle,
  }) => {},
  closeActionModal: () => {},
});

const ActionModalProvider = ({ children }) => {
  const { t } = useTranslation();
  const [action, setAction] = useState({
    type: ACTION_VIEW_MODE.new,
    shouldHideStatusToggle: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [errorMessage, setErrorMessage] = useState(t("Global.Error"));

  const { fetchState: cropsFetchState } = useCropStressesContext();

  useEffect(() => {
    if (fetchState === FETCH_STATE.ERROR) {
      return;
    }
    if (
      cropsFetchState === FETCH_STATE.SUCCESS ||
      cropsFetchState === FETCH_STATE.ERROR
    ) {
      setFetchState(cropsFetchState);
    }
  }, [cropsFetchState, fetchState]);

  const openActionModal = useCallback(
    async ({ actionId, fieldId, ...rest }) => {
      setFetchState(FETCH_STATE.LOADING);
      if (actionId) {
        try {
          const res = await api.actions.fetchOne({
            actionId,
            fieldId,
          });

          setAction({
            ...rest,
            type: ACTION_VIEW_MODE.view,
            status: res.data.status,
            initialStatus: res.data.status,
            action: res.data,
          });
          setIsOpen(true);
          if (cropsFetchState === FETCH_STATE.SUCCESS) {
            setFetchState(FETCH_STATE.SUCCESS);
          } else if (cropsFetchState === FETCH_STATE.ERROR) {
            setFetchState(FETCH_STATE.ERROR);
          }
        } catch (err) {
          if (err.response?.status === "404") {
            setErrorMessage(t("ActionModal.Error.NotFound"));
          }
          setIsOpen(true);
          setFetchState(FETCH_STATE.ERROR);
        }
        return;
      }
      setAction({
        ...rest,
      });
      setIsOpen(true);
      if (cropsFetchState === FETCH_STATE.SUCCESS) {
        setFetchState(FETCH_STATE.SUCCESS);
      } else if (cropsFetchState === FETCH_STATE.ERROR) {
        setFetchState(FETCH_STATE.ERROR);
      }
    },
    [cropsFetchState, t]
  );

  const closeActionModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const getValues = useMemo(
    () => ({
      action,
      isOpen,
      fetchState,
      errorMessage,
    }),
    [action, isOpen, fetchState, errorMessage]
  );

  const setValues = useMemo(
    () => ({
      setAction,
      setIsOpen,
      openActionModal,
      closeActionModal,
    }),
    [setAction, setIsOpen, openActionModal, closeActionModal]
  );

  return (
    <ActionModalSetContext.Provider value={setValues}>
      <ActionModalGetContext.Provider value={getValues}>
        {children}
      </ActionModalGetContext.Provider>
    </ActionModalSetContext.Provider>
  );
};

const useActionModalGetContext = () => {
  const context = useContext(ActionModalGetContext);

  if (!context) {
    throw new Error(
      "useActionModalGetContext must be used within a ActionModalProvider"
    );
  }
  return context;
};

const useActionModalSetContext = () => {
  const context = useContext(ActionModalSetContext);

  if (!context) {
    throw new Error(
      "useActionModalSetContext must be used within a ActionModalProvider"
    );
  }
  return context;
};

export {
  ActionModalProvider,
  useActionModalGetContext,
  useActionModalSetContext,
};
