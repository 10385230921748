import axiosInstance from "../api/utils/axiosInstance";

const saveLanguagePreference = (language) => {
  localStorage.setItem("language", language);
};

const getLanguagePreference = () => {
  return localStorage.getItem("i18nextLng");
};

const removeLanguagePreference = () => {
  localStorage.removeItem("i18nextLng");
};

const setCredsInLocalStorage = (token, refresh, roles) => {
  axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem("access", JSON.stringify(token));
  localStorage.setItem("refresh", JSON.stringify(refresh));
  localStorage.setItem("roles", JSON.stringify(roles));
};

const removeCredsFromLocalStorage = () => {
  delete axiosInstance.defaults.headers.common["Authorization"];
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  localStorage.removeItem("roles");
};

const getCredsFromLocalStorage = () => ({
  token: JSON.parse(localStorage.getItem("access")),
  refresh: JSON.parse(localStorage.getItem("refresh")),
  roles: JSON.parse(localStorage.getItem("roles")),
});

export const storage = {
  saveLanguagePreference,
  getLanguagePreference,
  removeLanguagePreference,
  setCredsInLocalStorage,
  removeCredsFromLocalStorage,
  getCredsFromLocalStorage,
};
