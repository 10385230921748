import { Divider } from "@mui/material";
import Title from "../../components/Typography/Title";
import NormalText from "../Typography/NormalText";
import "./card.css";

const CardSectionTitle = ({
  title,
  subText,
  rightSection,
  style = { wrapperStyle: {}, titleStyle: {} },
  classNames = {},
  shouldHideDivider = false,
}) => {
  return (
    <>
      <div
        className={`card-component-section-title-wrapper ${
          classNames.wrapper || ""
        }`}
        style={{
          ...style.wrapperStyle,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Title
            className={`card-component-section-title-title ${
              classNames.title || ""
            }`}
            title={title}
            style={{ ...style.titleStyle }}
          />
          <NormalText text={subText} />
        </div>
        {rightSection}
      </div>
      {!shouldHideDivider && <Divider variant="fullWidth" />}
    </>
  );
};

export default CardSectionTitle;
