import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { notifications } from "../../../constants/notifications";
import { useInboxContext } from "../../../context/InboxProvider";
import SidebarTabs from "../tabs/SidebarTabs";
import "./inbox-sidebar.css";
import ShowMore from "../../show-more/ShowMore";
import NormalText from "../../Typography/NormalText";
import Notification from "./Notification";

const categories = (t) => [
  {
    value: "all",
    label: t("Inbox.Tabs.All"),
  },
  {
    value: notifications.types.collaboration,
    label: t("Inbox.Tabs.Requests"),
  },
  {
    value: notifications.types.activity,
    label: t("Inbox.Tabs.Actions"),
  },
];

const InboxSidebar = () => {
  const { t } = useTranslation();
  const { inbox, seeAll, fetchInbox } = useInboxContext();
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [category, setCategory] = useState("all");

  useEffect(() => {
    if (!inbox) {
      return;
    }
    const { totalUnseen } = inbox || {};
    if (!totalUnseen) {
      return;
    }
    if (typeof seeAll === "function") {
      seeAll();
    }
  }, [inbox, seeAll]);

  const handleShowMore = () => {
    const nextPage = inbox?.paginationState?.nextPage;
    if (nextPage) {
      setIsFetchingMore(true);
      fetchInbox(false, nextPage).finally(() => {
        setIsFetchingMore(false);
      });
    }
  };

  const dividedNotifications = useMemo(() => {
    const notifications = inbox.results;
    const todays = [];
    const earliers = [];
    notifications?.forEach((notification) => {
      const createdAt = moment(notification.createdAt);
      const today = moment().startOf("day");
      if (createdAt.isSame(today, "day")) {
        todays.push(notification);
      } else {
        earliers.push(notification);
      }
    });
    return { todays, earliers };
  }, [inbox?.results]);

  const hasTodayNotifications = dividedNotifications.todays.length > 0;

  return (
    <div className="inbox-sidebar-content">
      <SidebarTabs
        value={category}
        setValue={setCategory}
        tabs={categories(t)}
        classNames={{
          wrapper: "inbox-sidebar-item",
        }}
      />

      {hasTodayNotifications && (
        <>
          <NormalText className="inbox-sidebar-item notification-time-title">
            {t("Inbox.Groups.Todays")}
          </NormalText>

          {dividedNotifications.todays.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              tab={category}
            />
          ))}

          <NormalText className="inbox-sidebar-item notification-time-title">
            {t("Inbox.Groups.Older")}
          </NormalText>
        </>
      )}
      {dividedNotifications.earliers.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          tab={category}
        />
      ))}
      <ShowMore
        isShown={inbox?.paginationState?.nextPage}
        onShowMore={handleShowMore}
        isFetching={isFetchingMore}
      />
      <div style={{ paddingBottom: "40px" }} />
    </div>
  );
};

export default InboxSidebar;
