// export const notificationGroups = {
//   collaboration: "collaboration",
//   activities: "activities",
//   announcements: "announcements",
// };

// export const notificationChoices = {
//   email: "email",
//   inApp: "inApp",
//   webhook: "webhook",
//   webpush: "webpush",
//   mobilepush: "mobilepush",
//   sms: "sms",
// };

export const notifications = {
  channels: {
    collaboration: "collaboration",
    activities: "activities",
    announcements: "announcements",
  },
  types: {
    collaboration: "collaboration",
    activity: "activity",
    announcement: "announcement",
  },
  choices: {
    email: "email",
    inApp: "inApp",
    webhook: "webhook",
    webpush: "webpush",
    mobilepush: "mobilepush",
    sms: "sms",
  },
};
