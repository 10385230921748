import { useMemo } from "react";
import moment from "moment";
import { AvatarCustom, NormalText, SmallText, SubTitle } from "components";

const ActionComment = ({ comment }) => {
  const dateFormatted = useMemo(
    () => moment(comment.createdAt).format("D MMMM YYYY, hh:mm a"),
    [comment?.createdAt]
  );

  return (
    <>
      <div className="comment-meta">
        <AvatarCustom name={comment.userName} />
        <SubTitle>{comment.userName}</SubTitle>
        <SmallText>{dateFormatted}</SmallText>
      </div>
      <NormalText className="comment-text">{comment.text}</NormalText>
    </>
  );
};

export default ActionComment;
