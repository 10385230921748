import { Field, useField, useFormikContext } from "formik";
import { DateRangeField } from "../components";

const FormDateRangeInput = ({
  startName,
  endName,
  onChange,
  shouldHaveErrorBehaviorAfterSubmit,
  fieldStyle,
  ...props
}) => {
  const { submitCount } = useFormikContext();

  const [, metaStart] = useField(startName);
  const [, metaEnd] = useField(endName);
  const startError = startName ? metaStart.error : null;
  const endError = endName ? metaEnd.error : null;

  const shouldDisplayErrorStart = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && startError
    : startError;

  const shouldDisplayErrorEnd = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && endError
    : endError;

  const helperTextStart = (shouldDisplayErrorStart && startError) || "";
  const helperTextEnd = (shouldDisplayErrorEnd && endError) || "";
  const helperText = helperTextStart || helperTextEnd || "";
  return (
    <>
      <Field
        as={DateRangeField}
        {...(typeof onChange === "function" ? { onChange } : {})}
        inputFormat="D MMM YYYY"
        startError={shouldDisplayErrorStart}
        endError={shouldDisplayErrorEnd}
        helperText={helperText}
        fieldStyle={{
          "& .MuiOutlinedInput-root": {
            fontSize: "14px",
          },
          ...fieldStyle,
        }}
        {...props}
      />
    </>
  );
};

export default FormDateRangeInput;
