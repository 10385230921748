import React from "react";
import NoData from "../../assets/images/NoData.png";
import ErrorImg from "../../assets/images/Error.png";
import ErrorCollecting from "../../assets/images/ErrorCollecting.png";
import Collecting from "../../assets/images/Collecting.png";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const Error = ({ type, ErrorMsg }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  if (type === "collecting")
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={Collecting} />
        <Typography textAlign={"center"}>
          {ErrorMsg ?? "Αυτήν την στιγμή συλλέγουμε τα δεδομένα σας"}
        </Typography>
      </Box>
    );
  else if (type === "missing")
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={NoData} />
        <Typography textAlign={"center"}>Δεν υπάρχουν δεδομένα</Typography>
      </Box>
    );
  else if (type === "missingBig")
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isMd ? "90%" : "60%",
        }}
      >
        <img src={ErrorCollecting} />
        <Typography textAlign={"center"}>Δεν υπάρχουν δεδομένα</Typography>
      </Box>
    );
  else if (type === "error")
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isMd ? "90%" : "60%",
        }}
      >
        <img src={ErrorImg} />
        <Typography textAlign={"center"}>
          {ErrorMsg ?? "Δεν υπάρχουν δεδομένα"}
        </Typography>
      </Box>
    );
};

export default Error;
