import { useMemo } from "react";
import { useFormikContext } from "formik";
import { useActionChoicesContext } from "../../../context/GeneralAgroProvider";
import { ACTION } from "../../../constants/actions";
import DiagnoseDetails from "./diagnose/DiagnoseDetails";
import OtherActionDetails from "./other/OtherActionDetails";
import WateringDetails from "./watering/WateringDetails";
import FertilizeDetails from "./fertilize/FertilizeDetails";
import DrugDetails from "./drug/DrugDetails";

const ActionDetails = ({ viewMode, actionStatus }) => {
  const { values } = useFormikContext();
  const { getActionById } = useActionChoicesContext();

  const action = useMemo(
    () => getActionById(values.taskId),
    [getActionById, values?.taskId]
  );

  switch (action) {
    case ACTION.watering:
      return <WateringDetails viewMode={viewMode} status={actionStatus} />;
    case ACTION.drug:
      return <DrugDetails viewMode={viewMode} status={actionStatus} />;
    case ACTION.fertilize:
      return <FertilizeDetails viewMode={viewMode} status={actionStatus} />;
    case ACTION.diagnose:
      return <DiagnoseDetails viewMode={viewMode} status={actionStatus} />;
    case ACTION.other:
      return <OtherActionDetails viewMode={viewMode} status={actionStatus} />;
    default:
      return <></>;
  }
};

export default ActionDetails;
