import { useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "../../../../constants/actionStatus";
import { ACTION_VIEW_MODE } from "../../../../constants/actionViewModes";
import { useActionChoicesContext } from "../../../../context/GeneralAgroProvider";
import { useSelectedFieldContext } from "../../../../context/SelectedFieldProvider";
import Loader from "../../../Loader/Loader";
import NoContentFound from "../../../NoContentFound/NoContentFound";
import FertilizeSuggestion from "./FertilizeSuggestion";
import FertilizePerformed from "./FertilizePerformed";
import { useFetchFertilizers } from "./useFertilizers";

const FertilizeDetails = ({ viewMode, status }) => {
  const { t } = useTranslation();
  const { actionChoices } = useActionChoicesContext();
  const { operations } = actionChoices?.actions?.fertilize;

  const { values } = useFormikContext();
  const isPerformedWithSuggested =
    Boolean(values?.suggestedActionId) ||
    (values.status === ACTION_STATUS.performed &&
      values.initialStatus !== ACTION_STATUS.performed);

  const { selectedField } = useSelectedFieldContext();
  const fertilizersParams = useMemo(
    () => ({
      cropId: selectedField.varietyRead?.cropId,
      // varietyId: selectedField.varietyRead?.varietyId,
    }),
    [selectedField?.varietyRead]
  );
  const { isLoading, error, fertilizers } =
    useFetchFertilizers(fertilizersParams);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return (
      <NoContentFound
        textStyle={{ fontSize: "16px" }}
        text={t("ActionModal.Error.FetchingData")}
      />
    );
  }
  if (
    viewMode === ACTION_VIEW_MODE.view ||
    viewMode === ACTION_VIEW_MODE.edit
  ) {
    if (status !== ACTION_STATUS.performed) {
      return (
        <FertilizeSuggestion
          viewMode={viewMode}
          isFullWidth
          operations={operations}
          fertilizers={fertilizers}
        />
      );
    } else if (isPerformedWithSuggested) {
      return (
        <div className="action-details-both-box-wrapper">
          <FertilizeSuggestion
            viewMode={ACTION_VIEW_MODE.view}
            operations={operations}
            fertilizers={fertilizers}
            isProposedValue
          />
          <FertilizePerformed
            viewMode={viewMode}
            operations={operations}
            fertilizers={fertilizers}
          />
        </div>
      );
    } else {
      return (
        <FertilizePerformed
          viewMode={viewMode}
          isFullWidth
          operations={operations}
          fertilizers={fertilizers}
        />
      );
    }
  } else if (viewMode === ACTION_VIEW_MODE.new) {
    if (status !== ACTION_STATUS.performed) {
      return (
        <FertilizeSuggestion
          viewMode={viewMode}
          isFullWidth
          operations={operations}
          fertilizers={fertilizers}
        />
      );
    } else {
      return (
        <FertilizePerformed
          viewMode={viewMode}
          isFullWidth
          operations={operations}
          fertilizers={fertilizers}
        />
      );
    }
  } else {
    return <></>;
  }
};

export default FertilizeDetails;
