import moment from "moment";
import { createContext, useContext, useState } from "react";

const SidebarsContext = createContext({
  selected: undefined,
  /**
   * @param {('inbox' )} sidebar - The selected sidebar option.
   */
  openSidebar: (sidebar) => {},
  /**
   * @param {('inbox' )} sidebar - The selected sidebar option.
   */
  toggleSidebar: (sidebar) => {},
  closeSidebar: () => {},
});

const SidebarsProvider = ({ children }) => {
  const [selected, setSelected] = useState();
  const [lastOpenedAt, setLastOpenedAt] = useState();

  /**
   * @param {('inbox' )} sidebar - The selected sidebar option.
   */
  const toggleSidebar = (sidebar) => {
    if (selected === sidebar) {
      closeSidebar();
    } else {
      openSidebar(sidebar);
    }
  };

  /**
   * @param {('inbox' )} sidebar - The selected sidebar option.
   */
  const openSidebar = (sidebar) => {
    setSelected(sidebar);
    setLastOpenedAt(moment());
  };

  const closeSidebar = () => {
    if (moment().diff(lastOpenedAt, "milliseconds") < 100) {
      return;
    }
    if (selected) {
      setSelected(undefined);
    }
  };

  return (
    <SidebarsContext.Provider
      value={{
        selected,
        openSidebar,
        closeSidebar,
        toggleSidebar,
      }}
    >
      {children}
    </SidebarsContext.Provider>
  );
};

const useSidebarsContext = () => {
  const context = useContext(SidebarsContext);
  if (context === undefined) {
    throw new Error(
      "useSidebarsContext must be used within a SidebarsProvider"
    );
  }
  return context;
};

export { SidebarsProvider, useSidebarsContext };
