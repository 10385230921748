import * as Yup from "yup";
import { ACTION_STATUS } from "../../constants/actionStatus";

const waterActionValidationSchema = (t, waterTaskId) =>
  Yup.object().when("taskId", {
    is: waterTaskId,
    then: Yup.object().shape({
      operationTypeId: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
      quantity: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      quantityUnits: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    }),
    otherwise: Yup.object()
      .shape({
        operationTypeId: Yup.string().nullable(),
        quantity: Yup.string().nullable(),
        quantityUnits: Yup.string().nullable(),
      })
      .nullable(),
  });

const drugActionValidationSchema = (t, drugTaskId) =>
  Yup.object().when("taskId", {
    is: drugTaskId,
    then: Yup.object().shape({
      bio: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      quantity: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      quantityUnits: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      activeSubstance: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
      composition: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
      compositionUnits: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
    }),
    otherwise: Yup.object()
      .shape({
        bio: Yup.string().nullable(),
        quantity: Yup.string().nullable(),
        quantityUnits: Yup.string().nullable(),
        activeSubstance: Yup.string().nullable(),
        composition: Yup.string().nullable(),
        compositionUnits: Yup.string().nullable(),
      })
      .nullable(),
  });
const fertilizeActionValidationSchema = (t, fertilizeTaskId) =>
  Yup.object().when("taskId", {
    is: fertilizeTaskId,
    then: Yup.object().shape({
      bio: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      operationTypeId: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
      fertId: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
      quantity: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      quantityUnits: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    }),
    otherwise: Yup.object()
      .shape({
        bio: Yup.string().nullable(),
        operationTypeId: Yup.string().nullable(),
        fertId: Yup.string().nullable(),
        quantity: Yup.string().nullable(),
        quantityUnits: Yup.string().nullable(),
      })
      .nullable(),
  });

const diagnoseActionValidationSchema = (t, diagnoseTaskId) =>
  Yup.object().when("taskId", {
    is: diagnoseTaskId,
    then: Yup.object().shape({
      operationTypeId: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
    }),
    otherwise: Yup.object()
      .shape({
        operationTypeId: Yup.string().nullable(),
      })
      .nullable(),
  });

const otherActionValidationSchema = (t, otherTaskId) =>
  Yup.object().when("taskId", {
    is: otherTaskId,
    then: Yup.object().shape({
      operationTypeId: Yup.string()
        .nullable()
        .required(t("ActionModal.ErrorRequiredField")),
    }),
    otherwise: Yup.object()
      .shape({
        operationTypeId: Yup.string().nullable(),
      })
      .nullable(),
  });

export const actionsSchema = (t, actionChoices) =>
  Yup.object().shape({
    actionTypeId: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    taskId: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    dateRange: Yup.object().when("status", {
      is: (value) => value !== ACTION_STATUS.performed,
      then: Yup.object().shape({
        lower: Yup.string().required(t("ActionModal.ErrorRequiredDateRange")),
        upper: Yup.string().required(t("ActionModal.ErrorRequiredDateRange")),
      }),
      otherwise: Yup.object().shape({
        lower: Yup.string().nullable(),
        upper: Yup.string().nullable(),
      }),
    }),
    datetimePerformed: Yup.string().when("status", {
      is: ACTION_STATUS.performed,
      then: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      otherwise: Yup.string().nullable(),
    }),
    timeTaken: Yup.number().when("status", {
      is: ACTION_STATUS.performed,
      then: Yup.number().when("taskId", {
        is: actionChoices?.actions.watering?.taskId,
        then: Yup.number().required(t("ActionModal.ErrorRequiredField")),
        otherwise: Yup.number().nullable(),
      }),
      otherwise: Yup.number().nullable(),
    }),
    // stressType: Yup.string().when("taskId", {
    //   is: actionChoices?.actions?.diagnose?.taskId,
    //   then: Yup.string().nullable(),
    //   otherwise: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    // }),
    // caused: Yup.object().when("taskId", {
    //   is: actionChoices?.actions?.diagnose?.taskId,
    //   then: Yup.object().nullable().shape({
    //     stressId: Yup.string().nullable(),
    //     enemyId: Yup.string().nullable(),
    //   }),
    //   otherwise: Yup.object().when("shouldCheckEnemyId", {
    //     is: true,
    //     then: Yup.object().shape({
    //       stressId: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    //       enemyId: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    //     }),
    //     otherwise: Yup.object().shape({
    //       stressId: Yup.string().required(t("ActionModal.ErrorRequiredField")),
    //       enemyId: Yup.string().nullable(),
    //     }),
    //   }),
    // }),
    stressType: Yup.string().nullable(),
    caused: Yup.object().nullable().shape({
      stressId: Yup.string().nullable(),
      enemyId: Yup.string().nullable(),
    }),
    waterAction: waterActionValidationSchema(
      t,
      actionChoices?.actions.watering?.taskId
    ),
    drugAction: drugActionValidationSchema(
      t,
      actionChoices?.actions.drug?.taskId
    ),
    fertilizeAction: fertilizeActionValidationSchema(
      t,
      actionChoices?.actions.fertilize?.taskId
    ),
    diagnoseAction: diagnoseActionValidationSchema(
      t,
      actionChoices?.actions.diagnose?.taskId
    ),
    otherAction: otherActionValidationSchema(
      t,
      actionChoices?.actions.other?.taskId
    ),
    equipment: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(t("ActionModal.ErrorRequiredField")),
        duration: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      })
    ),
  });

export const analysisSchema = (t) =>
  Yup.object().shape({
    datetimePerformed: Yup.string().when("status", {
      is: ACTION_STATUS.performed,
      then: Yup.string().required(t("ActionModal.ErrorRequiredField")),
      otherwise: Yup.string().nullable(),
    }),
    diagnoseAction: Yup.object().shape({
      operationTypeId: Yup.string().required(
        t("ActionModal.ErrorRequiredField")
      ),
    }),
  });
