import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  styled,
} from "@mui/material";
import Loader from "../Loader/Loader";
import { selectPropTypes } from "./selectPropTypes";

const InputLabelStyled = styled(InputLabel)(() => ({
  position: "inherit",
  marginBottom: "8px",
  marginLeft: "-8px",
  fontSize: "12px",
  fontWeight: 400,
}));

const Placeholder = styled(InputLabel)(() => ({
  fontSize: "14px",
  top: "-3px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const MenuItemStyled = styled(MenuItem)(() => ({
  fontSize: "14px",
}));

const MuiSelectStyled = styled(MuiSelect)(() => ({
  fontSize: "14px",
  borderRadius: "1rem",
  "& .MuiSelect-outlined": {
    fontSize: "14px",
    borderRadius: "1rem",
    padding: "5px 32px 5px 14px",
  },
  "& .Mui-disabled": {
    backgroundColor: "#f0f0f0",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "1rem",
  },
}));

const viewModeStyle = {
  ".MuiSelect-outlined": {
    // paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 400,
    backgroundColor: "transparent",
    "-webkit-text-fill-color": "unset",
  },
  "&.Mui-disabled": {
    color: "unset",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: 0,
  },
  ".MuiSelect-icon": {
    display: "none",
  },
};

const SelectNew = ({
  id,
  options,
  onChange,
  isError,
  helperText,
  name,
  label,
  renderValue,
  defaultValue = "",
  isDisabled,
  isViewMode,
  isFullWidth = true,
  isMultiple,
  placeholder,
  groups,
  value,
  labelStyle,
  isLoading,
  itemRenderer,
  style,
}) => {
  const inputId = (id || name) + Math.floor(Math.random() * 1000).toString();

  const finalStyle = isViewMode ? { ...viewModeStyle, ...style } : style;
  const shouldShowLoader = !isViewMode && isLoading;

  const isValueFromOptions = options?.some((option) => option.value === value);

  return (
    <FormControl size="small" fullWidth={isFullWidth}>
      {label ? (
        <InputLabelStyled id={inputId} shrink={false}>
          {label}
        </InputLabelStyled>
      ) : (
        !isValueFromOptions && (
          <Placeholder id={inputId} shrink={false}>
            {shouldShowLoader && <Loader />}
            {placeholder}
          </Placeholder>
        )
      )}
      <MuiSelectStyled
        renderValue={renderValue}
        value={value}
        disabled={isViewMode || isDisabled}
        labelId={inputId}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        multiple={isMultiple}
        name={name}
        error={Boolean(isError)}
        size="small"
        variant="outlined"
        sx={finalStyle}
      >
        {groups?.reduce((acc, group) => {
          acc.push(
            <ListSubheader key={group.value} style={{ textAlign: "center" }}>
              {group.title}
            </ListSubheader>
          );
          acc.push(
            ...options
              ?.filter((option) => option.group === group.value)
              ?.map((option) => {
                if (typeof option === "string") {
                  return (
                    <MenuItemStyled key={option} value={option}>
                      {option}
                    </MenuItemStyled>
                  );
                } else {
                  return itemRenderer ? (
                    <MenuItemStyled key={option.value} value={option?.value}>
                      {itemRenderer({ option })}
                    </MenuItemStyled>
                  ) : (
                    <MenuItemStyled
                      key={option.value}
                      value={option?.value}
                      disabled={option.disabled}
                    >
                      {option?.label}
                    </MenuItemStyled>
                  );
                }
              })
          );
          return acc;
        }, [])}

        {!Array.isArray(groups) &&
          Array.isArray(options) &&
          options?.map((option) => {
            if (typeof option === "string") {
              return (
                <MenuItemStyled key={option} value={option}>
                  {option}
                </MenuItemStyled>
              );
            } else {
              return itemRenderer ? (
                <MenuItemStyled key={option.value} value={option?.value}>
                  {itemRenderer({ option })}
                </MenuItemStyled>
              ) : (
                <MenuItemStyled
                  key={option.value}
                  value={option?.value}
                  disabled={option.disabled}
                >
                  {option?.label}
                </MenuItemStyled>
              );
            }
          })}
      </MuiSelectStyled>
      <FormHelperText error={Boolean(isError)}>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default SelectNew;

SelectNew.propTypes = selectPropTypes;
