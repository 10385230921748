import "./card.css";

const CardSection = ({ children, style = {}, className }) => {
  return (
    <div className={`card-component-section ${className || ""}`} style={style}>
      {children}
    </div>
  );
};

export default CardSection;
