import "./tabs.css";

const btnClassnameVariants = {
  outlined: "tabs-component-btn",
  underline: "tabs-component-link",
};

const TabButton = ({
  children,
  onClick,
  isSelected,
  variant,
  classNames = {},
  styles,
}) => {
  const selectedClassname = ["selected", classNames.selected || ""].join(" ");

  const finalClassname = [
    btnClassnameVariants[variant],
    classNames.button || "",
    isSelected ? selectedClassname : "",
  ].join(" ");

  const finalStyle = {
    ...styles.button,
    ...(isSelected ? styles.selected : {}),
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={finalClassname}
      style={finalStyle}
    >
      {children}
    </button>
  );
};

const Tabs = ({
  value,
  setValue,
  tabs = [],
  classNames = {},
  styles = {},
  variant = "outlined", // 'outlined' | 'underline'
}) => {
  const { wrapper: wrapperStyle, ...btnStyles } = styles;
  const { wrapper: wrapperClassname, ...btnClassnames } = classNames;
  return (
    <div
      className={`tabs-component-wrapper ${wrapperClassname || ""}`}
      style={wrapperStyle}
    >
      {tabs.map((tab) => (
        <TabButton
          key={tab.value}
          onClick={() => setValue(tab.value)}
          isSelected={tab.value === value}
          variant={variant}
          classNames={btnClassnames}
          styles={btnStyles}
        >
          {tab.label}
        </TabButton>
      ))}
    </div>
  );
};

export default Tabs;
