import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FETCH_STATE } from "../constants/fetchState";
import { api } from "../api";

const LanguagesContext = createContext({
  languages: [],
  fetchState: FETCH_STATE.IDLE,
});

const LanguagesProvider = ({ children }) => {
  const [languages, setLanguages] = useState([]);
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);

  const fetchLanguages = async () => {
    setFetchState(FETCH_STATE.LOADING);
    try {
      const res = await api.account.availableLanguages();
      setLanguages(
        res.data?.map((lan) => ({
          id: lan.id,
          lan: lan.languageMacro,
          labelKey: `Language.${lan.languageMacro}`,
        }))
      );
      setFetchState(FETCH_STATE.SUCCESS);
    } catch (err) {
      setFetchState(FETCH_STATE.ERROR);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const value = useMemo(
    () => ({
      languages,
      fetchState,
    }),
    [languages, fetchState]
  );

  return (
    <LanguagesContext.Provider value={value}>
      {children}
    </LanguagesContext.Provider>
  );
};

const useLanguagesContext = () => {
  const context = useContext(LanguagesContext);

  if (!context) {
    throw new Error(
      "useLanguagesContext must be used within a LanguagesProvider"
    );
  }
  return useContext(LanguagesContext);
};

export { LanguagesProvider, useLanguagesContext };
