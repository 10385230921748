import { Avatar as MuiAvatar } from "@mui/material";

const Avatar = ({
  firstName = "",
  lastName = "",
  size = "54px",
  style = {},
}) => {
  const initials = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

  return (
    <MuiAvatar
      sx={{
        width: size,
        height: size,
        boxShadow: "0px 1px 4px 0px #00000040",
        backgroundColor: "#E5F4F4",
        fontSize: "20px",
        fontWeight: 500,
        textAlign: "center",
        color: "#233D4D",
        ...style,
      }}
    >
      {initials}
    </MuiAvatar>
  );
};

export default Avatar;
