import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api } from "../api";
import { FETCH_STATE } from "../constants/fetchState";
import { useTranslation } from "react-i18next";
import { sortAlphabetically } from "../utils/utils";

const CropsInformationContext = createContext({
  fetchState: FETCH_STATE.IDLE,
  cropsInformation: [],
  getAllTaxonomies: (isMinimal) => {},
  getAllCrops: (isMinimal) => {},
  getAllVarieties: (isMinimal) => {},
  getCropsOfTaxonomy: (taxonomyId, isMinimal) => {},
  getVarietiesOfCrop: (cropId) => {},
  getTaxonomyById: (id, isMinimal) => {},
  getCropById: (id, isMinimal) => {},
  getVarietyById: (id, isMinimal) => {},
});

const transformInformation = ({ information = {}, isEn, parentIdPath }) => {
  const infoArray = Object.values(information);
  return infoArray.map((info) => {
    if (info.taxonomyId) {
      return {
        value: info.taxonomyId,
        label: isEn ? info.taxonomyNameEn : info.taxonomyNameGr,
        children: transformInformation({
          information: info.crops,
          isEn,
          parentIdPath: [info.taxonomyId],
        }),
        parentIdPath: [],
      };
    } else if (info.varieties) {
      return {
        value: info.cropId,
        label: isEn ? info.cropNameEn : info.cropNameGr,
        children: transformInformation({
          information: info.varieties,
          isEn,
          parentIdPath: [...parentIdPath, info.cropId],
        }),
        parentIdPath,
      };
    } else {
      return {
        value: info.varietyId,
        label: isEn ? info.varietyNameEn : info.varietyNameGr,
        parentIdPath,
      };
    }
  });
};

const CropsInformationProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const isEn = useMemo(() => i18n.language.startsWith("en"), [i18n.language]);

  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [cropsInformation, setCropsInformation] = useState([]);

  const getTaxonomyById = useCallback(
    (id, isMinimal) => {
      let result = cropsInformation.find((taxonomy) => taxonomy.value === id);
      if (isMinimal) {
        const { children, ...restTaxonomyInfo } = result;
        result = restTaxonomyInfo;
      }
      return result;
    },
    [cropsInformation]
  );

  const getCropById = useCallback(
    (id, isMinimal) => {
      let result;
      for (let taxonomy of cropsInformation) {
        const crops = taxonomy.children;
        const crop = crops.find((crop) => crop.value === id);
        if (crop) {
          result = crop;
          break;
        }
      }
      if (isMinimal) {
        const { children, ...restCropInfo } = result;
        result = restCropInfo;
      }
      return result;
    },
    [cropsInformation]
  );

  const getAllTaxonomies = useCallback(
    (isMinimal) => {
      let result;
      if (isMinimal) {
        result = cropsInformation?.map((taxonomy) => {
          const { children, ...restTaxonomyInfo } = taxonomy;
          return restTaxonomyInfo;
        });
      } else {
        result = cropsInformation;
      }
      return sortAlphabetically(result, "label");
    },
    [cropsInformation]
  );
  const getAllCrops = useCallback(
    (isMinimal) => {
      let result;
      if (isMinimal) {
        result = cropsInformation?.reduce(
          (acc, taxonomy) =>
            acc.concat(
              taxonomy.children.map((crop) => {
                const { children, ...restCropInfo } = crop;
                return restCropInfo;
              })
            ),
          []
        );
      } else {
        result = cropsInformation?.reduce(
          (acc, taxonomy) => acc.concat(taxonomy.children),
          []
        );
      }
      return sortAlphabetically(result, "label");
    },
    [cropsInformation]
  );
  const getAllVarieties = useCallback(() => {
    const crops = getAllCrops();
    let result;
    result = crops?.reduce((acc, crop) => {
      return acc.concat(crop.children);
    }, []);
    return sortAlphabetically(result, "label");
  }, [getAllCrops]);

  const getCropsOfTaxonomy = useCallback(
    (taxonomyId, isMinimal) => {
      const taxonomy = cropsInformation.find((tax) => tax.value === taxonomyId);
      if (!taxonomy) {
        return [];
      }
      let result;
      if (isMinimal) {
        result = taxonomy.children.map((crop) => {
          const { children, ...restCropInfo } = crop;
          return restCropInfo;
        });
      } else {
        result = taxonomy.children;
      }
      return sortAlphabetically(result, "label");
    },
    [cropsInformation]
  );

  const getVarietiesOfCrop = useCallback(
    (cropId) => {
      const crops = getAllCrops();
      const crop = crops.find((crop) => crop.value === cropId);
      const varieties = crop?.children || [];
      return sortAlphabetically(varieties, "label");
    },
    [getAllCrops]
  );

  useEffect(() => {
    if (typeof isEn === "undefined") return;

    setFetchState(FETCH_STATE.LOADING);
    api.generalAgro
      .cropsInformation()
      .then((res) => {
        setCropsInformation(() =>
          transformInformation({ information: res.data, isEn })
        );
        setFetchState(FETCH_STATE.SUCCESS);
      })
      .catch(() => {
        setFetchState(FETCH_STATE.ERROR);
      });
  }, [isEn]);

  const value = useMemo(
    () => ({
      fetchState,
      cropsInformation,
      getTaxonomyById,
      getCropById,
      getAllTaxonomies,
      getAllCrops,
      getAllVarieties,
      getCropsOfTaxonomy,
      getVarietiesOfCrop,
    }),
    [
      fetchState,
      cropsInformation,
      getTaxonomyById,
      getCropById,
      getAllTaxonomies,
      getAllCrops,
      getAllVarieties,
      getCropsOfTaxonomy,
      getVarietiesOfCrop,
    ]
  );

  return (
    <CropsInformationContext.Provider value={value}>
      {children}
    </CropsInformationContext.Provider>
  );
};

const useCropsInformationContext = () => {
  const context = useContext(CropsInformationContext);

  if (!context) {
    throw new Error(
      "useCropsInformationContext must be used within a CropsInformationProvider"
    );
  }
  return context;
};

export { useCropsInformationContext, CropsInformationProvider };
