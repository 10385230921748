import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFieldsContextActions } from "./FieldsProvider";
import { paths } from "../utils/paths";
import { api } from "../api";

const SelectedFieldContext = createContext({
  selectedField: undefined,
  fetchState: {
    isLoading: false,
    error: undefined,
  },
});

const SelectedFieldContextActions = createContext({
  selectField: ({ postData, onSuccess, onError }) => {},
  updateSelectedField: ({ id, patchData, onSuccess, onError }) => {},
  deleteSelectedField: ({ id, onSuccess, onError }) => {},
  cancelFieldCollaboration: ({ id, onSuccess, onError }) => {},
});

const SelectedFieldProvider = ({ children }) => {
  const { fieldId } = useParams();
  const navigate = useNavigate();

  const { updateField, deleteField, cancelCollaboration } =
    useFieldsContextActions();

  const [selectedField, setSelectedField] = useState();
  const [fetchState, setFetchState] = useState({
    isLoading: false,
    error: undefined,
  });

  const selectField = useCallback(
    (fieldId) => {
      if (fieldId) {
        navigate?.(paths.field(fieldId));
      } else {
        navigate(paths.fields);
      }
    },
    [navigate]
  );

  const fetchSelectedField = useCallback((fieldId) => {
    if (fieldId) {
      setFetchState({ isLoading: true, error: undefined });
      api.fields
        .fetchOne({ fieldId })
        .then((res) => {
          setSelectedField(res.data);
          setFetchState({ isLoading: false, error: undefined });
        })
        .catch((error) => {
          setFetchState({ isLoading: false, error });
        });
    } else {
      setSelectedField();
    }
  }, []);

  useEffect(() => {
    if (typeof fetchSelectedField === "function") {
      fetchSelectedField(fieldId);
    }
  }, [fieldId, fetchSelectedField]);

  const updateSelectedField = useCallback(
    ({ id, patchData, onSuccess, onError }) => {
      updateField?.({
        id,
        patchData,
        onSuccess: (updatedField) => {
          setSelectedField(updatedField);
          onSuccess?.(updatedField);
        },
        onError,
      });
    },
    [updateField]
  );

  const deleteSelectedField = useCallback(
    ({ id, onSuccess, onError }) => {
      deleteField?.({
        id,
        onSuccess: () => {
          navigate?.(paths.fields);
          onSuccess?.();
        },
        onError,
      });
    },
    [deleteField, navigate]
  );

  const cancelFieldCollaboration = useCallback(
    ({ id, onSuccess, onError }) => {
      cancelCollaboration?.({
        id,
        onSuccess: () => {
          navigate?.(paths.fields);
          onSuccess?.();
        },
        onError,
      });
    },
    [cancelCollaboration, navigate]
  );

  const value = useMemo(
    () => ({
      selectedField,
      fetchState,
    }),
    [selectedField, fetchState]
  );

  const actions = useMemo(
    () => ({
      selectField,
      updateSelectedField,
      deleteSelectedField,
      cancelFieldCollaboration,
    }),
    [
      selectField,
      updateSelectedField,
      deleteSelectedField,
      cancelFieldCollaboration,
    ]
  );

  return (
    <SelectedFieldContextActions.Provider value={actions}>
      <SelectedFieldContext.Provider value={value}>
        {children}
      </SelectedFieldContext.Provider>
    </SelectedFieldContextActions.Provider>
  );
};

const useSelectedFieldContext = () => {
  const context = useContext(SelectedFieldContext);

  if (!context) {
    throw new Error(
      "useSelectedFieldContext must be used within a SelectedFieldProvider"
    );
  }
  return useContext(SelectedFieldContext);
};

const useSelectedFieldContextActions = () => {
  const context = useContext(SelectedFieldContextActions);

  if (!context) {
    throw new Error(
      "useSelectedFieldContextActions must be used within a SelectedFieldProvider"
    );
  }
  return useContext(SelectedFieldContextActions);
};

export {
  useSelectedFieldContext,
  useSelectedFieldContextActions,
  SelectedFieldProvider,
};
