import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, useTheme } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import { useInboxContext } from "../../../context/InboxProvider";
import { useSidebarsContext } from "../../../context/SidebarsProvider";
import { FETCH_STATE } from "../../../constants/fetchState";
import Loader from "../../Loader/Loader";
import NoContentFound from "../../NoContentFound/NoContentFound";
import Title from "../../Typography/Title";
import MenuDropdown from "../../menu-dropdown/MenuDropdown";
import InboxSidebar from "./InboxSidebar";
import { useInboxDropdown } from "./useInboxDropdown";

const InboxSidebarWrapper = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { items, isOpen, anchorEl, open, close } = useInboxDropdown();
  const { closeSidebar } = useSidebarsContext();
  return (
    <>
      <div className="inbox-sidebar-title-wrapper">
        <Title
          className="inbox-sidebar-title"
          style={{
            color: theme.color.primary100,
          }}
        >
          {t("Inbox.Title")}
        </Title>
        <span>
          <MenuDropdown
            items={items}
            isOpen={isOpen}
            anchorEl={anchorEl}
            onClose={close}
            onOpen={open}
          >
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </MenuDropdown>
          <IconButton onClick={closeSidebar}>
            <CloseIcon />
          </IconButton>
        </span>
      </div>
      {children}
    </>
  );
};

const InboxSidebarContainer = () => {
  const { t } = useTranslation();
  const { openInbox, fetchState, closeInbox } = useInboxContext();

  useEffect(() => {
    if (typeof openInbox === "function") {
      openInbox();
    }
    return () => {
      if (typeof closeInbox === "function") {
        closeInbox();
      }
    };
  }, [openInbox, closeInbox]);

  const isLoading = fetchState === FETCH_STATE.LOADING;
  const isReady = fetchState === FETCH_STATE.SUCCESS;
  const hasError = fetchState === FETCH_STATE.ERROR;

  return (
    <InboxSidebarWrapper>
      {hasError && <NoContentFound text={t("Inbox.Error")} />}
      {isLoading && <Loader size={40} />}
      {isReady && <InboxSidebar />}
    </InboxSidebarWrapper>
  );
};

export default InboxSidebarContainer;
