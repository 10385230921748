export const sortObjectsByProperty = (a, b, key) => {
  var textA = a[key].toUpperCase();
  var textB = b[key].toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

export const sortObjectEntriesByProperty = (a, b, key) => {
  var textA = a[1][key].toUpperCase();
  var textB = b[1][key].toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};
