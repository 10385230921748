import { useTranslation } from "react-i18next";
import { LoadingButton, SmallText, TextFieldCustom, Title } from "components";
import { FETCH_STATE } from "constants/fetchState";
import { useActionComments } from "../utils/useActionComments";
import "./action-modal-comments.css";
import ActionComment from "./ActionComment";

const ActionCommentsSection = () => {
  const { t } = useTranslation();
  const {
    addComment,
    showOlder,
    hideOlder,
    commentRef,
    fetchState,
    comments,
    displayedComments,
    commentsRemaining,
    couldHideOlder,
  } = useActionComments();

  const isLoading = fetchState === FETCH_STATE.LOADING;
  // const isError = fetchState === FETCH_STATE.ERROR;

  return (
    <div className="action-modal-comments-wrapper">
      <div className="action-modal-comments-title-wrapper">
        <Title>{t("ActionModal.Comments.Title")}</Title>
        <Title style={{ fontSize: "16px" }}>{`(${comments?.length})`}</Title>
      </div>
      <div className="comments-section">
        <span>
          {Boolean(commentsRemaining) && (
            <button onClick={showOlder} className="toggle-more-btn">
              <SmallText>
                {t("ActionModal.Comments.ShowOlder", {
                  count: commentsRemaining,
                })}
              </SmallText>
            </button>
          )}
          {Boolean(couldHideOlder) && (
            <button onClick={hideOlder} className="toggle-more-btn">
              <SmallText>{t("ActionModal.Comments.HideOlder")}</SmallText>
            </button>
          )}
        </span>
        <ul className="comments-list">
          {displayedComments.map((comment) => (
            <li key={comment.id}>
              <ActionComment comment={comment} />
            </li>
          ))}
        </ul>
        <TextFieldCustom
          inputRef={commentRef}
          placeholder={t("ActionModal.Comments.Placeholder")}
          minRows={2}
          isMultiline
          // {...(isError && {
          //   helperText: t("ActionModal.Comments.Error"),
          // })}
          // isError={isError}
        />
        <LoadingButton
          className="submit-comment-btn"
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={addComment}
        >
          {t("ActionModal.Comments.SubmitBtn")}
        </LoadingButton>
      </div>
    </div>
  );
};

export default ActionCommentsSection;
