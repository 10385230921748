import { useTranslation } from "react-i18next";
import { useSelectedFieldContext } from "../../../../context/SelectedFieldProvider";
import Title from "../../../Typography/Title";
import AnalysisResults from "../analysis-results/AnalysisResults";
import { useAnalysisFields } from "../diagnose/useAnalysisFields";
import { ACTION_VIEW_MODE } from "../../../../constants/actionViewModes";

const AnalysisDetails = ({ viewMode }) => {
  const { t } = useTranslation();
  const { selectedField } = useSelectedFieldContext();
  const { analysisFields } = useAnalysisFields(viewMode);
  return (
    <div className="action-modal-card action-details-box ">
      <Title title={t("ActionModal.AnalysisResult.Title")} />
      <AnalysisResults
        field={selectedField}
        analysisFields={analysisFields}
        isViewMode={viewMode === ACTION_VIEW_MODE.view}
      />
    </div>
  );
};

export default AnalysisDetails;
