import { useEffect, useState } from "react";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";
import NormalText from "../Typography/NormalText";

const DurationInput = ({
  units = "minutes",
  unitsStyle,
  style,
  onChange,
  isViewMode,
  value,
  defaultValue,
  ...fieldProps
}) => {
  const [numberValue, setNumberValue] = useState(Number(defaultValue));

  const handleChange = (e) => {
    const duration = parseInt(e.target.value);

    setNumberValue(duration);
    onChange?.(duration);
  };

  const handleBlur = () => {
    if (Number.isNaN(numberValue)) {
      if (value) {
        onChange?.(0);
      } else {
        setNumberValue(0);
      }
    }
  };

  useEffect(() => {
    setNumberValue(value);
  }, [value]);

  return (
    <TextFieldCustom
      type="number"
      onChange={handleChange}
      isViewMode={isViewMode}
      onBlur={handleBlur}
      value={numberValue}
      defaultValue={defaultValue}
      style={{
        // width: "100px",
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            "-webkit-appearance": "none",
            margin: 0,
          },
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        ...style,
      }}
      inputProps={{
        pattern: "[0-9]",
      }}
      InputProps={{
        endAdornment: (
          <NormalText
            style={{
              fontSize: "0.75rem",
              lineHeight: 1.66,
              textAlign: "left",
              letterSpacing: "0.03333em",
              color: "rgba(0,0,0,0.6)",
              marginLeft: "4px",
            }}
            text={units}
          />
        ),
      }}
      {...fieldProps}
    />
  );
};

export default DurationInput;
