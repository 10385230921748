import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSidebarsContext } from "../../../../context/SidebarsProvider";
import { USER_ROLES } from "../../../../constants/userRoles";
import { paths } from "../../../../utils/paths";
import NormalText from "../../../Typography/NormalText";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import NotificationContentContainer from "./NotificationContentContainer";

const requestTypeTranslationKeys = {
  Pending: "ReceivedJoin",
  Accepted: "Accepted",
  Rejected: "Rejected",
};

const RequestNotificationContent = ({ notification }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeSidebar } = useSidebarsContext();
  const { user, request, field } = notification || {};
  const { status } = request || {};

  const requestTypeTranslationKey = requestTypeTranslationKeys[status];
  const requestTranslationKey = `Inbox.Request.${requestTypeTranslationKey}`;

  const handleClick = () => {
    let requestType = "";

    if (request?.status === "Pending") {
      // If it is pending, it means the user has received a request
      requestType = "received";
    } else {
      // if it is accepted/rejected, it means the user had sent a request
      requestType = "sent";
    }
    if (request?.id && user && requestType) {
      let pathBase = "";
      if (user?.role === USER_ROLES.FARMER) {
        pathBase = paths.farmers;
      } else if (user?.role === USER_ROLES.AGRONOMIST) {
        pathBase = paths.agronomists;
      }
      if (pathBase) {
        navigate(
          `${pathBase}?requestId=${request.id}&requestType=${requestType}`
        );
        closeSidebar();
      }
    }
  };

  return (
    <NotificationContentContainer
      notification={notification}
      onClick={handleClick}
    >
      <NormalText>
        <Trans
          i18nKey={requestTranslationKey}
          values={{
            userName: user.firstName + " " + user.lastName,
            fieldName: field?.name,
          }}
          components={{
            navlink: <strong />,
          }}
        />
      </NormalText>
      <div className="buttons-container">
        {/* {totalRequests && totalRequests > 1 && (
          <>
            <ButtonCustom>{t("Inbox.View")}</ButtonCustom>
            <ButtonCustom buttonType="filled">{t("Inbox.View")}</ButtonCustom>
          </>
        )} */}
        {/* {totalRequests && totalRequests === 1 && ( */}
        <ButtonCustom buttonType="filled">{t("Inbox.View")}</ButtonCustom>
        {/* )} */}
      </div>
    </NotificationContentContainer>
  );
};

export default RequestNotificationContent;
