const useNestedTable = ({ allValues, values }) => {
  const handleModify = ({ modifyType, value, parentIdPath, currentValues }) => {
    // Create a deep copy of initialValues to avoid mutating the original object
    const newValues = JSON.parse(JSON.stringify(currentValues || values));

    // Navigate through the structure based on parentIdPath
    let parentLevelArray = newValues;
    let updatePath = [];
    parentIdPath.forEach((id, index) => {
      const parentIndex = parentLevelArray.findIndex(
        (item) => item.value === id
      );
      updatePath.push({ array: parentLevelArray, index: parentIndex });
      parentLevelArray = parentLevelArray[parentIndex].children;
    });

    // Modify the item at the specified level based on the modifyType
    if (modifyType === "remove") {
      parentLevelArray = parentLevelArray.filter(
        (item) => item.value !== value.value
      );
    } else if (modifyType === "add") {
      parentLevelArray.push(value);
    }

    // If no parent, just return the changed array
    if (parentIdPath.length === 0) {
      return parentLevelArray;
    }

    // Propagate changes back up to the root of newValues
    for (let i = updatePath.length - 1; i >= 0; i--) {
      const { array, index } = updatePath[i];
      if (i === updatePath.length - 1) {
        array[index].children = parentLevelArray;
      } else {
        parentLevelArray = array;
      }
    }

    // Return the updated newValues
    return newValues;
  };

  const handleInsert = (value) => {
    // First insert the parents of this value and finish by inseting the value itself
    let currentLevelAllValues = allValues;
    let parentsParentIdPath = [];
    let currentLevelValues = values;
    let currentValues = values;
    // debugger;
    value.parentIdPath.forEach((id, index) => {
      // First insert the parents of this value
      let currentParent = currentLevelValues.find((item) => item.value === id);
      let newValues = currentValues;
      const parentPopulated = currentLevelAllValues.find(
        (item) => item.value === id
      );

      // If the parent does not exist in current values, insert it emptied
      if (!currentParent) {
        const { children, ...parent } = parentPopulated;
        currentParent = {
          ...parent,
          children: [],
        };
        newValues = handleModify({
          modifyType: "add",
          value: currentParent,
          parentIdPath: parentsParentIdPath,
          currentValues,
        });
      }

      parentsParentIdPath.push(id);
      currentLevelAllValues = parentPopulated.children;
      currentValues = newValues;
      currentLevelValues = currentParent.children;
    });

    // Insert the value itself and return
    return handleModify({
      modifyType: "add",
      value,
      parentIdPath: value.parentIdPath,
      currentValues,
    });
  };

  return { handleModify, handleInsert };
};

export default useNestedTable;
