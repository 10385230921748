import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

/**
 * @param {Object} params - The request parameters.
 * @param {'sent' | 'received'} params.requestType - The type of requests to fetch. Should be either 'sent' or 'received'. *
 */
const getCollabRequests = (params = { requestType: undefined }) => {
  const { requestType, ...restParams } = params;
  const config = {
    ...(requestType
      ? { params: { ...restParams, request_type: requestType } }
      : {}),
  };

  return axiosInstance.get(`${URLS.joinRequests}/`, config);
};

const getCollabRequest = (id) => {
  return axiosInstance.get(`${URLS.joinRequests}/${id}/`);
};

/**
 *
 * @param {Object} data
 * @param {string} data.fieldId - The ID of the field to which the user is requesting access.
 * @param {string} data.message - The message to send with the request.
 * @param {string} data.userEmail - The email of the user to whom the request is being sent.
 * @returns
 */
const createCollabRequest = (data) => {
  return axiosInstance.post(URLS.joinRequests + "/", data);
};

const acceptCollabRequest = (id) => {
  return axiosInstance.post(
    `${URLS.joinRequests}/${id}/accept_request/`,
    "",
    {}
  );
};

const acceptCollabRequests = (ids) => {
  return Promise.all(ids.map((id) => acceptCollabRequest(id)));
};

const rejectCollabRequest = (id) => {
  return axiosInstance.post(
    `${URLS.joinRequests}/${id}/reject_request/`,
    "",
    {}
  );
};

const rejectCollabRequests = (ids) => {
  return Promise.all(ids.map((id) => rejectCollabRequest(id)));
};

const inviteToApp = (data) => {
  return axiosInstance.post(`${URLS.users}/invite/simple-via-email/`, data);
};

export const collabApi = {
  acceptMultiple: acceptCollabRequests,
  acceptOne: acceptCollabRequest,
  create: createCollabRequest,
  fetchAll: getCollabRequests,
  fetchOne: getCollabRequest,
  inviteToApp,
  rejectMultiple: rejectCollabRequests,
  rejectOne: rejectCollabRequest,
};
