import * as Yup from "yup";

export const inviteToAppSchema = (t, error) =>
  Yup.object().shape({
    email: Yup.string()
      .test("custom-error", error, function () {
        if (typeof error === "string") {
          return this.createError({ message: error });
        }
        return true;
      })
      .email(t("UsersPage.Modals.InviteUser.Email.ErrorInvalid"))
      .required(t("UsersPage.Modals.InviteUser.Email.ErrorRequired")),
    message: Yup.string().nullable(),
  });
