import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { ACTION_STATUS } from "../../../../constants/actionStatus";
import { useActionChoicesContext } from "../../../../context/GeneralAgroProvider";
import { transformObjectValues } from "../../../../utils/transformObjectKeys";
import { ACTION_VIEW_MODE } from "../../../../constants/actionViewModes";

export const useAnalysisFields = (viewMode) => {
  const { values, setFieldValue } = useFormikContext();
  const { actionChoices } = useActionChoicesContext();
  const [analysisFields, setAnalysisFields] = useState({});

  useEffect(() => {
    const opId = values?.diagnoseAction?.operationTypeId;
    if (!opId || values?.status === ACTION_STATUS.suggested) {
      return;
    }

    const isNewAnalysis =
      viewMode === ACTION_VIEW_MODE.new ||
      (viewMode === ACTION_VIEW_MODE.edit &&
        values?.initialStatus !== ACTION_STATUS.performed);

    if (!isNewAnalysis) {
      setAnalysisFields(values.diagnoseAction?.analysis);
      return;
    }

    const groundOpId =
      actionChoices.actions.diagnose?.operations[1]?.operationTypeId;
    const leafOpId =
      actionChoices.actions.diagnose?.operations[2]?.operationTypeId;
    if (opId === groundOpId) {
      const analysis = transformObjectValues(
        actionChoices.actions.diagnose?.operations[1]?.operationUnits,
        (item) => ({
          ...item,
          quantityUnit: item?.quantityUnits?.[0],
        })
      );
      setFieldValue("diagnoseAction.analysis", analysis);
      setAnalysisFields(analysis);
    } else if (opId === leafOpId) {
      const analysis = transformObjectValues(
        actionChoices.actions.diagnose?.operations[2]?.operationUnits,
        (item) => ({
          ...item,
          quantityUnit: item?.quantityUnits?.[0],
        })
      );
      setFieldValue("diagnoseAction.analysis", analysis);
      setAnalysisFields(analysis);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.diagnoseAction?.operationTypeId,
    values?.status,
    viewMode,
    actionChoices.actions.diagnose?.operations,
  ]);

  return { analysisFields };
};
