import {
  PRIVACY_POLICY_LINK,
  TERMS_CONDITIONS_LINK,
} from "../constants/camvioLinks";

export const paths = {
  home: "/",
  login: "/auth/login",
  register: "/auth/register",
  forgotPassword: "/auth/forgot-password",
  userActivation: "/users/activate/:uid/:token",
  passwordRecovery: "/password-recovery/:uid/:token",
  fields: "/fields",
  field: (id) => `/fields/${id}`,
  addField: "/fields/new",
  farmers: "/farmers",
  farmer: (id) => `/farmers/${id}`,
  agronomists: "/agronomists",
  agronomist: (id) => `/agronomists/${id}`,
  privacyPolicy: PRIVACY_POLICY_LINK,
  terms: TERMS_CONDITIONS_LINK,
  settings: {
    index: "/settings",
    profile: "/settings/profile",
    account: "/settings/account",
    notifications: "/settings/notifications",
    machineries: "/settings/machineries",
  },
  unauthorized: "/unauthorized-error403",
};
