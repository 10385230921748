import { Tooltip, styled, tooltipClasses } from "@mui/material";
import infoIcon from "../../assets/icons/info.svg";

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "black",
    fontSize: 11,
  },
}));

const FieldAnalysisHelperOverlay = () => {
  return (
    <TooltipStyled
      placement="right-start"
      title={
        <div style={{ width: "300px", padding: "8px" }}>
          <h4>Διαχείριση σημείων δειγματοληψίας</h4>
          <br />
          <ul style={{ listStyle: "none" }}>
            <li style={{ paddingBottom: "8px" }}>
              Προσθήκη σημείου:
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "4px",
                  fontWeight: 400,
                }}
              >
                Πατήστε το κουμπί 'Προσθήκη', μετακινήστε το σημείο κουνόντας
                τον κέρσορα και πατήστε αριστερό κλικ για να το τοποθετήσετε
              </div>
            </li>
            <li style={{ paddingBottom: "8px" }}>
              Μετακίνηση σημείου:
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "4px",
                  fontWeight: 400,
                }}
              >
                Σύρετέ το σημείο που σας ενδιαφέρει πατώντας παρατεταμένα πάνω
                του αριστερό κλικ
              </div>
            </li>
            <li style={{ paddingBottom: "8px" }}>
              Διαγραφή σημείου:
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "4px",
                  fontWeight: 400,
                }}
              >
                Πατήστε αριστερό κλικ στο σημείο που σας ενδιαφέρει και στη
                συνέχεια πατήστε το 'x' που εμφανίζεται από πάνω του
              </div>
            </li>
          </ul>
        </div>
      }
    >
      <img src={infoIcon} alt="info-icon" width="28px" />
    </TooltipStyled>
  );
};

export default FieldAnalysisHelperOverlay;
