import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDebouncedEffect } from "hooks/useDebounce";
import SearchIcon from "./SearchIcon/SearchIcon";

const SearchBar = ({
  onChange,
  wrapperStyle,
  inputStyle,
  placeholder,
  variant = "outlined",
  className,
  value = "",
  isDisabled,
}) => {
  const { t } = useTranslation();
  const isInitialized = useRef(false);

  const [finalValue, setFinalValue] = useState(value);

  useDebouncedEffect(
    () => {
      if (!isInitialized.current) {
        isInitialized.current = true;
        return;
      }
      if (typeof onChange === "function") {
        onChange(finalValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [finalValue],
    300
  );

  useEffect(() => {
    setFinalValue(value);
  }, [value]);

  const handleChange = (event) => {
    setFinalValue(event.target.value);
  };

  return (
    <TextField
      name="search-input"
      placeholder={placeholder || t("Components.Searchbar")}
      value={finalValue}
      onChange={handleChange}
      disabled={isDisabled}
      variant={variant}
      className={className}
      sx={{
        width: "100%",
        boxSizing: "border-box",
        ...wrapperStyle,
      }}
      InputProps={{
        startAdornment: <SearchIcon />,
        sx: {
          // border: `0.012rem solid ${theme.color.black30} `,
          fontSize: "14px",
          borderRadius: "1rem",
          padding: "0.5rem 1rem",
          "&:hover": {
            // backgroundColor: theme.color.white100,
            // boxShadow: `0px 2px 2px ${theme.color.black30} `,
          },
          ...inputStyle,
        },
      }}
      inputProps={{
        style: {
          padding: "0 0 0 0.5rem",
        },
      }}
    />
  );
};

export default SearchBar;
