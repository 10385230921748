export const populateActionChoicesActions = (actions = []) => ({
  watering: actions[0],
  drug: actions[1],
  fertilize: actions[2],
  diagnose: actions[3],
  other: actions[4],
});

export const populateActionChoicesTypes = (actionTypes = []) => ({
  prevention: {
    id: actionTypes[0][0],
    name: actionTypes[0][1],
  },
  treatment: {
    id: actionTypes[1][0],
    name: actionTypes[1][1],
  },
});
