import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useAvailableQuantityUnits = (selectedOperationTypeId, operations) => {
  const [availableQuantityUnits, setAvailableQuantityUnits] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOperationTypeId && t && Array.isArray(operations)) {
      const selectedOperation = operations.find(
        ({ operationTypeId }) => operationTypeId === selectedOperationTypeId
      );

      setAvailableQuantityUnits(
        selectedOperation?.operationUnits?.quantityUnits?.map((unit) => ({
          value: unit,
          label: t(`OperationUnits.${unit}`),
        })) || []
      );
    } else {
      setAvailableQuantityUnits([]);
    }
  }, [operations, selectedOperationTypeId, t]);

  return { availableQuantityUnits };
};

export default useAvailableQuantityUnits;
