import { useMemo, useState } from "react";
import { ClickAwayListener, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CellStyled from "./CellStyled";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../autocomplete/Autocomplete";

const getTogglableStyle = (isShown) => ({
  ...(!isShown
    ? {
        height: 0,
        fontSize: 0,
        marginBottom: 0,
        marginTop: 0,
        paddingBottom: 0,
        paddingTop: 0,
        border: 0,
        transition:
          "margin-bottom 0.3s, margin-top 0.3s, padding-bottom 0.3s, padding-top 0.3s",
      }
    : {
        transition:
          "margin-bottom 0.3s, margin-top 0.3s, padding-bottom 0.3s, padding-top 0.3s",
      }),
});

const AddMoreButtonRow = ({
  parent,
  levelIndex,
  totalLevels,
  isShown,
  getOptions,
  onAdd,
}) => {
  const [isFieldShown, setIsFieldShown] = useState(false);
  const hiddenStyle = getTogglableStyle(isShown);

  const handleShow = () => {
    setIsFieldShown(true);
  };

  const handleAdd = (value) => {
    if (value) {
      onAdd?.(value);
    }
    setIsFieldShown(false);
  };
  const options = useMemo(() => getOptions?.(parent), [getOptions, parent]);
  return (
    <tr>
      {Array.from({ length: levelIndex }).map((_, index) => (
        <CellStyled key={`before-${index}`} style={hiddenStyle} />
      ))}
      <CellStyled style={hiddenStyle}>
        {!isFieldShown && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isShown && (
              <IconButton size="small" onClick={handleShow}>
                <AddIcon style={{ width: "22px", height: "23px" }} />
              </IconButton>
            )}
          </div>
        )}
        {isFieldShown && (
          <ClickAwayListener onClickAway={() => setIsFieldShown(false)}>
            <span>
              <Autocomplete options={options} onChange={handleAdd} />
            </span>
          </ClickAwayListener>
        )}
      </CellStyled>
      {Array.from({ length: totalLevels - levelIndex - 1 }).map((_, index) => (
        <CellStyled key={`after-${index}`} style={hiddenStyle} />
      ))}
    </tr>
  );
};

const NestedTableRow = ({
  levels,
  levelIndex,
  parent,
  value,
  isShown,
  getOptions,
  onRemove,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  const totalLevels = levels.length;
  const hiddenStyle = getTogglableStyle(isShown);

  if (value.isAddButton) {
    return (
      <AddMoreButtonRow
        value={value}
        parent={parent}
        levelIndex={levelIndex}
        totalLevels={totalLevels}
        isShown={isShown}
        getOptions={getOptions[levelIndex]}
        onAdd={onAdd}
      />
    );
  }

  const children = value.children;

  const handleRemove = () => {
    onRemove(value, value.parentIdPath);
  };

  const shouldShowRemoveBtn = isShown && typeof onRemove === "function";

  return (
    <>
      <tr>
        {Array.from({ length: levelIndex }).map((_, index) => (
          <CellStyled key={`before-${index}`} style={hiddenStyle} />
        ))}
        <CellStyled style={hiddenStyle}>
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ...(isShown ? { padding: "6px 0px" } : {}),
            }}
          >
            {value.label}
            {shouldShowRemoveBtn && (
              <IconButton size="small" onClick={handleRemove}>
                <CloseIcon fontSize="14px" />
              </IconButton>
            )}
          </span>
        </CellStyled>
        {levelIndex < totalLevels - 1 && (
          <CellStyled style={hiddenStyle}>
            <span
              tabIndex={0}
              role="button"
              onClick={handleToggleOpen}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              {isShown &&
                (isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
              {isOpen
                ? t("NestedTable.Hide")
                : t("NestedTable.Show", { count: children?.length || 0 })}
            </span>
          </CellStyled>
        )}
        {Array.from({ length: totalLevels - levelIndex - 2 }).map(
          (_, index) => (
            <CellStyled key={`after-${index}`} style={hiddenStyle} />
          )
        )}
      </tr>
      {children?.map((child) => (
        <NestedTableRow
          key={child.label}
          levels={levels}
          levelIndex={levelIndex + 1}
          value={child}
          isShown={isShown && isOpen}
          onRemove={onRemove}
          onAdd={onAdd}
          getOptions={getOptions}
        />
      ))}
      {children && (
        <NestedTableRow
          value={{ isAddButton: true }}
          parent={value}
          levels={levels}
          levelIndex={levelIndex + 1}
          isShown={isShown && isOpen}
          onAdd={onAdd}
          getOptions={getOptions}
        />
      )}
    </>
  );
};

export default NestedTableRow;
