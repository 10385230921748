import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelectedFieldContext } from "../../../../context/SelectedFieldProvider";
import SubTitle from "../../../Typography/SubTitle";
import SmallText from "../../../Typography/SmallText";
import InfoIcon from "../../../../assets/icons/info-outlined.svg";
import NormalText from "../../../Typography/NormalText";
import { useWateringUsageContext } from "../../../../context/GeneralAgroProvider";

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
  },
});

const WateringLimitsTooltip = ({ limitsText, onClose }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const newRef = useRef(null);

  const handleOutsideClick = useCallback(
    (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        onClose?.();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const limitsTextTranslated = isEn
    ? limitsText?.descriptionEn
    : limitsText?.descriptionGr;

  return (
    <div
      ref={newRef}
      style={{
        width: "300px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "8px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SubTitle text={t("ActionModal.WateringLimits")} />
        <CloseIcon sx={{ cursor: "pointer", fill: "#000" }} onClick={onClose} />
      </div>
      <SmallText text={limitsTextTranslated} />
    </div>
  );
};

const WateringLimits = () => {
  const { t } = useTranslation();
  const [showLimits, setShowLimits] = useState(false);
  const [limits, setLimits] = useState();
  const { getWateringLimits } = useWateringUsageContext();
  const { selectedField: field } = useSelectedFieldContext();

  useEffect(() => {
    const fieldCropId = field?.varietyRead?.cropId;
    if (fieldCropId) {
      getWateringLimits(fieldCropId).then((lim) => {
        setLimits(lim);
      });
    }
  }, [field, getWateringLimits]);

  return (
    <TooltipStyled
      open={showLimits}
      title={
        <WateringLimitsTooltip
          limitsText={limits}
          onClose={() => setShowLimits(false)}
        />
      }
      placement="bottom-start"
    >
      <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
        <img alt="info" src={InfoIcon} />
        <span onClick={() => setShowLimits((prev) => !prev)}>
          <NormalText
            text={t("ActionModal.WateringLimits")}
            style={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          />
        </span>
      </div>
    </TooltipStyled>
  );
};

export default WateringLimits;
