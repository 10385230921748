export const getSubstring = (string, lengthOverflow) => {
  return string.length > lengthOverflow
    ? `${string.substring(0, lengthOverflow)}...`
    : string;
};

export const sortData = (data) => {
  return data?.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const sortAlphabetically = (data, key) => {
  return data?.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};

export const isEmpty = (object) => {
  return Object.keys(object).length === 0;
};

export const convertFromLatLng = (coords) => {
  const convertedCoords = [];
  coords.forEach((coord) => convertedCoords.push([coord.lng, coord.lat]));
  return convertedCoords;
};

export const setOfArrayOfObjects = (arr, key) => {
  return arr.reduce((acc, curr) => {
    if (!acc.some((obj) => obj[key] === curr[key])) {
      acc.push(curr);
    }
    return acc;
  }, []);
};
