import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "../components/Maps/indices-comparator/sidebyside";

export const useMapImageOverlay = (png) => {
  const map = useMap();
  const overlayRef = useRef(null);

  useEffect(() => {
    if (overlayRef.current) {
      map.removeLayer(overlayRef.current);
    }
    if (png.pngImage) {
      const ndviLayer = L.imageOverlay(png.pngImage, [
        [png.boundBox.maxLat, png.boundBox.minLon],
        [png.boundBox.minLat, png.boundBox.maxLon],
      ]);

      ndviLayer.addTo(map);
      overlayRef.current = ndviLayer;
    }

    return () => {
      if (overlayRef.current) {
        map.removeLayer(overlayRef.current);
      }
    };
  }, [png, map]);
};

export const useSideBySideImageOverlays = (png1, png2) => {
  const map = useMap();

  const indice1Ref = useRef(null);
  const indice2Ref = useRef(null);
  const sideBySideRef = useRef(null);

  useEffect(() => {
    // Clear previous layers and sideBySide control if they exist
    if (indice1Ref.current) {
      map.removeLayer(indice1Ref.current);
    }
    if (indice2Ref.current) {
      map.removeLayer(indice2Ref.current);
    }
    if (sideBySideRef.current) {
      map.removeControl(sideBySideRef.current);
    }

    if (png1?.pngImage && png2?.pngImage) {
      map.createPane("left");
      map.createPane("right");

      // Create new image overlay layers
      const ndviLayer1 = L.imageOverlay(
        png1.pngImage,
        [
          [png1.boundBox.maxLat, png1.boundBox.minLon],
          [png1.boundBox.minLat, png1.boundBox.maxLon],
        ],
        { pane: "left" }
      );
      indice1Ref.current = ndviLayer1;

      const ndviLayer2 = L.imageOverlay(
        png2.pngImage,
        [
          [png2.boundBox.maxLat, png2.boundBox.minLon],
          [png2.boundBox.minLat, png2.boundBox.maxLon],
        ],
        { pane: "right" }
      );
      indice2Ref.current = ndviLayer2;

      // Add new layers to the map
      ndviLayer1.addTo(map);
      ndviLayer2.addTo(map);

      // Create new sideBySide control
      const sideBySide = L.control.sideBySide(ndviLayer1, ndviLayer2);
      sideBySide.addTo(map);
      sideBySideRef.current = sideBySide;
    }

    // Clean up function to remove layers and sideBySide control when component unmounts
    return () => {
      if (indice1Ref.current) {
        map.removeLayer(indice1Ref.current);
      }
      if (indice2Ref.current) {
        map.removeLayer(indice2Ref.current);
      }
      if (sideBySideRef.current) {
        map.removeControl(sideBySideRef.current);
      }
    };
  }, [png1, png2, map]);
};
