import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCropsInformationContext } from "context/CropsInformationProvider";
import SelectorNew from "../Selector/SelectorNew";

const CropSelector = ({
  onChange,
  style = {},
  selectorStyle = {},
  initialValues = {},
}) => {
  const { t } = useTranslation();

  const {
    cropsInformation,
    getAllTaxonomies,
    getCropsOfTaxonomy,
    getVarietiesOfCrop,
  } = useCropsInformationContext();

  const [taxonomyCode, setTaxonomyCode] = useState(initialValues.taxonomyId);
  const [cropCode, setCropCode] = useState(initialValues.cropId);
  const [varietyCode, setVarietyCode] = useState(initialValues.varietyId);
  const [dirtyFields, setDirtyFields] = useState({
    taxonomyCode: false,
    cropCode: false,
  });

  useEffect(() => {
    if (varietyCode && typeof onChange === "function") {
      onChange(varietyCode);
    }
  }, [varietyCode]);

  useEffect(() => {
    if (dirtyFields.taxonomyCode) {
      setCropCode(undefined);
    }
  }, [taxonomyCode]);

  useEffect(() => {
    if (dirtyFields.cropCode) {
      setVarietyCode(undefined);
    }
  }, [cropCode]);

  return cropsInformation?.length ? (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "8px", ...style }}
    >
      <SelectorNew
        options={getAllTaxonomies(true)}
        name="taxonomyId"
        label={t("FieldsPage.AddFieldSidebar.Form.CropTaxonomy.Label")}
        onChange={(e) => {
          setDirtyFields((prev) => ({ ...prev, taxonomyCode: true }));
          setTaxonomyCode(e.target.value);
        }}
        style={selectorStyle}
        value={taxonomyCode}
      />
      <SelectorNew
        isDisabled={!Boolean(taxonomyCode)}
        options={getCropsOfTaxonomy(taxonomyCode, true)}
        name="cropId"
        label={t("FieldsPage.AddFieldSidebar.Form.CropCrop.Label")}
        onChange={(e) => {
          setDirtyFields((prev) => ({ ...prev, cropCode: true }));
          setCropCode(e.target.value);
        }}
        value={cropCode}
        style={selectorStyle}
      />
      <SelectorNew
        isDisabled={!Boolean(cropCode)}
        options={getVarietiesOfCrop(cropCode)}
        name="varietyId"
        label={t("FieldsPage.AddFieldSidebar.Form.CropVariety.Label")}
        onChange={(e) => setVarietyCode(e.target.value)}
        value={varietyCode}
        style={selectorStyle}
      />
    </div>
  ) : (
    <></>
  );
};

export default CropSelector;
