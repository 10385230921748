import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../LoadingButton/LoadingButton";
import { useFormikContext } from "formik";

const NewAnalysisHeader = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const { isValid } = useFormikContext();

  const isDisabled = isLoading || !isValid;

  return (
    <div className="action-modal-header-row space-between">
      <div className="action-modal-header-title">
        {t("ActionModal.Mode.NewAnalysis")}
      </div>
      <div className="action-modal-header-btn-group">
        <LoadingButton
          isLoading={isLoading}
          className="action-action-button"
          isDisabled={isDisabled}
          onClick={onSubmit}
          buttonType="filled"
        >
          {t("ActionModal.SubmitButton")}
        </LoadingButton>
      </div>
    </div>
  );
};

export default NewAnalysisHeader;
