import { useCallback, useState } from "react";
import nestedUtils from "utils/nestedUtils";

export const useTreeHelpers = ({ data, checkedNodes, onChange }) => {
  const [nodes, setNodes] = useState(data);

  const handleToggle = useCallback((node) => {
    const { value, parentIdPath } = node;
    setNodes((prevNodes) => {
      if (parentIdPath.length === 0) {
        return prevNodes.map((node) =>
          node.value === value
            ? { ...node, isExpanded: !node.isExpanded }
            : node
        );
      }
      if (parentIdPath.length === 1) {
        return prevNodes.map((node) =>
          node.value === parentIdPath[0]
            ? {
                ...node,
                children: node.children.map((child) =>
                  child.value === value
                    ? { ...child, isExpanded: !child.isExpanded }
                    : child
                ),
              }
            : node
        );
      }
      if (parentIdPath.length === 2) {
        return prevNodes.map((node) =>
          node.value === parentIdPath[0]
            ? {
                ...node,
                children: node.children.map((pref) =>
                  pref.value === parentIdPath[1]
                    ? {
                        ...pref,
                        children: pref.children.map((child) =>
                          child.value === value
                            ? { ...child, isExpanded: !child.isExpanded }
                            : child
                        ),
                      }
                    : pref
                ),
              }
            : node
        );
      }
    });
  }, []);

  const handleCheck = useCallback(
    (node) => {
      const newCheckedNodes = nestedUtils.toggleItem({
        checkedNodes,
        data: nodes,
        item: node,
      });
      onChange?.(newCheckedNodes);
    },
    [checkedNodes, nodes, onChange]
  );

  return { nodes, handleToggle, handleCheck };
};
