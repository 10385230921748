import * as Yup from "yup";
import { yupTaxNumber } from "./yupRules";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const noSpaceRegExp = /^\S+$/;

export const profileSettingsSchemas = (t) =>
  Yup.object().shape({
    firstName: Yup.string().required(
      t("SettingsPage.Profile.Basic.FirstName.Error")
    ),
    lastName: Yup.string().required(
      t("SettingsPage.Profile.Basic.LastName.Error")
    ),
    region: Yup.string().nullable(),
    // region: Yup.string().when("role", {
    // is: (value) => value === USER_ROLES.AGRONOMIST,
    //   then: Yup.string().required(t("Register.RegionError")),
    //   otherwise: Yup.string().nullable(),
    // }),
  });

export const accountSettingsSchemas = (t) =>
  Yup.object().shape({
    firstName: Yup.string().required(
      t("SettingsPage.Account.Personal.FirstName.Error")
    ),
    lastName: Yup.string().required(
      t("SettingsPage.Account.Personal.LastName.Error")
    ),
    email: Yup.string().required(t("SettingsPage.Account.Contact.Email.Error")),
    dob: Yup.string().nullable(),
    taxNumber: yupTaxNumber(t),
    phone: Yup.string()
      .matches(phoneRegExp, t("Register.MobilePhoneErrorNum"))
      .matches(noSpaceRegExp, t("Register.ErrorNoSpaces"))
      .min(10, t("Register.MobilePhoneErrorLength")),
  });
