import { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { api } from "api";
import { actionFormValue } from "../../utils/inputName";

const BIO_SELECTIONS = [
  {
    value: false,
    label: {
      nameGr: "Συμβατική",
      nameEn: "Conventional",
    },
  },
  {
    value: true,
    label: {
      nameGr: "Βιολογική",
      nameEn: "Biological",
    },
  },
];

const useFertilizeTypes = (operations) => {
  const [fertilizeTypes, setFertilizeTypes] = useState({});

  useEffect(() => {
    const filters = {};

    operations.forEach(({ operationTypeName, operationTypeId }) => {
      switch (operationTypeName.toLowerCase()) {
        case "υδρολίπανση":
          filters[operationTypeId] = "hydro";
          break;
        case "εδαφική βασική λίπανση":
          filters[operationTypeId] = "groundBasic";
          break;
        case "εδαφική επιφανειακή λίπανση":
          filters[operationTypeId] = "groundSurface";
          break;
        case "διαφυλλική λίπανση":
          filters[operationTypeId] = "foliar";
          break;
        default:
          break;
      }
    });

    setFertilizeTypes(filters);
  }, [operations]);

  return { fertilizeTypes };
};

export const useFilteredFertilizers = ({
  fertilizers,
  operations,
  isProposed,
}) => {
  const { values, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext();
  const { fertilizeTypes } = useFertilizeTypes(operations);
  const [filteredFertilizers, setFilteredFertilizers] = useState([]);
  const [limits, setLimits] = useState([]);

  useEffect(() => {
    if (fertilizers && Array.isArray(fertilizers)) {
      const selectedOperationTypeId = actionFormValue(
        values,
        "fertilizeAction",
        "operationTypeId",
        isProposed
      );
      const selectedFertilizeType = fertilizeTypes[selectedOperationTypeId];
      const selectedBio = actionFormValue(
        values,
        "fertilizeAction",
        "bio",
        isProposed
      );

      setFilteredFertilizers(
        fertilizers.filter(
          (fertilizer) =>
            (!selectedFertilizeType || fertilizer[selectedFertilizeType]) &&
            (!selectedBio || fertilizer.bio === selectedBio)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProposed,
    fertilizers,
    fertilizeTypes,
    values?.fertilizeAction?.operationTypeId,
    values?.fertilizeAction?.bio,
  ]);

  const handleFertilizerChange = (e) => {
    handleChange(e);

    const selectedFertilizer = filteredFertilizers.find(
      ({ ourId }) => e.target.value === ourId
    );

    setLimits(selectedFertilizer?.cropLimits[0]);
    setFieldValue(
      "fertilizeAction.brandName",
      selectedFertilizer.brandName
    ).then(() => {
      setFieldTouched("fertilizeAction.brandName", true);
    });
    setFieldValue(
      "fertilizeAction.composition",
      selectedFertilizer.composition
    ).then(() => {
      setFieldTouched("fertilizeAction.composition", true);
    });
  };

  return {
    filteredFertilizers,
    limits,
    handleFertilizerChange,
    bioSelections: BIO_SELECTIONS,
  };
};

export const useFetchFertilizers = (params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fertilizers, setFertilizers] = useState();
  const [error, setError] = useState();

  const fetchFertilizers = useCallback(async () => {
    setError();
    setIsLoading(true);
    try {
      const res = await api.crops.fertilizers(params);
      let newFertilizers = res.data;
      if (!newFertilizers || !newFertilizers?.length) {
        const newRes = await api.crops.fertilizers();
        newFertilizers = newRes.data;
      }
      setFertilizers(newFertilizers);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchFertilizers();
  }, [fetchFertilizers]);

  return { isLoading, fertilizers, error };
};
