import { useEffect, useState } from "react";
import DateField from "./DateField";
import TimeField from "../TimeField/TimeField";

const DateTimeField = ({
  defaultValue,
  datePlaceholder,
  timePlaceholder,
  dateLabel,
  timeLabel,
  dateInputFormat,
  timeInputFormat,
  locale,
  onChange,
  value,
  wrapperStyle,
  dateStyle,
  timeStyle,
  isDisabled,
  isError,
}) => {
  const [dateTime, setDateTime] = useState(defaultValue || null);

  const handleDateChange = (date) => {
    const newDateTime = dateTime
      ? dateTime.clone().set({
          year: date.year(),
          month: date.month(),
          date: date.date(),
        })
      : date;

    setDateTime(newDateTime);
    onChange?.(newDateTime);
  };

  const handleTimeChange = (time) => {
    const newDateTime = dateTime
      ? dateTime.clone().set({
          hour: time.hour(),
          minute: time.minute(),
          second: time.second(),
          millisecond: time.millisecond(),
        })
      : time;

    setDateTime(newDateTime);
    onChange?.(newDateTime);
  };

  useEffect(() => {
    setDateTime(value);
  }, [value]);

  return (
    <div style={{ ...wrapperStyle }}>
      <DateField
        defaultValue={defaultValue}
        placeholder={datePlaceholder}
        label={dateLabel}
        inputFormat={dateInputFormat}
        value={dateTime}
        onChange={handleDateChange}
        style={dateStyle}
        isDisabled={isDisabled}
        locale={locale}
        isError={isError}
      />
      <TimeField
        defaultValue={defaultValue}
        placeholder={timePlaceholder}
        inputFormat={timeInputFormat}
        label={timeLabel}
        value={dateTime}
        onChange={handleTimeChange}
        style={timeStyle}
        isDisabled={isDisabled}
        locale={locale}
        isError={isError}
      />
    </div>
  );
};

export default DateTimeField;
