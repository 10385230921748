import Tabs from "../../Tabs/Tabs";
import "./sidebar-tabs.css";

const SidebarTabs = ({
  value,
  setValue,
  tabs,
  classNames = { wrapper: {}, button: {}, selected: {} },
}) => {
  return (
    <Tabs
      setValue={setValue}
      value={value}
      tabs={tabs}
      variant="underline"
      classNames={{
        wrapper: `sidebar-tabs-wrapper ${classNames.wrapper || ""}`,
        button: `sidebar-tabs-button ${classNames.button || ""}`,
        selected: `sidebar-tabs-selected ${classNames.selected || ""}`,
      }}
    />
  );
};

export default SidebarTabs;
