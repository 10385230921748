/**
 * Returns the available action options based on the selected type ID.
 * @param {number} selectedTypeId - The selected type ID.
 * @param {object} actions - The actions object containing different action options.
 * @returns {array} - The array of available action options.
 */
export const availableActionsOptions = (selectedTypeId, actions) => {
  if (selectedTypeId?.toString() === "1") {
    return [
      actions.watering,
      actions.drug,
      actions.fertilize,
      actions.diagnose,
      actions.other,
    ];
  } else {
    return [actions.watering, actions.drug, actions.fertilize, actions.other];
  }
};

/**
 * Checks if the enemy is in the filtered enemies list and updates the values accordingly.
 * @param {boolean} actionTypeInputIsInViewMode - Indicates if the action type input is in view mode.
 * @param {object} values - The current values object.
 * @param {function} setValues - The function to update the values object.
 * @param {array} filteredEnemies - The filtered enemies list.
 */
export const checkIfEnemyIsInFilteredEnemies = (
  actionTypeInputIsInViewMode,
  values,
  setValues,
  filteredEnemies
) => {
  if (!actionTypeInputIsInViewMode) {
    const enemyId = values?.caused?.enemyId || "";
    const caused = {};

    if (
      enemyId.length &&
      filteredEnemies.length &&
      !filteredEnemies.map(({ uuid }) => uuid).includes(enemyId)
    ) {
      caused["enemyId"] = "";
      caused["enemyName"] = "";
    }

    if (filteredEnemies.length) {
      setValues((prev) => ({
        ...prev,
        caused: { ...prev?.caused, ...caused },
        shouldCheckEnemyId: true,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        caused: { ...prev?.caused, ...caused },
        shouldCheckEnemyId: false,
      }));
    }
  }
};

/**
 * Checks if the stress is in the filtered stresses list and updates the values accordingly.
 * @param {boolean} actionTypeInputIsInViewMode - Indicates if the action type input is in view mode.
 * @param {object} values - The current values object.
 * @param {function} setValues - The function to update the values object.
 * @param {array} filteredStresses - The filtered stresses list.
 * @param {string} stressType - The stress type.
 */
export const checkIfStressIsInFilteredStresses = (
  actionTypeInputIsInViewMode,
  values,
  setValues,
  filteredStresses
) => {
  if (!actionTypeInputIsInViewMode) {
    const stressId = values?.caused?.stressId;

    if (
      stressId &&
      !filteredStresses.map(({ uuid }) => uuid).includes(stressId)
    ) {
      setValues((prev) => ({
        ...prev,
        caused: { ...prev?.caused, stressId: "", stressName: "" },
      }));
    }
  }
};
