import React, { useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";
import { MenuItem, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const MenuItemStyled = styled(MenuItem)(() => ({
  textWrap: "wrap",
}));

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(
  function ListboxComponent(props, ref) {
    const { children: itemData, ...other } = props;
    const itemCount = itemData.length;

    const rowHeights = useRef({});
    const gridRef = useResetCache(itemCount);

    const setRowHeight = (index, size) => {
      if (gridRef.current != null) {
        gridRef.current.resetAfterIndex?.(0);
      }
      rowHeights.current = { ...rowHeights.current, [index]: size };
    };

    const getRowHeight = (index) => {
      return rowHeights.current[index] || 40;
    };

    const Row = (props) => {
      const { data, index, style } = props;
      const dataSet = data[index];
      const { height, ...restStyle } = style || {};
      const inlineStyle = {
        ...restStyle,
        top: style.top,
      };

      const { key, ...optionProps } = dataSet[0];

      const option = dataSet[1];
      const isIncluded = option.isIncluded;

      const rowRef = useRef(null);
      useEffect(() => {
        if (rowRef.current) {
          setRowHeight(index, rowRef.current.clientHeight);
        }
        // eslint-disable-next-line
      }, [rowRef]);

      return (
        <MenuItemStyled
          ref={rowRef}
          key={key}
          style={inlineStyle}
          {...optionProps}
        >
          {option.label}
          {isIncluded && (
            <span style={{ marginLeft: "8px" }}>
              <CheckIcon fontSize="16px" />
            </span>
          )}
        </MenuItemStyled>
      );
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={500}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            // innerElementType="ul"
            itemSize={getRowHeight}
            overscanCount={5}
            itemCount={itemCount}
          >
            {Row}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

export default ListboxComponent;
