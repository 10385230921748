import { useState } from "react";
import "../action-modal-header.css";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  useActionModalGetContext,
  useActionModalSetContext,
} from "context/ActionModalProvider";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import ConfirmationDialog from "../../../dialog/ConfirmationDialog";
import { useActionCalls } from "../../utils/useActionsUtils";
import EditActionModalHeader from "./modes/EditActionModalHeader";
import NewActionModalHeader from "./modes/NewActionModalHeader";
import ViewActionModalHeader from "./modes/ViewActionModalHeader";

const CONFIRM_DIALOG_OPTIONS = {
  delete: "delete",
  undoComplete: "undoComplete",
};

const ActionModalHeader = ({ fieldId, onClose, isLoading }) => {
  const { t } = useTranslation();
  const { action } = useActionModalGetContext();
  const { setAction } = useActionModalSetContext();
  const { resetForm, submitForm, setFieldValue } = useFormikContext();
  const { deleteAction, isLoading: isActionCallLoading } =
    useActionCalls(fieldId);
  const [confirmationDialog, setConfirmationDialog] = useState();

  const viewMode = action.type;

  const handleSubmit = () => {
    submitForm();
  };

  const handleConfirmDelete = () => {
    setConfirmationDialog(CONFIRM_DIALOG_OPTIONS.delete);
  };

  const handleDelete = () => {
    deleteAction({
      status: action.action.status,
      values: action.action,
      onSuccess: () => {
        setConfirmationDialog();
        onClose?.();
      },
      onError: () => {
        setConfirmationDialog();
        // TODO: - toast error or close modal
      },
    });
  };

  const handleConfirm = () => {
    if (confirmationDialog === CONFIRM_DIALOG_OPTIONS.delete) {
      handleDelete();
      return;
    }
    setFieldValue("status", ACTION_STATUS.suggested).then(() => {
      handleSubmit();
    });
  };

  const handleCancelEdit = () => {
    resetForm();
    setAction((prev) => ({ ...prev, type: ACTION_VIEW_MODE.view }));
  };

  const handleToggleComplete = () => {
    if (action.action.status !== ACTION_STATUS.performed) {
      setFieldValue("status", ACTION_STATUS.performed);
      setAction((prev) => ({
        ...prev,
        type: ACTION_VIEW_MODE.edit,
      }));
      return;
    }
    setConfirmationDialog(CONFIRM_DIALOG_OPTIONS.undoComplete);
  };

  const handleEdit = () => {
    setAction((prev) => ({
      ...prev,
      type: ACTION_VIEW_MODE.edit,
    }));
  };

  const isCallsLoading = isLoading || isActionCallLoading;

  const confirmationContent = {
    [CONFIRM_DIALOG_OPTIONS.delete]: {
      title: t("ActionModal.DeleteConfirmationTitle"),
      contentText: t("ActionModal.DeleteConfirmationContentText"),
    },
    [CONFIRM_DIALOG_OPTIONS.undoComplete]: {
      title: t("ActionModal.UndoCompleteConfirmationTitle"),
      contentText: t("ActionModal.UndoCompleteConfirmationContentText"),
    },
  };

  return (
    <div className="action-modal-header-wrapper">
      {viewMode === ACTION_VIEW_MODE.new && (
        <NewActionModalHeader
          action={action}
          onSubmit={handleSubmit}
          isLoading={isCallsLoading}
        />
      )}
      {viewMode === ACTION_VIEW_MODE.edit && (
        <EditActionModalHeader
          onCancel={handleCancelEdit}
          onSubmit={handleSubmit}
          isLoading={isCallsLoading}
        />
      )}
      {viewMode === ACTION_VIEW_MODE.view && (
        <ViewActionModalHeader
          action={action}
          onDelete={handleConfirmDelete}
          onEdit={handleEdit}
          onToggleComplete={handleToggleComplete}
          isLoading={isCallsLoading}
        />
      )}
      <ConfirmationDialog
        isConfirmLoading={isActionCallLoading}
        isOpen={Boolean(confirmationDialog)}
        onCancel={() => {
          setConfirmationDialog();
        }}
        title={confirmationContent[confirmationDialog]?.title}
        contentText={confirmationContent[confirmationDialog]?.contentText}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default ActionModalHeader;
