import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
//TEMPLATE ICON
import Sun from "../../../assets/icons/weather/sun.svg";
import Error from "../../Errors/Error.js";
const MarkerPopup = ({ field }) => {
  const theme = useTheme();
  const fieldName = field.name;
  const cropName = field.varietyRead && field.varietyRead.cropNameGr;
  const farmerName =
    field.farmerDetails.length > 0 ? field.farmerDetails[0].lastName : "";

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.color.white100,
          position: "fixed",
          height: "417px",
          maxHeight: "417px",
          maxWidth: "260px",
          width: "260px",
          boxSizing: "border-box",
          padding: "20px 16px",
          right: 5,
          top: 55,
          cursor: "default",
          borderRadius: "16px",
          zIndex: theme.zIndex.max,
        }}
      >
        <Box>
          <Typography
            sx={{
              color: theme.color.text,
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            {fieldName}
          </Typography>
          <Typography
            sx={{
              color: theme.color.text,
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            {cropName}
          </Typography>
          <Typography sx={{ color: theme.color.textSecondary }}>
            {farmerName}
          </Typography>
        </Box>
        {/* CHANGE THAT WHEN ENDPOINT IS BUILT */}

        {
          // eslint-disable-next-line no-constant-condition
          false ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                  padding: "10px",
                  justifyContent: "space-evenly",
                }}
              >
                <img src={Sun} alt="sun" />
                <Box>
                  <Typography
                    sx={{
                      color: theme.color.text,
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Δευτέρα 23 Δεκ
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.color.text,
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    14-18 Co
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.color.text,
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Ηλιοφάνεια
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: "10px",

                  height: "165px",
                  maxHeight: "165px",
                  overflowY: "auto",
                }}
              >
                <Typography
                  sx={{
                    color: theme.color.text,
                    padding: "0.5rem",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Ειδοποιήσεις
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #E5E5E5",
                    padding: "16px",
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      width: "15px",
                      height: "10px",
                      borderRadius: "100%",
                      backgroundColor: "red",
                    }}
                  />
                  <Typography
                    sx={{
                      color: theme.color.text,
                      fontWeight: "400",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    Εμφάνιση Δάκου στην κοντινή περιοχή.
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Error type={"collecting"} />
          )
        }
        <NavLink
          to={`/fields/${field.id}`}
          style={{
            color: theme.color.textSecondary,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            border: "none",
            background: "none",
            textDecoration: "none",
            fontWeight: "700",
          }}
        >
          Προβολή χωραφιού
          <KeyboardArrowRightIcon
            fontSize="large"
            sx={{ color: theme.color.primary100 }}
          />
        </NavLink>
      </Box>
    </>
  );
};

export default MarkerPopup;
