import * as Yup from "yup";

export const fieldInfoSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
    wateringMechanism: Yup.string().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
    plantationDate: Yup.date().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
    numberOfRoots: Yup.number().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
    taxonomyId: Yup.string().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
    cropId: Yup.string().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
    varietyId: Yup.string().required(
      t("SingleFieldPage.FieldInfoModal.ErrorRequired")
    ),
  });
