import { createContext, useContext, useMemo } from "react";
import useSelectedWeek from "../hooks/useSelectedWeek";

const SelectedWeekContext = createContext({
  selectedWeek: undefined,
  selectedDate: undefined,
});

const SelectedWeekProvider = ({ children, date }) => {
  const { selectedWeek } = useSelectedWeek(date);

  const value = useMemo(
    () => ({
      selectedWeek,
      selectedDate: date,
    }),
    [selectedWeek, date]
  );

  return (
    <SelectedWeekContext.Provider value={value}>
      {children}
    </SelectedWeekContext.Provider>
  );
};

const useSelectedWeekContext = () => {
  const context = useContext(SelectedWeekContext);

  if (!context) {
    throw new Error(
      "useSelectedWeekContext must be used within a SelectedWeekProvider"
    );
  }
  return useContext(SelectedWeekContext);
};

export { SelectedWeekProvider, useSelectedWeekContext };
