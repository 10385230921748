import { useEffect } from "react";
import { useFormikContext } from "formik";

const SubmitOnChange = () => {
  const { isValid, values, dirty, submitForm } = useFormikContext();

  useEffect(() => {
    if (isValid && dirty) {
      submitForm?.();
    }
  }, [isValid, values, dirty, submitForm]);

  return null;
};

export default SubmitOnChange;
