import { useTranslation } from "react-i18next";
import { useFetchUser } from "../../hooks/useFetchUser";
import UserCard from "./UserCard";
import { USER_ROLES } from "../../constants/userRoles";

const AgronomistCard = ({ agronomistId }) => {
  const { t } = useTranslation();
  const { isLoading, user } = useFetchUser(agronomistId, USER_ROLES.AGRONOMIST);

  return (
    <UserCard
      user={user}
      isLoading={isLoading}
      title={t("UserCard.Agronomist.Title")}
    />
  );
};

export default AgronomistCard;
