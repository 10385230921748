import { toast } from "react-toastify";
import ToastMessage from "../components/toast/ToastMessage";

const executeChosenToast = (
  toastFn,
  {
    message,
    title,
    position,
    delay,
    shouldAutoClose = false,
    shouldReload,
    id,
    style,
    className,
  }
) =>
  toastFn(
    <ToastMessage
      title={title}
      message={message}
      shouldReload={shouldReload}
    />,
    {
      position,
      delay,
      toastId: id,
      shouldAutoClose,
      style,
      className,
    }
  );

export const toaster = ({
  message,
  title,
  position,
  delay,
  shouldAutoClose,
  shouldReload,
  style,
  className,
  id,
}) =>
  executeChosenToast(toast, {
    message,
    title,
    position,
    delay,
    shouldAutoClose,
    shouldReload,
    id,
    style,
    className,
  });

toaster.info = ({
  message,
  title,
  position,
  delay,
  shouldAutoClose,
  shouldReload,
  id,
  style,
  className,
}) =>
  executeChosenToast(toast.info, {
    message,
    title,
    position,
    delay,
    shouldAutoClose,
    shouldReload,
    id,
    style,
    className,
  });

toaster.error = ({
  message,
  title,
  position,
  delay,
  shouldAutoClose,
  shouldReload,
  id,
  style,
  className,
}) =>
  executeChosenToast(toast.error, {
    message,
    title,
    position,
    delay,
    shouldAutoClose,
    shouldReload,
    id,
    style,
    className,
  });

toaster.success = ({
  message,
  title,
  position,
  delay,
  shouldAutoClose,
  shouldReload,
  id,
  style,
  className,
}) =>
  executeChosenToast(toast.success, {
    message,
    title,
    position,
    delay,
    shouldAutoClose,
    shouldReload,
    id,
    style,
    className,
  });

toaster.update = ({
  message,
  title,
  position,
  delay,
  shouldAutoClose,
  shouldReload,
  id,
}) =>
  toast.update(id, {
    render: () => (
      <ToastMessage
        title={title}
        message={message}
        shouldReload={shouldReload}
      />
    ),
    position,
    delay,
  });
