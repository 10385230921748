import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useSidebarsContext } from "context/SidebarsProvider";
import { paths } from "utils/paths";
import NormalText from "../../../Typography/NormalText";
import NotificationContentContainer from "./NotificationContentContainer";

const actionTypeTranslationKeys = {
  add: "Create",
  update: "Update",
  delete: "Delete",
  complete: "Complete",
};

const ActionNotificationContent = ({ notification }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, actions, field } = notification || {};

  const { closeSidebar } = useSidebarsContext();
  const action = actions[0];
  const { id: actionId, status } = action;
  const { id: fieldId, name: fieldName } = field;
  const actionTypeTranslationKey = actionTypeTranslationKeys[status];
  const actionText = t(`Inbox.Action.${actionTypeTranslationKey}`, {
    actionId,
  });

  const handleClick = () => {
    if (status === "delete") {
      return;
    }

    navigate(`${paths.field(fieldId)}?actionId=${actionId}`);
    closeSidebar();
  };

  return (
    <NotificationContentContainer
      notification={notification}
      onClick={handleClick}
    >
      <NormalText>
        <Trans
          i18nKey="Inbox.Action.Template"
          values={{
            userName: user.firstName + " " + user.lastName,
            text: actionText,
            fieldName,
          }}
          components={{
            // navlink: <NavLink to={paths.field(fieldId)} onClick={closeSidebar} />,
            navlink: <strong />,
          }}
        />
      </NormalText>
    </NotificationContentContainer>
  );
};

export default ActionNotificationContent;
