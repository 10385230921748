import { Field, useFormikContext } from "formik";
import { RadioGroup } from "../components";

const FormRadioGroupInput = ({
  classNames = {}, // { root: '', label: ''}
  direction = "row",
  labelPlacement = "end",
  id,
  name,
  onChange,
  options = [],
  styles = {}, // { root: '', label: ''}
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (e) => {
    if (typeof onChange === "function") {
      onChange(e);
      return;
    }
    const value = e.target.value;
    if (value === "true") {
      setFieldValue(name, true).then(() => {
        setFieldTouched(name, true);
      });
    } else if (value === "false") {
      setFieldValue(name, false).then(() => {
        setFieldTouched(name, true);
      });
    } else {
      setFieldValue(name, value).then(() => {
        setFieldTouched(name, true);
      });
    }
  };
  return (
    <Field
      name={name}
      as={RadioGroup}
      direction={direction}
      labelPlacement={labelPlacement}
      options={options}
      classNames={classNames}
      styles={styles}
      onChange={handleChange}
      // {...(typeof onChange === "function" ? { onChange } : {})}
      {...props}
    />
  );
};

export default FormRadioGroupInput;
