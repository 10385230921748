import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toaster } from "../utils/toaster";
import { useTranslation } from "react-i18next";
import { api } from "../api";

const EquipmentsContext = createContext({
  equipments: [],
  fetchState: {
    isLoading: false,
    error: undefined,
  },
  createNewEquipment: ({ postData, onSuccess, onError }) => {},
  updateEquipment: ({ id, postData, onSuccess, onError }) => {},
  deleteEquipment: ({ id, onSuccess, onError }) => {},
});

const EquipmentsProvider = ({ children }) => {
  const { t } = useTranslation();

  const [equipments, setEquipments] = useState([]);
  const [equipmentsFetchState, setEquipmentsFetchState] = useState({
    isLoading: false,
    error: undefined,
  });

  const fetchAllEquipments = useCallback(
    (handler) => {
      if (t) {
        setEquipmentsFetchState({ isLoading: true, error: undefined });
        api.equipments
          .fetchAll()
          .then((res) => {
            setEquipments(res.data);
            setEquipmentsFetchState({ isLoading: false, error: undefined });
            handler?.onSuccess();
          })
          .catch((error) => {
            setEquipmentsFetchState({ isLoading: false, error });
            toaster.error({
              title: t("Equipment.Error.Fetching.Title"),
              message: t("Equipment.Error.Fetching.Message"),
            });
          });
      }
    },
    [t]
  );

  useEffect(() => {
    if (typeof fetchAllEquipments === "function") {
      fetchAllEquipments();
    }
  }, [fetchAllEquipments]);

  const createNewEquipment = useCallback(
    ({ postData, onSuccess, onError }) => {
      api.equipments
        .add(postData)
        .then((res) => {
          fetchAllEquipments({
            onSuccess: () => onSuccess?.(res.data),
          });
        })
        .catch((error) => {
          onError?.(error);
        });
    },
    [fetchAllEquipments]
  );

  const updateEquipment = useCallback(
    ({ id, patchData, onSuccess, onError }) => {
      api.equipments
        .update(id, patchData)
        .then((res) => {
          fetchAllEquipments({
            onSuccess: () => onSuccess?.(res.data),
          });
        })
        .catch((error) => {
          onError?.(error);
        });
    },
    [fetchAllEquipments]
  );

  const deleteEquipment = useCallback(
    ({ id, onSuccess, onError }) => {
      setEquipmentsFetchState({ isLoading: true, error: undefined });
      api.equipments
        .delete(id)
        .then(() => {
          fetchAllEquipments({
            onSuccess: () => onSuccess?.(),
          });
        })
        .catch((error) => {
          onError?.(error);
        });
    },
    [fetchAllEquipments]
  );

  const equipmentsValue = useMemo(
    () => ({
      equipments,
      fetchState: equipmentsFetchState,
      createNewEquipment,
      updateEquipment,
      deleteEquipment,
    }),
    [
      equipments,
      equipmentsFetchState,
      createNewEquipment,
      deleteEquipment,
      updateEquipment,
    ]
  );

  return (
    <EquipmentsContext.Provider value={equipmentsValue}>
      {children}
    </EquipmentsContext.Provider>
  );
};

const useEquipmentsContext = () => {
  const context = useContext(EquipmentsContext);

  if (!context) {
    throw new Error(
      "useEquipmentsContext must be used within a EquipmentsProvider"
    );
  }
  return useContext(EquipmentsContext);
};
export { EquipmentsProvider, useEquipmentsContext };
