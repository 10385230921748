import { ACTION_STATUS } from "../../../../constants/actionStatus";
import PerformedDateSpan from "./PerformedDateSpan";
import SuggestedDateSpan from "./SuggestedDateSpan";

const DateSpan = ({ viewMode, status }) => {
  const isSuggesting = status === ACTION_STATUS.suggested;
  return isSuggesting ? (
    <SuggestedDateSpan viewMode={viewMode} status={status} />
  ) : (
    <PerformedDateSpan viewMode={viewMode} />
  );
};

export default DateSpan;
