import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { api } from "../api";
import { useSelectedWeekContext } from "../context/SelectedWeekProvider";
import { useIndicesContext } from "../context/IndicesProvider";
import { useDebouncedEffect } from "./useDebounce";

const extractIndiceType = (indice) => indice?.indice;

const extractIndiceDate = (indice) =>
  moment(indice?.productDateValid)?.format("YYYY-MM-DD");

const useFetchAllIndicesMeta2 = (fieldId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [indices, setIndices] = useState();
  const { selectedWeek: week } = useSelectedWeekContext();

  useDebouncedEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (fieldId && week) {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await api.fields.indicesMeta({
            fieldId,
            startDate: week.start.format("YYYY-MM-DD"),
            endDate: week.end.format("YYYY-MM-DD"),
            signal,
          });
          const fetchedIndices = {};
          data.forEach((indice) => {
            const indiceType = extractIndiceType(indice);
            if (!fetchedIndices[indiceType]) {
              fetchedIndices[indiceType] = { ...week.days };
              const indiceDate = moment(indice.productDateValid).format(
                "YYYY-MM-DD"
              );
              if (indiceDate in fetchedIndices[indiceType]) {
                fetchedIndices[indiceType][indiceDate] = indice;
              }
            } else {
              fetchedIndices[indiceType] = {
                ...fetchedIndices[indiceType],
                [moment(indice.productDateValid).format("YYYY-MM-DD")]: indice,
              };
            }
          });

          if (Object.keys(fetchedIndices).length === 0) {
            setIndices();
          } else {
            setIndices(fetchedIndices);
          }
          setError();
          setIsLoading(false);
        } catch (err) {
          setError(err);
          setIndices();
          setIsLoading(false);
        }
      })();
    }
    return () => {
      controller.abort();
    };
  }, [fieldId, week]);

  return { indices, isLoading, error };
};

export const useFetchInicesMonthly = (date) => {
  const { fieldId } = useParams();

  const [monthlyIndices, setMonthlyIndices] = useState();
  const [fetchState, setFetchState] = useState("idle");

  const { cachedMonthlyIndices, setCachedMonthlyIndices } = useIndicesContext();

  const availableDates = useMemo(() => {
    if (!monthlyIndices) {
      return undefined;
    }
    return Object.keys(monthlyIndices);
  }, [monthlyIndices]);

  const currentMonth = useMemo(() => moment(date).format("YYYY-MM"), [date]);

  useDebouncedEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const isFutureMonth = moment(currentMonth).isAfter(moment());
    if (isFutureMonth) {
      return;
    }
    if (fieldId && currentMonth != null) {
      (async () => {
        try {
          setFetchState("loading");
          // If indices already fetched, use them
          if (cachedMonthlyIndices?.[currentMonth]) {
            setMonthlyIndices(cachedMonthlyIndices[currentMonth]);
            setFetchState("success");
            return;
          }
          const startDate = moment(date)
            .month(currentMonth)
            .startOf("month")
            .format("YYYY-MM-DD");
          const endOfMonth = moment(date)
            .month(currentMonth)
            .endOf("month")
            .format("YYYY-MM-DD");
          const endDate = moment().isBefore(endOfMonth)
            ? moment().format("YYYY-MM-DD")
            : endOfMonth;

          const { data } = await api.fields.indicesMeta({
            fieldId,
            startDate,
            endDate,
            signal,
          });
          const fetchedIndices = {};
          data.forEach((indice) => {
            const indiceType = extractIndiceType(indice);
            const indiceDate = extractIndiceDate(indice);
            if (!fetchedIndices[indiceDate]) {
              fetchedIndices[indiceDate] = {};
            }
            if (indiceType in fetchedIndices[indiceDate]) {
              fetchedIndices[indiceDate][indiceType] = indice;
            } else {
              fetchedIndices[indiceDate] = {
                ...fetchedIndices[indiceDate],
                [indiceType]: indice,
              };
            }
          });

          setCachedMonthlyIndices((prev) => ({
            ...prev,
            [currentMonth]: fetchedIndices,
          }));
          setMonthlyIndices(fetchedIndices);
          setFetchState("success");
        } catch (err) {
          setMonthlyIndices();
          setFetchState("error");
        }
      })();
    }
    return () => {
      controller.abort();
    };
  }, [fieldId, currentMonth]);

  return { fetchState, monthlyIndices, availableDates };
};

export const useFetchIndiceImage = (indiceType, indices, date) => {
  const { fieldId } = useParams();

  const [png, setPng] = useState({ pngImage: null, boundBox: {} });
  const [cloudCover, setCloudCover] = useState();

  const [selectedIndice, setSelectedIndice] = useState();

  useEffect(() => {
    if (date && indices) {
      const indicesMonth = Object.keys(indices)?.[0];
      if (!indicesMonth) {
        return;
      }
      if (moment(date).isSame(indicesMonth, "month")) {
        setSelectedIndice(indices[date]);
      }
    }
  }, [date, indices]);

  useEffect(() => {
    if (fieldId && indiceType && selectedIndice) {
      const indice = selectedIndice?.[indiceType];
      if (indice) {
        setCloudCover(indice?.cloudCover);
        api.fields.indicePng(
          indiceType,
          fieldId,
          indice.filename,
          "png",
          (url) => setPng({ pngImage: url, boundBox: indice.boundbox })
        );
      } else {
        setCloudCover();
        setPng({ pngImage: null, boundBox: {} });
      }
    } else {
      setCloudCover();
      setPng({ pngImage: null, boundBox: {} });
    }
  }, [indiceType, selectedIndice, fieldId]);

  return { png, cloudCover };
};
export default useFetchAllIndicesMeta2;
