import { IconButton } from "@mui/material";
import React from "react";
import search from "../../../assets/icons/sidebar/search.icon.svg";

const SearchIcon = () => {
  return (
    <IconButton
      sx={{
        padding: 0,
        cursor: "pointer",
      }}
    >
      <img width="20px" alt="SearchIcon" src={search} />
    </IconButton>
  );
};

export default SearchIcon;
