import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getBiocyclePlanForWeatherAnalyticData = (id) => {
  return axiosInstance(`${URLS.externalRequests}/seasons/${id}/analytics/`);
};

const getBiocyclePlanForCarbonFootprintAndWaterConsumption = (fieldId) => {
  return axiosInstance(`${URLS.externalRequests}/seasons/`, {
    params: {
      field_id: fieldId,
    },
  });
};

export const biocycleApi = {
  getBiocyclePlanForWeatherAnalyticData: getBiocyclePlanForWeatherAnalyticData,
  getBiocyclePlanForCarbonFootprintAndWaterConsumption:
    getBiocyclePlanForCarbonFootprintAndWaterConsumption,
};
