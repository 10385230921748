import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ACTION_STATUS } from "constants/actionStatus";
import { FormRadioGroupInput, FormSelectInput } from "form";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import Title from "../../../Typography/Title";
import SmallText from "../../../Typography/SmallText";
import DateSpan from "../date-span/DateSpan";
import QuantityInput from "../quantity/QuantityInput";
import DetailSection from "../DetailSection";
import { actionFormValue, actionInputName } from "../../utils/inputName";
import {
  useDrugFormEffects,
  useDrugQuantityUnitOptions,
  useFilteredDrugSubstances,
  useSubstanceCompositionOptions,
  useSuggestedDrugQuantity,
} from "./useDrugFormUtils";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const DrugSuggestion = ({ viewMode, isProposedValue, isFullWidth }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { values } = useFormikContext();
  const status = ACTION_STATUS.suggested;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { filteredSubstances, bioSelections } = useFilteredDrugSubstances();
  const { suggestedQuantity } = useSuggestedDrugQuantity(filteredSubstances);
  const { selectedSubstanceName } = useDrugFormEffects(isProposedValue);
  const { quantityOptions } = useDrugQuantityUnitOptions(
    filteredSubstances,
    selectedSubstanceName
  );
  const {
    activeSubstanceOptions,
    activeSubstancesGroups,
    compositionOptions,
    brandNameOptions,
    handleCompositionChange,
  } = useSubstanceCompositionOptions(
    filteredSubstances,
    selectedSubstanceName,
    isProposedValue
  );

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Suggested")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection
          title={t("ActionModal.ActiveSubstance")}
          isRequired={!isViewMode}
        >
          <div className={!isViewMode && "action-details-column-multiple"}>
            <FormRadioGroupInput
              name={actionInputName("drugAction", "bio", isProposedValue)}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              options={bioSelections.map(
                ({ value, label: { nameEn, nameGr } }) => ({
                  value,
                  label: isEn ? nameEn : nameGr,
                })
              )}
            />
            <FormSelectInput
              name={actionInputName(
                "drugAction",
                "activeSubstance",
                isProposedValue
              )}
              label={isViewMode ? t("ActionModal.ActiveSubstance") : undefined}
              placeholder={t("ActionModal.ActiveSubstance")}
              shouldHaveErrorBehaviorAfterSubmit
              isDisabled={values?.drugAction?.bio == null}
              isViewMode={isViewMode}
              groups={activeSubstancesGroups}
              options={activeSubstanceOptions}
            />
            <FormSelectInput
              name="drugAction.composition"
              value={JSON.stringify({
                composition:
                  actionFormValue(
                    values,
                    "drugAction",
                    "composition",
                    isProposedValue
                  ) + "",
                compositionUnits: actionFormValue(
                  values,
                  "drugAction",
                  "compositionUnits",
                  isProposedValue
                ),
              })}
              placeholder={t("ActionModal.Composition")}
              label={isViewMode ? t("ActionModal.Composition") : undefined}
              shouldHaveErrorBehaviorAfterSubmit
              isDisabled={!values?.drugAction?.activeSubstance}
              isViewMode={isViewMode}
              onChange={handleCompositionChange}
              options={compositionOptions}
            />
            <FormSelectInput
              name={actionInputName("drugAction", "brandName", isProposedValue)}
              label={isViewMode ? t("ActionModal.BrandName") : undefined}
              placeholder={t("ActionModal.BrandName")}
              shouldHaveErrorBehaviorAfterSubmit
              isDisabled={!values?.drugAction?.composition}
              isViewMode={isViewMode}
              options={brandNameOptions}
            />
          </div>
        </DetailSection>
        <DetailSection
          title={t("ActionModal.Quantity")}
          isRequired={!isViewMode}
        >
          <QuantityInput
            isViewMode={isViewMode}
            isProposedValue={isProposedValue}
            actionBaseName="drugAction"
            quantityOptions={quantityOptions}
          />
          {!isViewMode && suggestedQuantity && (
            <SmallText
              text={t("ActionModal.SuggestedQuantity", {
                quantity: suggestedQuantity,
              })}
              style={{
                color: "#818181",
                paddingLeft: "8px",
                marginTop: "4px",
                fontStyle: "italic",
              }}
            />
          )}
        </DetailSection>
        <ActionModalDescriptionSection
          actionBaseName="drugAction"
          isViewMode={isViewMode}
          isProposed={isProposedValue}
        />
      </div>
    </div>
  );
};
export default DrugSuggestion;
