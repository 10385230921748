import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

const PriorityIcon = ({
  priority,
  size = "16px",
  stroke = "#fff",
  strokeWidth = "0.3",
}) => {
  switch (priority?.toString()) {
    case "0":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            transform: "rotate(180deg)",
          }}
        >
          <Arrow
            stroke={stroke}
            fill="blue"
            strokeWidth={strokeWidth}
            width={size}
            height={size}
          />
        </div>
      );
    case "0.25":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            transform: "rotate(180deg)",
          }}
        >
          <Arrow
            stroke={stroke}
            fill="cyan"
            strokeWidth={strokeWidth}
            width={size}
            height={size}
          />
        </div>
      );
    case "0.5":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Medium
            stroke={stroke}
            fill="#d1c80a"
            strokeWidth={strokeWidth}
            width={size}
            height={size}
          />
        </div>
      );
    case "0.75":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Arrow
            stroke={stroke}
            fill="orange"
            strokeWidth={strokeWidth}
            width={size}
            height={size}
          />
        </div>
      );
    case "1":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Arrow
            stroke={stroke}
            fill="red"
            strokeWidth={strokeWidth}
            width={size}
            height={size}
          />
        </div>
      );
    default:
      return <></>;
  }
};

export default PriorityIcon;
