import { styled, TableCell } from "@mui/material";

const CellStyled = styled(TableCell)(({ theme }) => ({
  width: "33%",
  borderLeft: `1px solid ${theme.palette.divider}`,
  padding: "2px",
  paddingRight: "4px",
  paddingLeft: "6px",
}));

export default CellStyled;
