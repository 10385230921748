import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../LoadingButton/LoadingButton";

const EditActionModalHeader = ({ onCancel, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className="action-modal-header-row space-between">
      <div className="action-modal-header-title">
        {t("ActionModal.Mode.EditAction")}
      </div>
      <div className="action-modal-header-btn-group">
        <LoadingButton onClick={onCancel} className="action-action-button">
          {t("Global.Cancel")}
        </LoadingButton>
        <LoadingButton
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={onSubmit}
          buttonType="filled"
          className="action-action-button"
        >
          {t("ActionModal.SubmitButton")}
        </LoadingButton>
      </div>
    </div>
  );
};

export default EditActionModalHeader;
