import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import Loader from "../Loader/Loader";

const FormControlStyled = styled(FormControl)(() => ({
  minWidth: "200px",
}));

const SelectStyled = styled(Select)(() => ({
  borderRadius: "1rem",
  width: "100%",
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "1rem",
  },
}));

const MenuItemStyled = styled(MenuItem)(() => ({
  fontSize: "14px",
}));

const InputLabelStyled = styled(InputLabel)(() => ({
  position: "inherit",
  marginBottom: "8px",
  marginLeft: "-8px",
  fontSize: "12px",
  fontWeight: 400,
}));

const SelectorNew = ({
  id,
  style = {},
  options,
  onChange,
  isError,
  helperText,
  name,
  label,
  renderValue,
  defaultValue = "",
  isDisabled,
  groups,
  size = "small",
  value,
  labelStyle,
  isLoading,
  itemRenderer,
  ...muiProps
}) => {
  const inputId = (id || name) + Math.floor(Math.random() * 1000).toString();

  const {
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    ...selectStyle
  } = style;
  const formControlStyle = {
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
  };

  return (
    <FormControlStyled size={size} sx={{ ...formControlStyle }}>
      {label && (
        <InputLabelStyled id={inputId} shrink={false} style={labelStyle}>
          {label}
        </InputLabelStyled>
      )}
      <SelectStyled
        renderValue={renderValue}
        startAdornment={isLoading && <Loader />}
        value={value}
        disabled={isDisabled}
        labelId={inputId}
        defaultValue={defaultValue}
        onChange={onChange}
        name={name}
        error={Boolean(isError)}
        size="small"
        variant="outlined"
        sx={{ ...selectStyle }}
        {...muiProps}
      >
        {groups?.reduce((acc, group) => {
          acc.push(
            <ListSubheader key={group.value} style={{ textAlign: "center" }}>
              {group.title}
            </ListSubheader>
          );
          acc.push(
            ...options
              ?.filter((option) => option.group === group.value)
              ?.map((option) => {
                if (typeof option === "string") {
                  return (
                    <MenuItemStyled key={option} value={option}>
                      {option}
                    </MenuItemStyled>
                  );
                } else {
                  return itemRenderer ? (
                    itemRenderer({ option, key: option.id })
                  ) : (
                    <MenuItemStyled
                      key={option.value}
                      value={option?.value}
                      disabled={option.disabled}
                    >
                      {option?.label}
                    </MenuItemStyled>
                  );
                }
              })
          );
          return acc;
        }, [])}

        {!Array.isArray(groups) &&
          Array.isArray(options) &&
          options?.map((option) => {
            if (typeof option === "string") {
              return (
                <MenuItemStyled key={option} value={option}>
                  {option}
                </MenuItemStyled>
              );
            } else {
              return itemRenderer ? (
                itemRenderer({ option, key: option.id })
              ) : (
                <MenuItemStyled
                  key={option.value}
                  value={option?.value}
                  disabled={option.disabled}
                >
                  {option?.label}
                </MenuItemStyled>
              );
            }
          })}
      </SelectStyled>
    </FormControlStyled>
  );
};

export default SelectorNew;
