import axios from "axios";

const token = JSON.parse(localStorage.getItem("access"));

const axiosInstance = axios.create({
  headers: token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {},
});

export default axiosInstance;
