import { useField, useFormikContext } from "formik";
import DurationInput from "../components/duration-input/DurationInput.jsx";
import { useCallback } from "react";

const FormDurationInput = ({
  name,
  onChange,
  style,
  shouldHaveErrorBehaviorAfterSubmit,
  helperText,
  ...props
}) => {
  const [, meta] = useField(name);
  const { submitCount, values, setFieldValue, setFieldTouched } =
    useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && meta.error
    : meta.error;

  const finalHelperText = (shouldDisplayError && meta.error) || helperText;

  const handleChange = useCallback(
    (duration) => {
      if (typeof onChange === "function") {
        onChange(duration);
      } else {
        setFieldValue(name, duration).then(() => {
          setFieldTouched(name, true);
        });
      }
    },
    [name, onChange, setFieldTouched, setFieldValue]
  );

  return (
    <DurationInput
      name={name}
      value={values[name]}
      helperText={finalHelperText}
      isError={shouldDisplayError}
      onChange={handleChange}
      style={{
        ...style,
        fontSize: "14px",
      }}
      {...props}
    />
  );
};
export default FormDurationInput;
