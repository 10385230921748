import { useTranslation } from "react-i18next";
import SelectNew from "./Select";
import { multiSelectPropTypes } from "./selectPropTypes";

const MultiSelect = ({
  allSelectedLabel,
  renderValue,
  value,
  onChange,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const handleRenderValue = () => {
    if (typeof renderValue === "function") {
      return renderValue(value);
    }
    return t("Components.MultiSelect.DefaultRenderValue", {
      count: value?.length || 0,
    });
  };

  const handleChange = (e) => {
    onChange?.(e.target?.value);
  };

  return (
    <SelectNew
      {...props}
      value={value}
      onChange={handleChange}
      isMultiple
      placeholder={!value?.length ? placeholder : undefined}
      renderValue={handleRenderValue}
      itemRenderer={({ option }) => {
        const isChecked = value.includes(option.value);
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <input
              type="checkbox"
              checked={isChecked}
              style={{ cursor: "pointer" }}
              onChange={() => {}}
            />
            <span>{option.label}</span>
          </div>
        );
      }}
    />
  );
};

export default MultiSelect;

MultiSelect.propTypes = multiSelectPropTypes;
