import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { setFromArrayOfObjects } from "../../../../utils/filterObject";
import { sortObjectsByProperty } from "../../../../utils/sortUtils";
import { useAgrochemicalsContext } from "../../../../context/AgrochemichalsProvider";
import { actionFormValue } from "../../utils/inputName";

const BIO_SELECTIONS = [
  {
    value: false,
    label: {
      nameGr: "Συμβατική",
      nameEn: "Conventional",
    },
  },
  {
    value: true,
    label: {
      nameGr: "Βιολογική",
      nameEn: "Biological",
    },
  },
];

const getSubstanceName = (substance, isEn) =>
  isEn
    ? substance?.agrochemicalRead?.name?.nameEn
    : substance?.agrochemicalRead?.name?.nameGr;

const groupSubstancesOnCause = (activeSubstances, enemyId) => {
  if (!activeSubstances) {
    return {};
  }
  const reducedActiveSubstances = {};
  Object.entries(activeSubstances).forEach(([substanceKey, substances]) => {
    let group = "notRecommended";
    reducedActiveSubstances[substanceKey] = substances?.map((substance) => {
      const { causeId } = substance;
      if (Array.isArray(causeId)) {
        if (causeId.includes(enemyId)) {
          group = "recommended";
          return { ...substance, group };
        } else {
          return { ...substance, group };
        }
      }
      return null;
    });

    reducedActiveSubstances[substanceKey] = {
      value: reducedActiveSubstances[substanceKey],
      group,
    };
  });

  return { ...reducedActiveSubstances };
};

export const useFilteredDrugSubstances = () => {
  const { values } = useFormikContext();
  const { activeSubstances } = useAgrochemicalsContext();

  const filteredSubstances = useMemo(
    () => groupSubstancesOnCause(activeSubstances, values?.caused?.enemyId),
    [activeSubstances, values?.caused?.enemyId]
  );

  return { filteredSubstances, bioSelections: BIO_SELECTIONS };
};

export const useDrugFormEffects = (isProposed) => {
  const { values, initialValues, setFieldValue, setFieldTouched } =
    useFormikContext();
  const [selectedSubstanceName, setSelectedSubstanceName] = useState();

  useEffect(() => {
    if (
      setFieldValue &&
      values?.drugAction?.bio !== initialValues?.drugAction?.bio
    ) {
      setFieldValue("drugAction.activeSubstance", null).then(() => {
        setFieldTouched("drugAction.activeSubstance", true);
      });
    }
    setSelectedSubstanceName(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.drugAction?.bio, initialValues?.drugAction?.bio]);

  useEffect(() => {
    if (
      setFieldValue &&
      values?.drugAction?.activeSubstance !==
        initialValues?.drugAction?.activeSubstance
    ) {
      setFieldValue("drugAction.composition", null).then(() =>
        setFieldTouched("drugAction.composition", true)
      );
      setFieldValue("drugAction.compositionUnits", null).then(() =>
        setFieldTouched("drugAction.compositionUnits", true)
      );
      setFieldValue("drugAction.brandName", null).then(() =>
        setFieldTouched("drugAction.brandName", true)
      );
    }
    setSelectedSubstanceName(
      actionFormValue(values, "drugAction", "activeSubstance", isProposed)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProposed,
    values?.drugAction?.activeSubstance,
    initialValues?.drugAction?.activeSubstance,
  ]);

  useEffect(() => {
    if (
      setFieldValue &&
      values?.drugAction?.brandName !== initialValues?.drugAction?.brandName
    ) {
      setFieldValue("drugAction.quantity", "").then(() => {
        setFieldTouched("drugAction.quantity", true);
      });
      setFieldValue("drugAction.quantityUnits", "").then(() => {
        setFieldTouched("drugAction.quantityUnits", true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.drugAction?.brandName, initialValues?.drugAction?.brandName]);

  return { selectedSubstanceName };
};

export const useSuggestedDrugQuantity = (filteredSubstances) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const [suggestedQuantity, setSuggestedQuantity] = useState();

  useEffect(() => {
    const substance = values?.drugAction?.activeSubstance;
    const brandName = values?.drugAction?.brandName;
    const composition = values?.drugAction?.composition;
    if (filteredSubstances && substance && brandName && composition) {
      const isNoRecommended =
        filteredSubstances[substance]?.group === "notRecommended";
      if (isNoRecommended) {
        return;
      }
      const variations = filteredSubstances[substance]?.value?.filter(
        (s) =>
          s.agrochemicalRead.brandName === brandName &&
          s.agrochemicalRead.composition == composition
      );

      const quantity = variations?.[0]?.quantity;
      const quantityUnits = variations?.[0]?.quantityUnits;
      if (quantity && quantityUnits) {
        setSuggestedQuantity(
          quantity + " " + t(`OperationUnits.${quantityUnits}`)
        );
      } else {
        setSuggestedQuantity("");
      }
    }
  }, [
    filteredSubstances,
    values?.drugAction?.activeSubstance,
    values?.drugAction?.brandName,
    values?.drugAction?.composition,
    t,
  ]);

  return { suggestedQuantity };
};

export const useDrugQuantityUnitOptions = (
  filteredSubstances,
  substanceName
) => {
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      filteredSubstances?.[substanceName]?.value && t
        ? setFromArrayOfObjects(
            filteredSubstances[substanceName]?.value,
            "quantityUnits"
          )?.map(({ quantityUnits }) => ({
            label: t(`OperationUnits.${quantityUnits}`),
            value: quantityUnits,
          }))
        : [],
    [filteredSubstances, substanceName, t]
  );

  return { quantityOptions: options };
};

export const useSubstanceCompositionOptions = (
  filteredSubstances,
  substanceName,
  isProposed
) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const selectedSubstance = filteredSubstances[substanceName];

  const handleCompositionChange = (e) => {
    const { composition, compositionUnits } = JSON.parse(e.target.value);

    setFieldValue("drugAction.composition", composition).then(() => {
      setFieldTouched("drugAction.composition", true);
    });
    setFieldValue("drugAction.compositionUnits", compositionUnits).then(() => {
      setFieldTouched("drugAction.compositionUnits", true);
    });
    setFieldValue("drugAction.brandName", "").then(() => {
      setFieldTouched("drugAction.brandName", true);
    });
  };

  const shouldHaveRecommended = Object.values(filteredSubstances).filter(
    ({ group }) => group === "recommended"
  ).length;

  const activeSubstancesGroups = shouldHaveRecommended
    ? [
        {
          value: "recommended",
          title: t("ActionModal.RecommendedActiveSubstances"),
        },
        {
          value: "notRecommended",
          title: t("ActionModal.NotRecommendedActiveSubstances"),
        },
      ]
    : [
        {
          value: "notRecommended",
          title: t("ActionModal.NoRecommendedActiveSubstanceFound"),
        },
      ];

  const [activeSubstanceOptions, setActiveSubstanceOptions] = useState([]);
  useEffect(() => {
    setActiveSubstanceOptions(
      filteredSubstances
        ? Object.entries(filteredSubstances)
            ?.map(
              ([
                substanceName,
                {
                  value: [firstSubstanceInstance],
                },
              ]) => {
                const name = getSubstanceName(firstSubstanceInstance, isEn);
                return {
                  label: name || "--None--",
                  value: substanceName,
                  group: firstSubstanceInstance?.group,
                };
              }
            )
            .sort((a, b) => sortObjectsByProperty(a, b, "label"))
        : []
    );
  }, [filteredSubstances, isEn]);

  const [compositionOptions, setCompositionOptions] = useState([]);
  useEffect(() => {
    const options = setFromArrayOfObjects(
      selectedSubstance?.value.map(({ agrochemicalRead }) => agrochemicalRead),
      "composition"
    )?.map(({ composition, compositionUnits }) => {
      return {
        label: composition + " " + t(`OperationUnits.${compositionUnits}`),
        value: JSON.stringify({
          composition: composition + "",
          compositionUnits,
        }),
      };
    });
    setCompositionOptions(options);
  }, [selectedSubstance, t]);

  const [brandNameOptions, setBrandNameOptions] = useState([]);
  useEffect(() => {
    const options = setFromArrayOfObjects(
      selectedSubstance?.value.map(({ agrochemicalRead }) => agrochemicalRead),
      "brandName"
    )
      ?.filter((agrochemicalRead) => {
        return (
          agrochemicalRead?.composition + "" ===
            actionFormValue(values, "drugAction", "composition", isProposed) +
              "" &&
          agrochemicalRead?.compositionUnits ===
            actionFormValue(
              values,
              "drugAction",
              "compositionUnits",
              isProposed
            )
        );
      })
      .map((agrochemicalRead) => ({
        label: agrochemicalRead?.brandName,
        value: agrochemicalRead?.brandName,
      }))
      .sort((a, b) => sortObjectsByProperty(a, b, "label"));
    setBrandNameOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProposed,
    selectedSubstance,
    values?.drugAction?.composition,
    values?.drugAction?.compositionUnits,
  ]);

  return {
    activeSubstanceOptions,
    compositionOptions,
    brandNameOptions,
    activeSubstancesGroups,
    handleCompositionChange,
  };
};
