import { Field, useField, useFormikContext } from "formik";
import { DateField } from "../components";
import moment from "moment";

const FormDateInput = ({
  name,
  onChange,
  shouldHaveErrorBehaviorAfterSubmit,
  helperText,
  inputFormat,
  locale,
  ...props
}) => {
  const [, meta] = useField(name);
  const metaError = name ? meta.error : null;
  const { submitCount, setFieldValue, setTouched } = useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && metaError
    : metaError;

  const handleDateChange = (momentDate) => {
    if (typeof onChange === "function") {
      onChange(momentDate);
    } else {
      setFieldValue(name, moment(momentDate).toISOString());
      // .then(() => {
      //   setTouched(name, true);
      // });
    }
  };
  const finalHelperText = (shouldDisplayError && metaError) || helperText;

  return (
    <Field
      name={name}
      isError={shouldDisplayError}
      as={DateField}
      helperText={finalHelperText}
      inputFormat={inputFormat}
      locale={locale}
      onChange={handleDateChange}
      {...props}
    />
  );
};

export default FormDateInput;
