import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getAllActionChoices = () =>
  axiosInstance.get(`${URLS.externalRequests}/action-choices/`);

const getCropVarieties = () => {
  return axiosInstance.get(`${URLS.cropVarieties}/`);
};

const getWateringMethods = () => {
  return axiosInstance.get(`${URLS.wateringMechanism}/`);
};

const getAllBiocyclePhasesStresses = (fieldId, options = {}) =>
  axiosInstance.get(
    `${URLS.externalRequests}/agro/crops/get-biocyclephases-stresses/?field_id=${fieldId}`,
    options
  );

const getStressTypes = () => {
  return axiosInstance.get(
    `${URLS.externalRequests}/agro/stresses/get-stress-types/`,
    {}
  );
};

export const generalAgroApi = {
  actionChoices: getAllActionChoices,
  biocyclePhasesStresses: getAllBiocyclePhasesStresses,
  cropsInformation: getCropVarieties,
  stressTypes: getStressTypes,
  wateringMethods: getWateringMethods,
};
