import { useFormikContext } from "formik";
import { ACTION_STATUS } from "../../../../constants/actionStatus";
import OtherActionPerformed from "./OtherActionPerformed";
import OtherActionSuggestion from "./OtherActionSuggestion";
import { ACTION_VIEW_MODE } from "../../../../constants/actionViewModes";

const OtherActionDetails = ({ viewMode, status }) => {
  const { values } = useFormikContext();
  const isPerformedWithSuggested =
    Boolean(values?.suggestedActionId) ||
    (values.status === ACTION_STATUS.performed &&
      values.initialStatus !== ACTION_STATUS.performed);

  if (
    viewMode === ACTION_VIEW_MODE.view ||
    viewMode === ACTION_VIEW_MODE.edit
  ) {
    if (status !== ACTION_STATUS.performed) {
      return <OtherActionSuggestion viewMode={viewMode} isFullWidth />;
    } else if (isPerformedWithSuggested) {
      return (
        <div className="action-details-both-box-wrapper">
          <OtherActionSuggestion
            viewMode={ACTION_VIEW_MODE.view}
            isProposedValue
          />
          <OtherActionPerformed viewMode={viewMode} />
        </div>
      );
    } else {
      return <OtherActionPerformed viewMode={viewMode} isFullWidth />;
    }
  } else if (viewMode === ACTION_VIEW_MODE.new) {
    if (status !== ACTION_STATUS.performed) {
      return <OtherActionSuggestion viewMode={viewMode} isFullWidth />;
    } else {
      return <OtherActionPerformed viewMode={viewMode} isFullWidth />;
    }
  } else {
    return <></>;
  }
};

export default OtherActionDetails;
