import { useEffect, useState } from "react";
import { USER_ROLES } from "../constants/userRoles";
import { api } from "../api";

export const useFetchUser = (id, role = USER_ROLES.FARMER) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      api.users
        .fetchOne(id, role)
        .then((res) => {
          setUser(res.data.user);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [id, role]);

  return { isLoading, user };
};
