import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18next";
import { setupAxiosInterceptors } from "./utils/axiosInterceptors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CamvioLoader } from "./components";

// Setup axios interceptors
setupAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense
    fallback={<CamvioLoader style={{ width: "100vh", height: "100vh" }} />}
  >
    <ToastContainer
      hideProgressBar
      limit={3}
      style={{ width: "380px" }}
      toastStyle={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)" }}
    />
    <App />
  </Suspense>
);
