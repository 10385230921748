import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, styled, tooltipClasses } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubTitle from "../../../Typography/SubTitle";
import NormalText from "../../../Typography/NormalText";
import SmallText from "../../../Typography/SmallText";
import InfoIcon from "../../../../assets/icons/info-outlined.svg";

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
  },
});

const FertilizeLimitsTooltip = ({ limits, onClose }) => {
  const { t } = useTranslation();
  const newRef = useRef(null);

  const handleOutsideClick = useCallback(
    (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        onClose?.();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div
      ref={newRef}
      style={{
        width: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "8px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SubTitle text={t("ActionModal.FertilizeLimits")} />
        <CloseIcon sx={{ cursor: "pointer", fill: "#000" }} onClick={onClose} />
      </div>

      {limits?.map(({ name, symbol, description }) => (
        <Grid container key={symbol}>
          <Grid item xs={2.5}>
            <NormalText
              text={name}
              style={{ fontWeight: 500, textAlign: "start" }}
            />
          </Grid>
          <Grid item xs>
            <SmallText text={description} />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const FertilizeLimits = ({ limits }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const [showLimits, setShowLimits] = useState(false);

  const finalLimits = useMemo(() => {
    const { legalLimitB, legalLimitN, legalLimitP, legalLimitK } = limits || {};

    const limitsList = [
      ...(legalLimitB?.nameEn
        ? [
            {
              name: isEn ? legalLimitB.nameEn : legalLimitB.nameGr,
              symbol: legalLimitB.symbol,
              description: isEn
                ? legalLimitB.descriptionEn
                : legalLimitB.descriptionGr,
            },
          ]
        : []),
      ...(legalLimitN?.nameEn
        ? [
            {
              name: isEn ? legalLimitN.nameEn : legalLimitN.nameGr,
              symbol: legalLimitN.symbol,
              description: isEn
                ? legalLimitN.descriptionEn
                : legalLimitN.descriptionGr,
            },
          ]
        : []),
      ...(legalLimitP?.nameEn
        ? [
            {
              name: isEn ? legalLimitP.nameEn : legalLimitP.nameGr,
              symbol: legalLimitP.symbol,
              description: isEn
                ? legalLimitP.descriptionEn
                : legalLimitP.descriptionGr,
            },
          ]
        : []),
      ...(legalLimitK?.nameEn
        ? [
            {
              name: isEn ? legalLimitK.nameEn : legalLimitK.nameGr,
              symbol: legalLimitK.symbol,
              description: isEn
                ? legalLimitK.descriptionEn
                : legalLimitK.descriptionGr,
            },
          ]
        : []),
    ];

    return limitsList;
  }, [isEn, limits]);

  return finalLimits.length ? (
    <TooltipStyled
      open={showLimits}
      title={
        <FertilizeLimitsTooltip
          limits={finalLimits}
          onClose={() => setShowLimits(false)}
        />
      }
      placement="right-start"
    >
      <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
        <img alt="info" src={InfoIcon} />
        <span onClick={() => setShowLimits((prev) => !prev)}>
          <NormalText
            text={t("ActionModal.FertilizeLimits")}
            style={{
              marginTop: "8px",
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          />
        </span>
      </div>
    </TooltipStyled>
  ) : (
    <></>
  );
};

export default FertilizeLimits;
