import PropTypes from "prop-types";

const optionsType = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      group: PropTypes.string,
    }),
  ])
);

const groupsType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    title: PropTypes.string,
  })
);

export const selectPropTypes = {
  id: PropTypes.string,
  options: optionsType,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  renderValue: PropTypes.func,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  isViewMode: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isMultiple: PropTypes.bool,
  placeholder: PropTypes.string,
  groups: groupsType,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
  ]),
  labelStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  itemRenderer: PropTypes.func,
  style: PropTypes.object,
};

export const multiSelectPropTypes = {
  ...selectPropTypes,
  allSelectedLabel: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};
