import { useCallback, useEffect, useState } from "react";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Skeleton } from "@mui/material";

const FieldMapContainer = ({
  children,
  center,
  zoom,
  LatLngCoords,
  hasSkeleton,
  disableDragging,
  disableScrollZoom,
  disableZoomControl = false,
  setShowSlider,
}) => {
  const satelliteMap = "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
  const [map, setMap] = useState();
  const [zoomControl, setZoomControl] = useState();

  // useEffect(() => {
  //   if (mapRef.current && LatLngCoords) {
  //     const bounds = new L.latLngBounds(LatLngCoords);
  //     mapRef.current.fitBounds(bounds, { padding: [5, 5] });
  //   }
  // }, [LatLngCoords, mapRef.current]);

  useEffect(() => {
    if (map) {
      if (zoomControl) {
        L.map().removeControl(zoomControl);
      }
      if (!disableZoomControl) {
        const newZoomControl = L.control.zoom({
          position: "bottomright",
        });
        newZoomControl.addTo(map);
        setZoomControl(newZoomControl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableScrollZoom, disableZoomControl, map]);

  const handleReady = useCallback((e) => {
    setMap(e.target);
  }, []);

  return true ? (
    <Map
      // ref={mapRef}
      whenReady={handleReady}
      center={center}
      style={{
        height: "100%",
        width: "100%",
      }}
      scrollWheelZoom={!Boolean(disableScrollZoom)}
      zoomControl={false}
      // zoom={zoom}
      dragging={!Boolean(disableDragging)}
      // zoomAnimation={false}
      // attributionControl={false}
      // fadeAnimation={false}
      // touchZoom={false}
      // doubleClickZoom={false}
    >
      <TileLayer
        url={satelliteMap}
        maxZoom={20}
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
        keepBuffer={10}
      />
      {children}
    </Map>
  ) : (
    <Skeleton
      sx={{ padding: "0px", margin: "0px", transform: "none" }}
      width="100%"
      height="100%"
    />
  );
};

export default FieldMapContainer;
