const INDICES = {
  ndvi: "ndvi",
  ndwi: "ndwi",
  wdrvi: "wdrvi",
  evi: "evi",
  ccci: "ccci",
  lai: "lai",
  ndmi: "ndmi",
};

export default INDICES;
