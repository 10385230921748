import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useAgrochemicalsContext } from "../../../../context/AgrochemichalsProvider";
import { ACTION_STATUS } from "../../../../constants/actionStatus";
import NoContentFound from "../../../NoContentFound/NoContentFound";
import Loader from "../../../Loader/Loader";
import DrugPerformed from "./DrugPerformed";
import DrugSuggestion from "./DrugSuggestion";
import { ACTION_VIEW_MODE } from "../../../../constants/actionViewModes";

const DrugDetails = ({ viewMode, status }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const isPerformedWithSuggested =
    Boolean(values?.suggestedActionId) ||
    (values.status === ACTION_STATUS.performed &&
      values.initialStatus !== ACTION_STATUS.performed);

  const { isLoading, error } = useAgrochemicalsContext();

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return (
      <NoContentFound
        textStyle={{ fontSize: "16px" }}
        text={t("ActionModal.Error.FetchingData")}
      />
    );
  }
  if (
    viewMode === ACTION_VIEW_MODE.view ||
    viewMode === ACTION_VIEW_MODE.edit
  ) {
    if (status !== ACTION_STATUS.performed) {
      return <DrugSuggestion viewMode={viewMode} isFullWidth />;
    } else if (isPerformedWithSuggested) {
      return (
        <div className="action-details-both-box-wrapper">
          <DrugSuggestion viewMode={ACTION_VIEW_MODE.view} isProposedValue />
          <DrugPerformed viewMode={viewMode} />
        </div>
      );
    } else {
      return <DrugPerformed viewMode={viewMode} isFullWidth />;
    }
  } else if (viewMode === ACTION_VIEW_MODE.new) {
    if (status !== ACTION_STATUS.performed) {
      return <DrugSuggestion viewMode={viewMode} isFullWidth />;
    } else {
      return <DrugPerformed viewMode={viewMode} isFullWidth />;
    }
  } else {
    return <></>;
  }
};

export default DrugDetails;
