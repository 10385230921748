import BasicAccordion from "../../Accordion/Accordion";

const DetailSection = ({ children, title, isRequired }) => {
  const finalTitle = isRequired ? title + " *" : title;
  const titleStyle = {
    fontSize: "16px",
    fontWeight: 500,
    color: "#242424",
  };

  return (
    <BasicAccordion
      expandPosition="start"
      title={finalTitle}
      isInitiallyOpen
      titleStyle={titleStyle}
      wrapperStyle={{
        width: "100%",
        boxShadow: "none",
        margin: 0,
      }}
      summaryStyle={{ padding: 0 }}
      detailsStyle={{ padding: 0, paddingBottom: "16px" }}
    >
      {children}
    </BasicAccordion>
  );
};

export default DetailSection;
