import * as Yup from "yup";
import { validateAFM } from "../../utils/greekVatValidation";

const noSpaceRegExp = /^\S+$/;

Yup.addMethod(Yup.string, "vatGreek", function (message) {
  return this.test("test-vat-greek", message, function (value) {
    const { path, createError } = this;

    if (validateAFM(value)) {
      return true;
    } else {
      return createError({ path, message });
    }
  });
});

export const yupPassword = (t) =>
  Yup.string()
    .min(10, t("Register.PasswordLengthError", { min: 10 }))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_\-~+=.,?]).*$/,
      t("Register.PasswordErrorCharacters")
    )
    .matches(noSpaceRegExp, t("Register.ErrorNoSpaces"))
    .required(t("Register.PasswordError"));

export const yupRepeatPassword = (t) =>
  Yup.string()
    .oneOf([Yup.ref("password"), null], t("Register.RepeatPasswordError"))
    .required(t("Register.RepeatPasswordError"));

export const yupTaxNumber = (t) =>
  Yup.string().vatGreek(t("Register.ErrorVat")).nullable();
