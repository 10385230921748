import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getNotificationSettings = () => {
  return axiosInstance.get(`${URLS.notifications}/`);
};

const updateNotificationSettings = (settings) => {
  return axiosInstance.post(`${URLS.notifications}/`, settings);
};

const getUserInterests = () => {
  return axiosInstance.get(`${URLS.users}/profile/interests/`);
};

const postUserInterests = (interests) => {
  return axiosInstance.post(`${URLS.users}/profile/interests/`, interests);
};

const updateUserInterests = (interests) => {
  return axiosInstance.patch(`${URLS.users}/profile/interests/`, interests);
};

export const settingsApi = {
  notifications: {
    fetch: getNotificationSettings,
    update: updateNotificationSettings,
  },
  interests: {
    fetch: getUserInterests,
    post: postUserInterests,
    update: updateUserInterests,
  },
};
