export const varietyFullName = (
  isEn,
  field,
  options = { splitter: " ", startFrom: "taxonomy" }
) => {
  const { splitter: givenSplitter, startFrom: givenStartFrom } = options;

  const splitter = givenSplitter || " ";
  const startFrom = givenStartFrom || "taxonomy";

  let taxonomyName = isEn
    ? field?.varietyRead?.taxonomyNameEn
      ? field?.varietyRead?.taxonomyNameEn + splitter
      : ""
    : field?.varietyRead?.taxonomyNameGr
      ? field?.varietyRead?.taxonomyNameGr + splitter
      : "";
  let cropName = isEn
    ? field?.varietyRead?.cropNameEn
      ? field?.varietyRead?.cropNameEn + splitter
      : ""
    : field?.varietyRead?.cropNameGr
      ? field?.varietyRead?.cropNameGr + splitter
      : "";
  let varietyName = isEn
    ? field?.varietyRead?.varietyNameEn || ""
    : field?.varietyRead?.varietyNameGr || "";

  if (startFrom === "taxonomy") {
    return taxonomyName + cropName + varietyName;
  }
  if (startFrom === "crop") {
    return cropName + varietyName;
  }
  if (startFrom === "variety") {
    return varietyName;
  }
};

export const fieldOwner = (field = {}) => {
  const { agronomistDetails, farmerDetails } = field;

  if (Array.isArray(farmerDetails) && farmerDetails.length) {
    return farmerDetails[0];
  }

  if (Array.isArray(agronomistDetails) && agronomistDetails.length) {
    return agronomistDetails[0];
  }

  return;
};

export const fieldOwnerName = (owner) => {
  if (owner) {
    return owner.lastName + " " + owner.firstName;
  }

  return;
};
