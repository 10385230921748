export const limits_static = [
  {
    id: 1,
    variety: {
      variety_id: "var_000001",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Halkidiki's",
      variety_name_gr: "Χαλκιδικής",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 2,
    variety: {
      variety_id: "var_000002",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Kalamon",
      variety_name_gr: "Καλαμών",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 4,
    variety: {
      variety_id: "var_000004",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Kothreiki",
      variety_name_gr: "Κοθρέικη",
      variety_type_id: "vrt_000006",
      liquidation: true,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 5,
    variety: {
      variety_id: "var_000005",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Koroneiki",
      variety_name_gr: "Κορωνέϊκη",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 7,
    variety: {
      variety_id: "var_000006",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Koutsourelia",
      variety_name_gr: "Κουτσουρελιά",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 8,
    variety: {
      variety_id: "var_000008",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Mastoid",
      variety_name_gr: "Μαστοειδής",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 9,
    variety: {
      variety_id: "var_000019",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Adramittini",
      variety_name_gr: "Αδραμυττίνη",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 10,
    variety: {
      variety_id: "var_000020",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Agouromanakolia",
      variety_name_gr: "Αγουρομανακολιά",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 11,
    variety: {
      variety_id: "var_000021",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Megareitiki",
      variety_name_gr: "Μεγαρείτικη",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 12,
    variety: {
      variety_id: "var_000022",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Valanolia",
      variety_name_gr: "Βαλανολιά",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 13,
    variety: {
      variety_id: "var_000023",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Throumpolia",
      variety_name_gr: "Θρουμπολιά",
      variety_type_id: "vrt_000006",
      liquidation: true,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 14,
    variety: {
      variety_id: "var_000028",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Gaidourelia",
      variety_name_gr: "Γαϊδουρελιά",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 16,
    variety: {
      variety_id: "var_000030",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Karidolia",
      variety_name_gr: "Καρυδολιά",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 17,
    variety: {
      variety_id: "var_000031",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Kolimpada",
      variety_name_gr: "Κολυμπάδα",
      variety_type_id: "vrt_000005",
      liquidation: true,
      table_fruits: false,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: null,
    table_fruits_quantity_max: null,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 18,
    variety: {
      variety_id: "var_000033",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Konservolia",
      variety_name_gr: "Κονσερβολιά",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 19,
    variety: {
      variety_id: "var_000034",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Gordal",
      variety_name_gr: "Gordal",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 20,
    variety: {
      variety_id: "var_000035",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Picholine",
      variety_name_gr: "Picholine",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 21,
    variety: {
      variety_id: "var_000036",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Ascolana",
      variety_name_gr: "Ascolana",
      variety_type_id: "vrt_000004",
      liquidation: false,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: null,
    liquidation_quantity_max: null,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
  {
    id: 22,
    variety: {
      variety_id: "var_000037",
      taxonomy_id: "tax_000001",
      taxonomy_name_en: "Oleaceae",
      taxonomy_name_gr: "Ελαιώδη",
      crop_name_en: "Olive",
      crop_name_gr: "Ελιά",
      crop_id: "crp_000001",
      variety_name_en: "Amygdalolia",
      variety_name_gr: "Αμυγδαλολιά",
      variety_type_id: "vrt_000006",
      liquidation: true,
      table_fruits: true,
      canning: false,
      dehydration: false,
      is_last: false,
    },
    liquidation_quantity_min: 350,
    liquidation_quantity_max: 400,
    table_fruits_quantity_min: 200,
    table_fruits_quantity_max: 250,
    canning_quantity_min: null,
    canning_quantity_max: null,
    quantity_units: "m3/acre",
    quantity_in_days: 365,
    description_en:
      "The total irrigation water needs of olive groves range between 350-400 cubic meters/hectare for table olives and 200-250 cubic meters/hectare for oil-producing ones",
    description_gr:
      "Οι συνολικές ανάγκες των ελαιώνων σε νερό άρδευσης ανέρχονται σε 350-400 κυβικά μέτρα/στρέμμα για τις επιτραπέζιες και 200-250 κυβικά μέτρα/στρέμμα για τις ελαιοποιήσιμες ελίες.",
  },
];
