import { Field, useField, useFormikContext } from "formik";
import PasswordField from "../components/PasswordField/PasswordField";

const FormPasswordInput = ({
  name,
  onChange,
  shouldHaveErrorBehaviorAfterSubmit,
  helperText,
  ...props
}) => {
  const [, meta] = useField(name);
  const { submitCount } = useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && meta.error
    : meta.error;

  const finalHelperText = (shouldDisplayError && meta.error) || helperText;

  return (
    <Field
      name={name}
      as={PasswordField}
      helperText={finalHelperText}
      isError={shouldDisplayError}
      {...(typeof onChange === "function" ? { onChange } : {})}
      {...props}
    />
  );
};

export default FormPasswordInput;
