import { Grid } from "@mui/material";
import NormalText from "../Typography/NormalText";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";
import SelectorNew from "../Selector/SelectorNew";

const AnalysisLine = ({ isEn, isEditMode, elementKey, element, onChange }) => {
  const handleChange = (quantity) => {
    onChange(elementKey, { ...element, quantity });
  };

  const handleUnitChange = (unit) => {
    onChange(elementKey, { ...element, quantityUnit: unit });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} md={2}>
        <NormalText style={{ fontWeight: 500 }} text={element.symbol} />
      </Grid>
      <Grid item xs={0} md={4}>
        <NormalText text={isEn ? element.nameEn : element.nameGr} />
      </Grid>
      <Grid item xs={3} md={2}>
        {isEditMode ? (
          <TextFieldCustom value={element.quantity} onChange={handleChange} />
        ) : (
          <NormalText text={element.quantity} />
        )}
      </Grid>
      <Grid item xs={3} md={2}>
        {isEditMode ? (
          <SelectorNew
            value={element.quantityUnit}
            options={element.quantityUnits.map((unit) => ({
              value: unit,
              label: unit,
            }))}
            onChange={handleUnitChange}
          />
        ) : (
          <NormalText text={element.quantity} />
        )}
      </Grid>
    </Grid>
  );
};

export default AnalysisLine;
