import { Switch } from "@mui/material";
import { Field, useField } from "formik";

const FormSwitchInput = ({
  name,
  onChange,
  style,
  isDisabled,
  shouldHaveErrorBehaviorAfterSubmit,
  id,
  ...props
}) => {
  const [, meta] = useField(name);

  return (
    <Field
      as={Switch}
      id={id}
      name={name}
      disabled={isDisabled}
      checked={meta.value}
      {...(typeof onChange === "function" ? { onChange } : {})}
      style={style}
      {...props}
    />
  );
};

export default FormSwitchInput;
