import { useTheme } from "@mui/material";
import SmallText from "../Typography/SmallText";
import { useTranslation } from "react-i18next";
import cloudIcon from "../../assets/icons/fieldPage/ndvi-cloud.svg";
import NormalText from "../Typography/NormalText";

const CloudCoverage = ({ cloudCoverage, style, shouldDisplayIcon }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const cloudCoverageFloor =
    typeof cloudCoverage !== "undefined"
      ? Math.floor(cloudCoverage)
      : undefined;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
        ...style,
      }}
    >
      {shouldDisplayIcon ? (
        <img width="20px" alt="cloud-icon" src={cloudIcon} />
      ) : (
        <SmallText
          style={{
            color: theme.color.white100,
            textShadow: "2px 2px 2px black",
          }}
          text={t("Agro.Indices.CloudCoverage", { cloud: undefined })}
        />
      )}
      <NormalText
        style={{ color: theme.color.white100, textShadow: "2px 2px 2px black" }}
        text={cloudCoverageFloor + "%"}
      />
    </div>
  );
};

export default CloudCoverage;
