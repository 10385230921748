import { useTranslation } from "react-i18next";
import { useFetchUser } from "../../hooks/useFetchUser";
import { USER_ROLES } from "../../constants/userRoles";
import UserCard from "./UserCard";

const FarmerCard = ({ farmerId }) => {
  const { t } = useTranslation();
  const { isLoading, user } = useFetchUser(farmerId, USER_ROLES.FARMER);

  return (
    <UserCard
      user={user}
      isLoading={isLoading}
      title={t("UserCard.Farmer.Title")}
    />
  );
};

export default FarmerCard;
