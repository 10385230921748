import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCropStressesContext } from "../../../context/CropStressesProvider";

const useFilteredStresses = (selectedStressTypeName) => {
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const { cropStresses } = useCropStressesContext();

  const filteredStresses = useMemo(() => {
    if (selectedStressTypeName?.length) {
      return cropStresses?.filter((s) => {
        const stressTypeName = isEn
          ? s?.stressTypeName?.nameEn
          : s?.stressTypeName?.nameGr;
        return stressTypeName === selectedStressTypeName;
      });
    } else {
      return cropStresses;
    }
  }, [cropStresses, isEn, selectedStressTypeName]);

  return { filteredStresses };
};

export default useFilteredStresses;
