import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getStressInformation = ({ stressId, biocyclePhaseId }) => {
  const params = {
    ...(biocyclePhaseId ? { bio_id: biocyclePhaseId } : {}),
  };
  const config = { ...{ params } };

  return axiosInstance.get(
    `${URLS.externalRequests}/agro/stresses/${stressId}/get-syptoms/`,
    config
  );
};

const getAllStresses = () => {
  return axiosInstance.get(`${URLS.externalRequests}/agro/stresses/`);
};

const getEnemiesOfSpecificStress = (stressId) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/agro/stresses/${stressId}/enemies/`,
    {}
  );
};

export const stressesApi = {
  fetchOne: getStressInformation,
  fetchAll: getAllStresses,
  fetchEnemies: getEnemiesOfSpecificStress,
};
