import { useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../LoadingButton/LoadingButton";

const ConfirmationDialog = ({
  isOpen = false,
  title,
  contentText,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  isConfirmLoading = false,
}) => {
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    if (typeof onCancel === "function") {
      onCancel(false);
    }
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    if (typeof onConfirm === "function") {
      onConfirm(true);
    }
  }, [onConfirm]);

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <ButtonCustom onClick={handleCancel}>
            {cancelText || t("Global.Cancel")}
          </ButtonCustom>
        )}
        {onConfirm && (
          <LoadingButton
            isLoading={isConfirmLoading}
            isDisabled={isConfirmLoading}
            buttonType="filled"
            onClick={handleConfirm}
            autoFocus
          >
            {confirmText || t("Global.Confirm")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
