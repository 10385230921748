import { useCallback } from "react";
import { useMapEvents } from "react-leaflet";

const GetCoordinates = ({ onMouseMove, onClick }) => {
  const handleMove = useCallback(
    (e) => {
      const position = [e?.latlng?.lat, e?.latlng?.lng];
      onMouseMove?.(position);
    },
    [onMouseMove]
  );

  const handleClick = useCallback(
    (e) => {
      const position = [e?.latlng?.lat, e?.latlng?.lng];
      onClick?.(position);
    },
    [onClick]
  );

  useMapEvents({
    ...(onMouseMove
      ? {
          mousemove: handleMove,
        }
      : {}),
    ...(onClick
      ? {
          click: handleClick,
        }
      : {}),
  });
  return <></>;
};
export default GetCoordinates;
