import { Field, useField, useFormikContext } from "formik";
import { MultiSelect, SelectorNew } from "components";

const FormSelectInput = ({
  name,
  onChange,
  style,
  shouldHaveErrorBehaviorAfterSubmit,
  helperText,
  isMultiple,
  ...props
}) => {
  const [, meta] = useField(name);
  const metaError = name ? meta.error : null;

  const { submitCount } = useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && metaError
    : metaError;

  const finalHelperText = (shouldDisplayError && metaError) || helperText;

  if (isMultiple) {
    return (
      <Field
        name={name}
        as={MultiSelect}
        helperText={finalHelperText}
        isError={shouldDisplayError}
        {...(typeof onChange === "function" ? { onChange } : {})}
        style={style}
        {...props}
      />
    );
  }
  return (
    <Field
      name={name}
      as={SelectorNew}
      helperText={finalHelperText}
      isError={shouldDisplayError}
      {...(typeof onChange === "function" ? { onChange } : {})}
      style={style}
      {...props}
    />
  );
};

export default FormSelectInput;
