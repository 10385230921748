import "./card.css";
const CardSectionContent = ({ children, style = {}, className }) => (
  <div
    className={`card-component-section-content ${className || ""}`}
    style={style}
  >
    {children}
  </div>
);

export default CardSectionContent;
