import { useEffect, useState } from "react";
import moment from "moment";
import { FormHelperText } from "@mui/material";
import DateField from "./DateField";

const DateRangeField = ({
  startLabel,
  endLabel,
  startPlaceholder,
  endPlaceholder,
  startDefaultValue,
  endDefaultValue,
  startValue,
  endValue,
  startName,
  endName,
  startError,
  endError,
  isDisabled,
  isViewMode,
  seperator,
  onChange,
  helperText,
  inputFormat,
  fieldClassName,
  fieldStyle,
  locale,
  minDate,
  maxDate,
}) => {
  const [dateRange, setDateRange] = useState({
    start: startDefaultValue || startValue || null,
    end: endDefaultValue || endValue || null,
  });

  const handleStartChange = (date) => {
    const shouldNotEraseEndDate =
      dateRange.end == null || moment(date).isBefore(dateRange.end);

    setDateRange((prev) => ({
      ...prev,
      start: date,
      end: shouldNotEraseEndDate ? prev.end : undefined,
    }));

    if (date != null) {
      onChange?.({
        start: date,
        end: shouldNotEraseEndDate ? dateRange.end : undefined,
      });
    }
  };

  const handleEndChange = (time) => {
    setDateRange((prev) => ({
      ...prev,
      end: time,
    }));

    if (time != null) {
      onChange?.({
        start: dateRange.start,
        end: time,
      });
    }
  };

  useEffect(() => {
    setDateRange((prev) => ({ ...prev, start: startValue }));
  }, [startValue]);

  useEffect(() => {
    setDateRange((prev) => ({ ...prev, end: endValue }));
  }, [endValue]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: !seperator ? "8px" : "0px",
        }}
      >
        <DateField
          name={startName}
          label={startLabel}
          placeholder={startPlaceholder}
          value={dateRange.start}
          defaultValue={startDefaultValue}
          isDisabled={isDisabled}
          isViewMode={isViewMode}
          isError={Boolean(startError)}
          inputFormat={inputFormat}
          locale={locale}
          className={fieldClassName}
          style={fieldStyle}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleStartChange}
        />
        {seperator}
        <DateField
          name={endName}
          label={endLabel}
          placeholder={endPlaceholder}
          value={dateRange.end}
          defaultValue={endDefaultValue}
          isDisabled={isDisabled}
          isError={Boolean(endError)}
          isViewMode={isViewMode}
          inputFormat={inputFormat}
          locale={locale}
          className={fieldClassName}
          style={fieldStyle}
          minDate={dateRange.start}
          maxDate={maxDate}
          onChange={handleEndChange}
        />
      </div>
      <FormHelperText error={Boolean(startError || endError)}>
        {helperText}
      </FormHelperText>
    </div>
  );
};

export default DateRangeField;
