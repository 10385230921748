import axios from "axios";

const axiosInstanceWithoutAuth = axios.create({
  baseURL: "https://services-eu1.arcgis.com",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const getCoordinatesByKaek = (option, kaek) => {
  return axiosInstanceWithoutAuth.get(
    `/40tFGWzosjaLJpmn/arcgis/rest/services/${option}/FeatureServer/0/query`,
    {
      params: {
        f: "json",
        resultOffset: 0,
        resultRecordCount: 100,
        where: `(KAEK = '${kaek}')`,
        outFields: "AREA,DESCR,KAEK,PERCENTAGE,PERIMETER,OBJECTID",
        spatialRel: "esriSpatialRelIntersects",
        outSR: 4326,
      },
    }
  );
};

export const kaekApi = {
  getCoordinatesByKaek: getCoordinatesByKaek,
};
