import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  useTheme,
} from "@mui/material";
import useNestedTable from "hooks/useNestedTable";
import Row from "./NestedTableRow";
import CellStyled from "./CellStyled";

const NestedTable = ({
  values,
  allValues,
  headers,
  levels,
  getOptions,
  onChange,
}) => {
  const theme = useTheme();

  const { handleModify, handleInsert } = useNestedTable({
    values,
    allValues,
  });

  const handleRemove = (value, parentIdPath) => {
    const newValues = handleModify({
      modifyType: "remove",
      value,
      parentIdPath,
    });
    onChange?.(newValues);
  };

  const handleAdd = (value) => {
    const newValues = handleInsert(value);
    onChange?.(newValues);
  };

  return (
    <MuiTableContainer
      style={{
        minHeight: "300px",
        maxHeight: "500px",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "12px",
      }}
    >
      <MuiTable stickyHeader size="small">
        <MuiTableHead>
          <MuiTableRow>
            {headers.map((header) => (
              <CellStyled
                key={header}
                style={{
                  textAlign: "center",
                }}
              >
                {header}
              </CellStyled>
            ))}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {values?.map((value) => {
            return (
              <Row
                key={value.label}
                value={value}
                levelIndex={0}
                levels={levels}
                parentIdPath={[]}
                isShown={true}
                getOptions={getOptions}
                onRemove={handleRemove}
                onAdd={handleAdd}
              />
            );
          })}
          <Row
            value={{ isAddButton: true }}
            levels={levels}
            levelIndex={0}
            isShown={true}
            getOptions={getOptions}
            onAdd={handleAdd}
          />
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
export default NestedTable;
