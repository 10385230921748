import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api } from "../api";
import {
  populateActionChoicesActions,
  populateActionChoicesTypes,
} from "../utils/actionChoicesPopulation";
import { ACTION } from "../constants/actions";
import { CropsInformationProvider } from "./CropsInformationProvider";
import { snakeToCamel } from "../utils/camelSnakeTransformation";
import { deepTransformKeys } from "../utils/transformObjectKeys";
import { limits_static } from "./providers/limits";

const WateringUsageContext = createContext({
  limits: [],
});

const WateringMethodsContext = createContext({
  wateringMethods: {},
  setWateringMethods: () => {},
});

const ActionChoicesContext = createContext({
  isLoading: false,
  actionChoices: {
    actionTypes: {
      prevention: {},
      treatment: {},
    },
    actions: {
      watering: {},
      drug: {},
      fertilize: {},
      diagnose: {},
      other: {},
    },
  },
  getActionById: () => {},
  setActionChoices: () => {},
});
const StressTypesContext = createContext({
  stressTypes: {},
  setStressTypes: () => {},
});
const GeneralAgroProvider = ({ children }) => {
  const [wateringMethods, setWateringMethods] = useState();
  const [wateringLimits, setWateringLimits] = useState();
  const [actionChoices, setActionChoices] = useState({
    isLoading: false,
    actionTypes: {
      prevention: {},
      treatment: {},
    },
    actions: {
      watering: {},
      drug: {},
      fertilize: {},
      diagnose: {},
      other: {},
    },
  });
  const [stressTypes, setStressTypes] = useState();

  const getActionById = useCallback(
    (id, isPopulated = false) => {
      if (actionChoices?.actions[ACTION.watering].taskId === id) {
        return isPopulated
          ? actionChoices?.actions[ACTION.watering]
          : ACTION.watering;
      }
      if (actionChoices?.actions[ACTION.drug].taskId === id) {
        return isPopulated ? actionChoices?.actions[ACTION.drug] : ACTION.drug;
      }
      if (actionChoices?.actions[ACTION.fertilize].taskId === id) {
        return isPopulated
          ? actionChoices?.actions[ACTION.fertilize]
          : ACTION.fertilize;
      }
      if (actionChoices?.actions[ACTION.diagnose].taskId === id) {
        return isPopulated
          ? actionChoices?.actions[ACTION.diagnose]
          : ACTION.diagnose;
      }
      if (actionChoices?.actions[ACTION.other].taskId === id) {
        return isPopulated
          ? actionChoices?.actions[ACTION.other]
          : ACTION.other;
      }
      return;
    },
    [actionChoices?.actions]
  );

  const getWateringLimits = useCallback(
    async (cropId) => {
      if (wateringLimits) {
        if (cropId) {
          return wateringLimits[cropId];
        }
        return wateringLimits;
      } else {
        try {
          // const { data } = await api.getWaterLimits();
          const data = deepTransformKeys(limits_static, snakeToCamel);
          const newLimits = {};
          data?.forEach((cropLimit) => {
            const cropId = cropLimit?.variety?.cropId;
            if (cropId) {
              newLimits[cropId] = {
                descriptionGr: cropLimit.descriptionGr,
                descriptionEn: cropLimit.descriptionEn,
              };
            }
          });
          setWateringLimits(newLimits);
          if (cropId) {
            return newLimits[cropId];
          }
          return newLimits;
        } catch (err) {
          console.error(err);
          return {};
        }
      }
    },
    [wateringLimits]
  );

  useEffect(() => {
    setActionChoices((prev) => ({ ...prev, isLoading: true }));
    api.generalAgro
      .actionChoices()
      .then((res) => {
        // setActionChoices(() => res.data);
        setActionChoices(() => ({
          isLoading: false,
          actionTypes: populateActionChoicesTypes(res.data.actionTypes),
          actions: populateActionChoicesActions(res.data.actions),
        }));
      })
      .catch((err) => {
        setActionChoices((prev) => ({ ...prev, isLoading: false }));
      });

    api.generalAgro.wateringMethods().then((res) => {
      setWateringMethods(() => res.data);
    });

    api.generalAgro.stressTypes().then((res) => {
      setStressTypes(() => res.data);
    });
  }, []);

  const wateringUsageValues = useMemo(
    () => ({
      getWateringLimits,
    }),
    [getWateringLimits]
  );

  const wateringMethodsValues = useMemo(
    () => ({
      wateringMethods,
    }),
    [wateringMethods]
  );

  const actionChoicesValues = useMemo(
    () => ({
      actionChoices,
      getActionById,
    }),
    [actionChoices, getActionById]
  );

  const stressTypesValues = useMemo(
    () => ({
      stressTypes,
    }),
    [stressTypes]
  );

  return (
    <WateringMethodsContext.Provider value={wateringMethodsValues}>
      <WateringUsageContext.Provider value={wateringUsageValues}>
        <ActionChoicesContext.Provider value={actionChoicesValues}>
          <CropsInformationProvider>
            <StressTypesContext.Provider value={stressTypesValues}>
              {children}
            </StressTypesContext.Provider>
          </CropsInformationProvider>
        </ActionChoicesContext.Provider>
      </WateringUsageContext.Provider>
    </WateringMethodsContext.Provider>
  );
};

const useWateringUsageContext = () => {
  const context = useContext(WateringUsageContext);

  if (!context) {
    throw new Error(
      "useWateringUsageContext must be used within a WateringUsageProvider"
    );
  }
  return context;
};

const useWateringMethodsContext = () => {
  const context = useContext(WateringMethodsContext);

  if (!context) {
    throw new Error(
      "useWateringMethodsContext must be used within a WateringMethodsProvider"
    );
  }
  return context;
};

const useActionChoicesContext = () => {
  const context = useContext(ActionChoicesContext);

  if (!context) {
    throw new Error(
      "useActionChoicesContext must be used within a ActionChoicesProvider"
    );
  }
  return context;
};

const useStressTypesContext = () => {
  const context = useContext(StressTypesContext);

  if (!context) {
    throw new Error(
      "useStressTypesContext must be used within a StressTypesProvider"
    );
  }
  return useContext(StressTypesContext);
};

export {
  GeneralAgroProvider,
  useWateringMethodsContext,
  useWateringUsageContext,
  useActionChoicesContext,
  useStressTypesContext,
};
