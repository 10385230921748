import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import Title from "../../../Typography/Title";
import DateSpan from "../date-span/DateSpan";
import EquipmentUsed from "../equipment/EquipmentUsed";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const OtherActionPerformed = ({ viewMode, isFullWidth }) => {
  const { t } = useTranslation();
  const status = ACTION_STATUS.performed;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />
        <EquipmentUsed isInEditMode={viewMode !== ACTION_VIEW_MODE.view} />
        <ActionModalDescriptionSection
          actionBaseName="otherAction"
          isViewMode={isViewMode}
        />
      </div>
    </div>
  );
};

export default OtherActionPerformed;
