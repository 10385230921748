import { storage } from "../../utils/localStorage";

export const fetchBlob = (uri, callback) => {
  const { token } = storage.getCredsFromLocalStorage();
  var xhr = new XMLHttpRequest();
  xhr.open("GET", uri, true);
  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.responseType = "arraybuffer";

  xhr.onload = function (e) {
    if (this.status == 200) {
      var blob = this.response;
      if (callback) {
        callback(blob);
      }
    }
  };
  xhr.send();
};

export const blobToBase64Url = (blob, type = "image/png") => {
  const base64String = btoa(
    String.fromCharCode.apply(null, new Uint8Array(blob))
  );
  return `data:${type};base64,${base64String}`;
};
