import { useTheme, useMediaQuery } from "@mui/material";
import NormalText from "../../Typography/NormalText";
import { useTranslation } from "react-i18next";
import { paths } from "../../../utils/paths";
import "./index.css";

const AuthBox = ({ children, showTerms = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: !isMobile ? "30%" : "80%",
        maxWidth: "500px",
        minWidth: "350px",
        height: "80%",
        boxShadow: theme.boxShadow.aroundDark,
        background: "white",
        borderRadius: "10px",
        margin: !isMobile ? "5% 5% 5% 5%" : "3% 8%",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {children}
      </div>
      {showTerms && (
        <div
          style={{
            display: "flex",
            gap: "16px",
            padding: "8px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a
            href={paths.terms}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "inherit" }}
          >
            <NormalText
              text={t("Global.CamvioLinks.TermsAndConditions")}
              className={"links"}
            />
          </a>
          <a
            href={paths.privacyPolicy}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "inherit" }}
          >
            <NormalText
              text={t("Global.CamvioLinks.PrivacyPolicy")}
              className={"links"}
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default AuthBox;
