import {
  autocompleteClasses,
  FormControl,
  FormHelperText,
  inputBaseClasses,
  Autocomplete as MuiAutocomplete,
  outlinedInputClasses,
  Popper,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";
import ListboxComponent from "./ListBoxComponent";

const MuiAutocompleteStyled = styled(MuiAutocomplete)({
  [`& .${autocompleteClasses.endAdornment}`]: {
    padding: 0,
  },
  [`& .${autocompleteClasses.inputRoot}.${inputBaseClasses.root}`]: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  [`& .${autocompleteClasses.input}.${inputBaseClasses.input}.${outlinedInputClasses.input}`]:
    {
      paddingTop: 0,
      paddingBottom: 0,
    },
  [`.${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    fontSize: "14px",
  },
});

const MuiPopperStyled = styled(Popper)({
  [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    fontSize: "14px",
  },
});

const Autocomplete = ({
  className,
  helperText,
  id,
  isError,
  isMultiple,
  onChange,
  options,
  placeholder,
  value,
  style,
}) => {
  const { t } = useTranslation();
  const placeholderFinal =
    placeholder || t("Components.Autocomplete.Placeholder");

  const handleChange = (e, newValue) => {
    onChange?.(newValue);
  };

  return (
    <FormControl fullWidth>
      <MuiAutocompleteStyled
        id={id}
        fullWidth
        value={value}
        multiple={isMultiple}
        onChange={handleChange}
        options={options}
        PopperComponent={MuiPopperStyled}
        ListboxComponent={ListboxComponent}
        getOptionDisabled={(option) => option.isDisabled}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextFieldCustom {...params} placeholder={placeholderFinal} />
        )}
        renderOption={(props, option, state) => [props, option, state.index]}
        className={className}
        sx={style}
      />
      <FormHelperText error={Boolean(isError)}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Autocomplete;
