import { Alert, useTheme } from "@mui/material";
import React from "react";
import { useAuthContext } from "../../context/AuthProvider";

const AlertNotification = () => {
  const { message, setMessage } = useAuthContext();

  const theme = useTheme();
  const handleClose = () => {
    setMessage({ isOpen: false });
  };
  return (
    <Alert
      sx={{
        display: message?.isOpen ? "flex" : "none",
        position: "absolute",
        bottom: 50,
        left: "30%",
        zIndex: theme.zIndex.max,
      }}
      variant="filled"
      severity={message?.type}
      onClose={handleClose}
    >
      {message?.text}
    </Alert>
  );
};

export default AlertNotification;
