import { useEffect } from "react";
import useVisibilityChange from "./useVisibilityChange";
import { STORAGE_NOTIFICATIONSKEY } from "../constants/global";

const useBackgroundMessages = (handleBackgroundMessages) => {
  const { isForeground } = useVisibilityChange();

  useEffect(() => {
    if (isForeground) {
      const storedNotifications = localStorage.getItem(
        STORAGE_NOTIFICATIONSKEY
      );
      if (storedNotifications && storedNotifications.length > 0) {
        const notifications = JSON.parse(storedNotifications);

        if (typeof handleBackgroundMessages === "function") {
          handleBackgroundMessages(notifications);
        }

        localStorage.removeItem(STORAGE_NOTIFICATIONSKEY);
      }
    }
  }, [isForeground, handleBackgroundMessages]);
};

export default useBackgroundMessages;
